;
(function ($, window, undefined) {
    var $progressBar,
        progressTimeout;

    function start (topic, data) {
        var progressBarId = data.progressBarId;

        if(progressBarId) {
            $progressBar = $('#' + progressBarId);
            $progressBar.removeClass('progress-two progress-loaded').addClass('progress-one');
            progressTwo();
        }
    }

    function loaded (topic, data) {
        var progressBarId = data.progressBarId;

        if(progressBarId) {
            $progressBar = $('#' + progressBarId);
            $progressBar.removeClass('progress-one progress-two').addClass('progress-loaded');
            clearTimeout(progressTimeout);
        }
    }

    function cancel (topic, data) {
        var progressBarId = data.progressBarId;

        if(progressBarId) {
            $progressBar = $('#' + progressBarId);
            $progressBar.removeClass('progress-one progress-two progress-loaded');
            clearTimeout(progressTimeout);
        }
    }

    function progressTwo () {
        progressTimeout = setTimeout(function() {
            $progressBar.removeClass('progress-one').addClass('progress-two');
        }, 2000);
    }

    // Subscribe when progress starts
    uitk.subscribe('progress.start', start);
    // Subscribe when progress completes
    uitk.subscribe('progress.loaded', loaded);
    // Subscribe when progress errored out
    uitk.subscribe('progress.cancel', cancel);
}(jQuery, window));
