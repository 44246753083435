/*
 *  Project: UI Toolkit Product Tour Plugin
 *  Description: Product Tour Plugin for use in the Egencia.com UI Toolkit
 *  Author: rvora@expedia.com
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;

(($, window) => {
    class ProductTour{

        constructor(productTourObjects = []) {
            this.productTourObjects = [];
            this.currPostion = 0;
            this.template = Handlebars.templates['partials/uitk/product-tour'];
            /* Initialize triggering elements for product tour tooltips */
            if(productTourObjects && productTourObjects.length  === 0){
                throw new Error(`Cannot Initialize Product with an empty list of Product Tour Objects`);
            }

            const invalidProductTourObjects = this.validate(productTourObjects);
            if(!invalidProductTourObjects.length) {
                // scroll to top of document before tour starts
                document.body.scrollTop = document.documentElement.scrollTop = 0;

                this.productTourObjects = this.setDefaults(productTourObjects);
                // For case, when user closes the tool-tip by clicking outside the tool-tip(default behaviour closes the tool-tip)
                // In that case, we need to remove attributes attached to current tool-tip trigger so that clicking it again
                // does not open the tool-tip.
                $(document).one('click', () => {
                    this.removeToolTipAttributes(this.productTourObjects[this.currPostion]);
                });

                this.init();
            }
            else {
                const objectIds = invalidProductTourObjects.map( (invalidProductTourObject) => JSON.stringify(invalidProductTourObject));
                throw new Error(`Following Product Tour Objects are Invalid ${objectIds}`);
            }
        }

        validate(productTourObjects) {
            return productTourObjects.filter( (productTourObject) => !productTourObject.element_id || !productTourObject.tooltip_header || !productTourObject.tooltip_content);
        }

        setDefaults(productTourObjects) {
            const productTourObjectUpdate = productTourObjects.map( (productTourObject) => {
                return Object.assign({}, {
                    tooltip_content_mobile: productTourObject.tooltip_content_mobile || productTourObject.tooltip_content,
                    tooltip_position: productTourObject.tooltip_position || 'br',
                    tooltip_next_button_label: productTourObject.tooltip_next_button_label || uitk.i18n.msg('uitk_next_label'),
                    tooltip_end_tour_label: productTourObject.tooltip_end_tour_label || uitk.i18n.msg('uitk_end_tour_label'),
                    tooltip_finish_tour_label: productTourObject.tooltip_finish_tour_label || uitk.i18n.msg('uitk_finish_tour_label'),
                    isLast: false},
                    productTourObject);
            });

            productTourObjectUpdate[productTourObjectUpdate.length - 1].isLast = true;
            return productTourObjectUpdate;
        }

        addToolTipAttributes(productTourObject){
            const attributes = {
                'data-js-theme':'educational',
                'data-pos': uitk.adapt.smallScreen ? 'br': productTourObject.tooltip_position,
                'data-control':'tooltip',
                'data-trigger':'click',
                'data-content-id':'product-tour-tool-tip'
            };

            const $element = $('#'+ productTourObject.element_id);
            $element.attr(attributes);
        }

        removeToolTipAttributes(productTourObject){
            const $element = $('#'+ productTourObject.element_id);
            const attributes = ['data-js-theme', 'data-pos', 'data-control', 'data-trigger', 'data-content-id'];
            $element.removeAttr(attributes.join(''));
        }

        init(){
            this.addToolTipAttributes(this.productTourObjects[this.currPostion]);
            this.scroll();
            this.render();
            this.bindEvents();
        }

        getProductTourDataLength(){
            return this.productTourObjects.length;
        }

        next() {
            if(this.hasNext()){
                this.removeToolTipAttributes(this.productTourObjects[this.currPostion]);
                this.currPostion++;
                this.init();
            }
            else{
                this.endTour();
            }
        }

        endTour(){
            const productTourObject = this.productTourObjects[this.currPostion];
            this.removeToolTipAttributes(productTourObject);
            $('#' + productTourObject.element_id).uitk_tooltip('hide');
            this.reset();
        }

        scroll(){
            const eleActiveTour = document.getElementById(this.productTourObjects[this.currPostion].element_id);
            const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

            if (isSmoothScrollSupported) {
                eleActiveTour.scrollIntoView({behavior: 'smooth', block: 'center'});
            } else {
                // if we can not scrollIntoView, manually move scroll to center of element!
                const tourElementY = window.pageYOffset + eleActiveTour.getBoundingClientRect().top;
                const scrollToY = tourElementY - window.innerHeight / 2;
                window.scrollTo(0, scrollToY);
            }
        }

        reset(){
            this.currPostion = 0;
        }

        hasNext(){
            return this.currPostion + 1 < this.getProductTourDataLength();
        }

        bindEvents(){
            $('.next-button', this.root).click( (e) => {
                e.stopPropagation();
                this.next();
            });

            $('.end-tour-button', this.root).click( (e) => {
                e.stopPropagation();
                this.endTour();
            });
        }

        render(){
            const element = $('#' + this.productTourObjects[this.currPostion].element_id);
            const data = Object.assign({},{
                    toolTipProgess : uitk.i18n.msg('uitk_tooltip_progress', { arg0: this.currPostion + 1, arg1: this.getProductTourDataLength()})
                },
                this.productTourObjects[this.currPostion]);
            element.after(this.template(data));
            element.uitk_tooltip('show');
        }
    }
    uitk.ProductTour = ProductTour;
})(jQuery, window);