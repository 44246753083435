;
uitk.map.initHtmlMarker = function() {
    /**
     * Wrapper for a default Google Marker object with some convenience methods used by uitk.map plugin
     */
    uitk.map.DefaultMarker = function () {
        google.maps.Marker.apply(this, arguments);
    };

    uitk.map.DefaultMarker.prototype = new google.maps.Marker();

    /**
     * Removes marker from the map
     * @public
     */
    uitk.map.DefaultMarker.prototype.remove = function () {
        this.setMap(null);
    };

    uitk.map.DefaultMarker.prototype.addListener = function (eventName, callback) {
        google.maps.event.addListener(this, eventName, callback);
    };

    /**
     * Custom Marker that can be added to a map using {uitk.map.MapOverlay}.
     * Not a true {google.maps.Marker}, so it cannot be used with MarkerClusterer (since setMap() is a dummy method),
     * but can be used with widgets like {google.maps.InfoWindow} that expect {google.map.MVCObject}.
     * Currently used to display 'dot' and 'tooltip' markers on the map.
     */
    uitk.map.HtmlMarker = function (options) {

        options = options || {};
        options.labelContent = options.labelContent || '';
        options.labelAnchor = options.labelAnchor || new google.maps.Point(0, 25);
        options.labelClass = options.labelClass || 'marker-wrapper';
        options.icon = '../../images/transparent.png';
        options.optimized = false; // Optimized rendering is not supported, Google says, 'Disable optimized rendering...when each marker must be rendered as a separate DOM element (advanced usage only).'

        if (typeof options.clickable === 'undefined') {
            options.clickable = true;
        }

        $.extend(this, this, options);

        this.contentSet = false;

        this.anchorPoint = new google.maps.Point(0, -25);

        this.labelDiv_ = document.createElement('div');
        this.labelDiv_.style.cssText = 'position: absolute; overflow: hidden;';
        this.eventDiv_ = document.createElement('div');
        this.eventDiv_.style.cssText = this.labelDiv_.style.cssText;

        // This is needed for proper behavior on MSIE:
        this.eventDiv_.setAttribute('onselectstart', 'return false;');
        this.eventDiv_.setAttribute('ondragstart', 'return false;');
    };

    /**
     * Extends MVCObject so that it can be used with map APIs like {google.maps.InfoWindow#open()}
     */
    uitk.map.HtmlMarker.prototype = new google.maps.MVCObject();

    /**
     * lat/lng position of the marker (used for calculating screen position)
     * @returns {google.maps.LatLng}
     */
    uitk.map.HtmlMarker.prototype.getPosition = function () {
        return this.position;
    };

    /**
     * Point for calculating the pixelOffset (see InfoWindowOptions) of the info window
     * @see https://developers.google.com/maps/documentation/javascript/reference#InfoWindow
     * @returns {google.maps.Point}
     */
    uitk.map.HtmlMarker.prototype.getAnchorPoint = function () {
        return this.anchorPoint;
    };

    /**
     * Adds marker to given overlay (represented by event and image layers)
     * @param imageOverlay
     * @param mouseOverlay
     */
    uitk.map.HtmlMarker.prototype.addTo = function (imageOverlay, mouseOverlay) {
        var self = this;
        var cAbortEvent = function (e) {
            if (e.preventDefault) {
                e.preventDefault();
            }
            e.cancelBubble = true;
            if (e.stopPropagation) {
                e.stopPropagation();
            }
        };

        imageOverlay.appendChild(this.labelDiv_);
        mouseOverlay.appendChild(this.eventDiv_);

        this.listeners_ = [
            google.maps.event.addDomListener(this.eventDiv_, 'mouseover', function (e) {
                google.maps.event.trigger(self, 'mouseover', e);
            }),
            google.maps.event.addDomListener(this.eventDiv_, 'mouseout', function (e) {
                google.maps.event.trigger(self, 'mouseout', e);
            }),
            google.maps.event.addDomListener(this.eventDiv_, 'click', function (e) {
                google.maps.event.trigger(self, 'click', e);
                cAbortEvent(e); // Prevent click from being passed on to map
            }),
            google.maps.event.addDomListener(this.eventDiv_, 'touchstart', function (e) {
                google.maps.event.trigger(self, 'click', e);
                cAbortEvent(e); // Prevent click from being passed on to map
            }),
            google.maps.event.addDomListener(this.eventDiv_, 'touchend', function (e) {
                google.maps.event.trigger(self, 'click', e);
                cAbortEvent(e); // Prevent click from being passed on to map
            }),
            google.maps.event.addDomListener(this.eventDiv_, 'dblclick', function (e) {
                google.maps.event.trigger(self, 'dblclick', e);
                cAbortEvent(e); // Prevent map zoom when double-clicking on a label
            })
        ];
    };

    uitk.map.HtmlMarker.prototype.toggle = function (visible) {
        $(this.labelDiv_).attr('hidden', !visible);
        $(this.eventDiv_).attr('hidden', !visible);
    };

    uitk.map.HtmlMarker.prototype.remove = function () {
        var i;
        this.labelDiv_.parentNode.removeChild(this.labelDiv_);
        this.eventDiv_.parentNode.removeChild(this.eventDiv_);

        // Remove event listeners:
        for (i = 0; i < this.listeners_.length; i++) {
            google.maps.event.removeListener(this.listeners_[i]);
        }

        this.contentSet = false;
    };

    uitk.map.HtmlMarker.prototype.addListener = function (eventName, callback) {
        google.maps.event.addListener(this, eventName, callback);
    };

    uitk.map.HtmlMarker.prototype.bounce = function (bounce) {
        var $elem = $(this.labelDiv_);
        if (bounce) {
            $elem.addClass('marker-highlight');
        } else {
            $elem.removeClass('marker-highlight');
        }
    };

    /**
     * Draws the label on the map.
     * @private
     */
    uitk.map.HtmlMarker.prototype.draw = function (projection) {
        if (!this.contentSet) {
            this.setContent();
            this.setStyles();
            this.contentSet = true;
        }
        this.updatePosition(projection);
    };

    /**
     * Sets the content of the label.
     * The content can be plain text or an HTML DOM node.
     * @private
     */
    uitk.map.HtmlMarker.prototype.setContent = function () {
        var content = this.labelContent;
        this.labelDiv_.innerHTML = content;
        this.eventDiv_.innerHTML = this.labelDiv_.innerHTML;
    };


    /**
     * Sets the style of the label by setting the style sheet and applying
     * other specific styles requested.
     * @private
     */
    uitk.map.HtmlMarker.prototype.setStyles = function (projection) {
        this.labelDiv_.className = this.labelClass;
        this.eventDiv_.className = this.labelDiv_.className;
        this.setMandatoryStyles();
    };

    /**
     * set
     * @param projection
     */
    uitk.map.HtmlMarker.prototype.updatePosition = function (projection) {
        this.setAnchor();
        this.setPosition(undefined, projection); // This also updates z-index, if necessary.
    };

    /**
     * Sets the mandatory styles to the DIV representing the label as well as to the
     * associated event DIV. This includes setting the DIV position, z-index, and visibility.
     * @private
     */
    uitk.map.HtmlMarker.prototype.setMandatoryStyles = function () {
        this.labelDiv_.style.position = 'absolute';
        this.labelDiv_.style.overflow = 'hidden';
        this.eventDiv_.style.position = this.labelDiv_.style.position;
        this.eventDiv_.style.overflow = this.labelDiv_.style.overflow;
        this.eventDiv_.style.opacity = 0.01; // Don't use 0; DIV won't be clickable on MSIE
        this.eventDiv_.style.cursor = 'pointer';
    };

    /**
     * Sets the anchor point of the label.
     * @private
     */
    uitk.map.HtmlMarker.prototype.setAnchor = function () {
        var anchor = this.labelAnchor;
        this.labelDiv_.style.marginLeft = -anchor.x + 'px';
        this.labelDiv_.style.marginTop = -anchor.y + 'px';
        this.eventDiv_.style.marginLeft = -anchor.x + 'px';
        this.eventDiv_.style.marginTop = -anchor.y + 'px';
    };

    /**
     * Sets the position of the label. The z-index is also updated, if necessary.
     * @private
     */
    uitk.map.HtmlMarker.prototype.setPosition = function (yOffset, projection) {
        var position = projection.fromLatLngToDivPixel(this.getPosition());
        if (typeof yOffset === 'undefined') {
            yOffset = 0;
        }
        this.labelDiv_.style.left = Math.round(position.x) + 'px';
        this.labelDiv_.style.top = Math.round(position.y - yOffset) + 'px';
        this.eventDiv_.style.left = this.labelDiv_.style.left;
        this.eventDiv_.style.top = this.labelDiv_.style.top;

        this.setZIndex();
    };

    /**
     * Sets the z-index of the label. If the marker's z-index property has not been defined, the z-index
     * of the label is set to the vertical coordinate of the label. This is in keeping with the default
     * stacking order for Google Maps: markers to the south are in front of markers to the north.
     * @private
     */
    uitk.map.HtmlMarker.prototype.setZIndex = function () {
        var zAdjust = +1;
        if (typeof this.zIndex === 'undefined') {
            this.labelDiv_.style.zIndex = parseInt(this.labelDiv_.style.top, 10) + zAdjust;
            this.eventDiv_.style.zIndex = this.labelDiv_.style.zIndex;
        } else {
            this.labelDiv_.style.zIndex = this.zIndex + zAdjust;
            this.eventDiv_.style.zIndex = this.labelDiv_.style.zIndex;
        }
    };

    uitk.map.HtmlMarker.prototype.setMap = function (map) {
        // do nothing - stub to be able to use it along other marker implementations
        // normally this method adds/removes marker from a map
    };

    /**
     * Indicates that this marker is not a true {google.maps.Marker} implementation
     */
    uitk.map.HtmlMarker.prototype.isHtmlMarker = true;

    /**
     * A map overlay ({google.maps.OverlayView}) that can manage multiple collections of uitk.map.HtmlMarker.
     * Controls adding/removing markers to/from map and positioning on zoom in/out.
     * Provides a faster way of adding a lot of markers to a map comparing to conventional google.map.Marker.
     */
    uitk.map.MapOverlay = function (options) {
        var that = this;
        that.setValues(options);
        that.markerLayer = $('<div />').addClass('overlay');
        that.markers = [];
        that.updateMarkers(options);
    };

    uitk.map.MapOverlay.prototype = new google.maps.OverlayView;

    /**
     * Appends markers as children of the panes.
     */
    uitk.map.MapOverlay.prototype.onAdd = function () {
        var $pane = $(this.getPanes().overlayImage); // Pane 3
        $pane.append(this.markerLayer);
    };

    /**
     * Removes markers from the DOM.
     */
    uitk.map.MapOverlay.prototype.onRemove = function () {
        this.markerLayer.remove();
    };

    /**
     * Positions markers on the map
     */
    uitk.map.MapOverlay.prototype.draw = function () {

        var markers,
            that = this,
            projection = that.getProjection();

        if (!projection) {
            return;
        }

        for (var i in that.markers) {
            if (Array.isArray(that.markers[i])) {
                // dealing with a group
                markers = that.markers[i];
                for (var j = 0; j < markers.length; j++) {
                    markers[j].draw(projection);
                }
            } else if (that.markers[i]) {
                // dealing with an individual marker
                that.markers[i].draw(projection);
            }
        }
    };

    /**
     * Removes markers of a given group from overlay, or removes all markers if no group is specified
     */
    uitk.map.MapOverlay.prototype.removeMarkers = function (markersId) {
        var that = this,
            overlay;

        if (markersId) {
            overlay = that.findOverlay(markersId);
            if (overlay) {
                overlay.remove();
            }
            that.markers[markersId] = null;
        } else {
            that.markers = [];
            that.markerLayer.children().remove();
        }
    };

    /**
     * Accepts an array of {uitk.map.HtmlMarker} and adds them to a marker group identified by markersId
     * @param options
     */
    uitk.map.MapOverlay.prototype.updateMarkers = function (options) {
        var that = this,
            fragment = document.createDocumentFragment(),
            markers,
            existingOverlay,
            overlay;

        if (options.markersId) {
            that.markers[options.markersId] = that.markers[options.markersId] || [];
            markers = that.markers[options.markersId];
        } else {
            markers = that.markers;
        }

        $.each(options.markers, function (i, marker) {
            if (!marker.isHtmlMarker) {
                return;
            }
            markers.push(marker);
            marker.addTo(fragment, fragment); // add new pins to fragment
        });

        if (options.markersId) {
            existingOverlay = that.findOverlay(options.markersId);
            overlay = existingOverlay || $('<div />').data('markerId', options.markersId);
            overlay.append(fragment);
            if (!existingOverlay) {
                that.markerLayer.append(overlay);
            }
        } else {
            that.markerLayer.append(fragment);
        }
        that.draw();
    };

    /**
     * Returns overlay DOM object for a given marker group
     * @param markersId
     * @returns Jquery overlay object
     * @private
     */
    uitk.map.MapOverlay.prototype.findOverlay = function (markersId) {
        var that = this;
        var overlays = that.markerLayer.children().filter(
            function () {
                return $(this).data('markerId') === markersId;
            });
        return overlays && overlays.length > 0 ? $(overlays[0]) : undefined;
    };
};

//uitk.map.initPlugin(); //TODO this shouldn't have to be done here
