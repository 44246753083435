/*
 *  Project: UI Toolkit Filters Plugin
 *  Author: ctse@egencia.com
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function ($, window, undefined) {

    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_filters',
        $body = $('body'),
        openClass = 'open',
        appliedClass = 'filtered',
        defaults = {
            filtered: false,
            mode: 'live' // 'submit'
        };

    /* CONSTRUCTOR */
    function Filters(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    Filters.prototype = {
        init: function () {
            this.filtered = this.options.filtered;
            this.categories = []; // {id: '', filtered: false}

            this.setCategories();

            uitk.subscribe('filters.reset.success', this, this.resetSuccess);
            uitk.subscribe('filters.apply.success', this, this.applySuccess);
            uitk.subscribe('filters.updateContent', this, this.updateContent);
        },

        setCategories: function() {
            var that = this;
            this.element.find('.filter-category').each(function(i, category) {
                var $cat = $(category);
                that.categories[$cat.prop('id')] = ({id: $cat.prop('id'), filtered: $cat.hasClass('filtered')});
            });

        },

        slideDown: function(el) {
            el.css('max-height', el[0].scrollHeight + 'px');
        },

        slideUp: function(el) {
            el.css('max-height', 0);
        },

        showCategory: function($filterCategory) {
            var $filterContents = this.element.find('.filter-content');
            var $filterBody = this.element.find('.filter-body');

            this.element.addClass(openClass);
            this.element.find('.filter-category').removeClass(openClass);
            $filterContents.addClass('hidden');
            this.element.find('[data-content-category-id="'+ $filterCategory.prop('id') +'"]').removeClass('hidden');
            $filterCategory.addClass(openClass);
            this.slideDown($filterBody);
        },

        close: function(isSecondaryClose) {
            var $filterBody = this.element.find('.filter-body');

            this.element.removeClass(openClass);
            this.slideUp($filterBody);
            this.element.find('.filter-category').removeClass(openClass);
            // Don't publish a uitk msg for cases when the closing is a secondary action as a result of apply or clear buttons
            if (!isSecondaryClose) {
                uitk.publish('filters.close', {
                    id: this.element.id
                });
            }
        },

        reset: function() {
            this.close(true);
            this.filtered = false;
            this.resetCategories();
            uitk.publish('filters.reset', {
                id: this.element.id
            });
        },

        resetSuccess: function(topic, data) {
            this.element.find('.filter-category').removeClass(appliedClass);
            this.element.find('.filter-menu-icon').removeClass(appliedClass);
        },

        resetCategories: function() {
            $.each(this.categories, function(i, category) {
                category.filtered = false;
            });
            this.element.find('.filter-category').removeClass(appliedClass);
        },

        updateCategories: function(categoryIds) {
            var that = this;
            if (categoryIds && categoryIds.length > 0) {
                $.each(categoryIds, function (i, categoryId) {
                    that.categories[categoryId].filtered = true;
                    that.filtered = true;
                    $('#' + categoryId).addClass(appliedClass);
                });
            }
        },

        apply: function() {
            this.close(true);
            uitk.publish('filters.apply', {
                id: this.element.id
            });
        },

        /**
         * Remove the applied class from all filter category headers and the ones that are passed in will contain the applied class.
         * If there were no filters applied, then the applied class on the filter menu icon is turned off.
         * @param topic
         * @param data
         */
        applySuccess: function(topic, data) {
            // Remove the applied class from all categories first
            this.filtered = false;
            this.resetCategories();

            this.updateCategories(data.categoryIds);

            if (this.filtered) {
                this.element.addClass(appliedClass);
            } else {
                this.element.removeClass(appliedClass);
            }
        },

        updateContent: function(topic, data) {
            var categoryContent = data.content;
            var categoryId = data.categoryId;

            if (categoryContent && categoryId) {
                this.element.find('[data-content-category-id="'+ categoryId +'"]').html(categoryContent);
            }
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, Filters);

    // INITIALISE FILTERS
    // This will take in to account any tab state stored in local storage or hash in the URL
    // for tabs that are present on page load
    function initFilters() {
        $('[data-control=filters]')[pluginName]();
    }

    initFilters();

    // Expose init for dynamic client-side rendering
    uitk.initFilters = initFilters;

    //EVENT LISTENERS
    // Show the Filter Category content
    $body.on('click.' + pluginName, '.filter-category', function (e) {
        var $target = $(this).closest('[data-control=filters]');
        $target[pluginName]('showCategory', $(this));
    });

    // Close the Filter content
    $body.on('click.' + pluginName, '.filter-close, .filter-menu-icon', function(e) {
        var $target = $(this).closest('[data-control=filters]');
        $target[pluginName]('close');
    });

    // Reset Filters
    $body.on('click.' + pluginName, '.filter-reset', function(e) {
        var $target = $(this).closest('[data-control=filters]');
        $target[pluginName]('reset');
    });

    // Apply Filters
    $body.on('click.' + pluginName, '.filter-apply', function(e) {
        var $target = $(this).closest('[data-control=filters]');
        $target[pluginName]('apply');
    });

    // Close Filters if anywhere outside of the component is clicked
    $body.on('click.' + pluginName, function(e){
        if ($('[data-control=filters] .filter-category').hasClass(openClass)) {
            if ($(e.target).closest('[data-control="filters"]').length === 0) {
                $('[data-control="filters"]')[pluginName]('close');
            }
        }
    });

}(jQuery, window));
