/*
 *  Project: UI Toolkit Map Plugin
 *  Description: jQuery Google Map Plugin for use in the Expedia.com UI Toolkit
 *  Author: cbates@expedia.com
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function ($, window, undefined) {
    'use strict';

    uitk.map = {};

    uitk.map.events = {
        mapAppended:         'map.appended',
        mapLoaded:           'map.loaded',
        mapClosed:           'map.closed',
        mapInteractionStart: 'map.interaction.start',
        mapInteractionEnd:   'map.interaction.end',
        mapMarkersLoaded:    'map.markersLoaded',
        markerClick:         'map.marker.click',
        markerMouseOver:     'map.marker.mouseover',
        markerMouseOut:      'map.marker.mouseout',
        infowindowClosed:    'map.infowindow.closed',
        infowindowOpened:    'map.infowindow.opened'
    };

    uitk.map.templates = {
        fullscreen: Handlebars.templates['partials/uitk/uitk-map-template'],
        infowindow: Handlebars.templates['partials/uitk/map-infowindow'],
        tooltip:    Handlebars.templates['partials/uitk/map-marker-tooltip'],
        dot:        Handlebars.templates['partials/uitk/map-marker-dot'],
        cdd: 		Handlebars.templates['partials/uitk/map-marker-cdd'],
        pin:        Handlebars.templates['partials/uitk/map-marker-pin']
    };

    uitk.map.apiLoaded = $.Deferred();

    // Downloads the Google Maps API, this has to be ready before anything will work
    uitk.map.loadApi = function (options) {
        var apiUrl,
            config,
            callback = 'uitk.map._loadDependencies';

        config = $.extend(true, uitk.map.config || {}, options || {});

        // Set users maps api for IE(v3.46) vs other browsers
        config.mapApiUrl = /Trident/.test(navigator.userAgent) ? config.mapApiUrl || 'https://maps.googleapis.com/maps/api/js?v=3.46&callback='+callback :
            config.mapApiUrl || 'https://maps.googleapis.com/maps/api/js?v=3&callback='+callback;

        apiUrl = config.mapApiUrl;

        // Set Map Api key. Default to UITK map api key
        config.key = config.key || EG.mapAccessKey;
        apiUrl = apiUrl + '&key=' + config.key;

        // Add channel param.
        // Channel best practices: https://confluence.expedia.biz/display/GSAG/Guidelines+and+Best+Practices#GuidelinesandBestPractices-ChannelParameterBestPractices
        config.channel = 'egencia-' + config.channel || EG.appName || '';
        apiUrl = apiUrl + '&channel=' + config.channel;

        // set language param if defined
        if (config.language) {
            apiUrl = apiUrl + '&language=' + config.language;
        }

        // set up sensor param
        if(config.sensor){
            apiUrl = apiUrl + '&sensor=' + config.sensor;
        }

        // add libraries if defined
        if (config.libs && config.libs.length > 0) {
            apiUrl = apiUrl + '&libraries=' + config.libs.join();
        }

        //load api
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = apiUrl;
        document.body.appendChild(script);
    };

    // Callback given to Google Maps and will get called automatically
    uitk.map._loadDependencies = function () {
        uitk.map.initHtmlMarker();
        initPlugin();
    };

    function initPlugin() {
        // Create the defaults once
        var pluginName = 'uitk_map',
            $body = $('body'),
            $document = $(document),
            defaults = {
                title: false,
                subtitle: false,
                center: '0,0',
                zoom: 15,
                zoomToFit: true,
                eventDebounceTime: 500,
                template: uitk.map.templates.fullscreen,
                infowindowTemplate: uitk.map.templates.infowindow,
                legend: false,
                legendContent: '',
                mapType: 'ROADMAP',
                styles: null,
                maxZIndex: 5000, //if marker without type will have zIndex set at max..
                fullscreen: false
            },
            activeClass = 'active',
            activeBodyClass = 'map-active',
            infomarkerTemplate;

        /* CONSTRUCTOR */
        function Map(element, jsOptions) {
            var newOptions;
            this.element = $(element);
            //this.markers = [];
            this.gmap = null;
            jsOptions = jsOptions || {};
            newOptions = this.element.data() || {};
            this.options = $.extend(true, {}, defaults, newOptions, jsOptions);
            this.configId = this.options.configId;
            this.mapData = uitk.map.data[this.options.configId];
            this.setCustomParams();
            this.init();
        }

        Map.prototype = {
        		
            constructor: Map,

            init: function () {
                // Add data-control attr, Isn't this already there?
                this.element.attr('data-control', 'map');

                // Set up a unique ID for each map canvas
                this.canvasId = uitk.createUniqueId() + '-canvas';

                this.setWrappingElement();

                // Opens map right away if not a fullscreen map
                if (!this.options.fullscreen) {
                    this.open();
                }
            },

            appendHtml: function () {
                var that = this;

                //store reference to map on mapHtml
                this.mapHtml.data('map', this);
                this.element.prepend(this.mapHtml);

                // check if the browser is in a state that is ready to load the map
                setTimeout(function () {
                    //activate map to display it
                    that.mapHtml.addClass(activeClass);
                    if (that.options.fullscreen) {
                        $body.addClass(activeBodyClass);
                    }
                    that.loadMap();
                }, 100);
            },

            setCustomParams: function () {
                if (uitk.map.data[this.configId].customParams) {
                    this.options = $.extend(true, this.options, uitk.map.data[this.configId].customParams);
                }
            },

            setWrappingElement: function () {
                var mapData = this.getMapData();
                this.mapHtml = $('<div id="' + this.canvasId + '" class="map-canvas"></div>');
            },

            getMapData: function () {
                var mapData = {},
                    options = this.options;

                mapData.title = options.title;
                mapData.subtitle = options.subtitle;
                mapData.id = options.configId;
                mapData.legend = options.legend;
                mapData.legendcontent = options.legendContent;
                mapData.canvasid = this.canvasId;

                return mapData;
            },

            getData: function (property) {
                var mapConfig = uitk.map.data,
                    currentMapConfig,
                    data = {};

                if (this.options.configId) {
                    if (mapConfig) {
                        currentMapConfig = mapConfig[this.options.configId];
                        if (currentMapConfig) {
                            data = currentMapConfig[property];
                        }
                    }
                }
                else if (this.options.coordinates) {
                    data.markers = [{ coordinates: this.options.coordinates }];
                }
                return data;
            },


            loadToggleGroups: function () {
                var that = this,
                    toggleGroupDatas = that.getData('toggleGroups'),
                    toggleGroupControlFieldset = $('<fieldset class="toggleGroups"></fieldset>');
                if (toggleGroupDatas && toggleGroupDatas.length) {
                    for (var i = 0; i < toggleGroupDatas.length; i++) {
                        var toggleGroupData = toggleGroupDatas[i];
                        toggleGroupData.toggled = toggleGroupData.defaultToggled;

                        if (!that.toggleGroups) {
                            that.toggleGroups = {};
                        }
                        toggleGroupData.toggles = [];
                        that.toggleGroups[toggleGroupData.key] = toggleGroupData;

                        var checked = !!toggleGroupData.defaultToggled,
                            span = $('<span class="inline-label"></span>').text(toggleGroupData.localizedName),
                            input = $('<input type="checkbox" name="toggleGroup" />').attr('id', toggleGroupData.key).attr('value', toggleGroupData.key).prop('checked', checked),
                            label = $('<label class="check toggleGroup"></label>').attr('for', toggleGroupData.key).append(input).append(span);

                        toggleGroupControlFieldset.append(label);
                    }
                    that.gmap.controls[google.maps.ControlPosition.RIGHT_TOP].push(toggleGroupControlFieldset[0]);

                    $('#' + that.canvasId).on('change', '.toggleGroup input', function () {
                        var $this = $(this),
                            checked = $this.is(':checked'),
                            toggleKey = $this.val();
                        that.updateToggleItems(toggleKey, checked);
                    });
                }
            },

            queueToggle: function (toggleKey, item) {
                var that = this;
                if (toggleKey && that.toggleGroups[toggleKey] && item && item.setMap) {
                    that.toggleGroups[toggleKey].toggles.push(item);
                }
            },

            setInitialToggleStates: function () {
                var that = this;
                for (var toggleKey in that.toggleGroups) {
                    var toggleGroup = that.toggleGroups[toggleKey];
                    that.updateToggleItems(toggleKey, toggleGroup.toggled);
                }
            },

            updateToggleItems: function (toggleKey, toggled) {
                var that = this,
                    toggleGroup = that.toggleGroups[toggleKey],
                    toggleItems = toggleGroup.toggles,
                    mapObj = (toggled) ? that.gmap : null;
                toggleGroup.toggled = toggled;
                for (var i = 0; i < toggleItems.length; i++) {
                    if(toggleItems[i].isHtmlMarker) {
                        toggleItems[i].toggle(toggled);
                    } else {
                        toggleItems[i].setMap(mapObj);
                    }
                }
            },

            setCenter: function (coordinates) {
                this.options.center = coordinates;
                this.gmap.setCenter(this.getLatLong(this.options.center));
            },

            removeMarkers: function (markersId) {
                var count;

                if (markersId) {
                    count = this.markers[markersId].length;
                    for (var i = 0; i < count; i++) {
                        //google.maps.event.clearInstanceListeners(this.markers[markersId][i]); // deleting the array below removes all references, listener will get garbage collected
                        if(this.markers[markersId][i].markerInfowindow) {
                            this.markers[markersId][i].markerInfowindow.close();
                        }
                        this.markers[markersId][i].remove();
                    }
                    uitk.map.data[this.configId].markerData[markersId].length = 0;
                    this.markers[markersId].length = 0;
                    if(this.mapOverlay) {
                    	this.mapOverlay.removeMarkers(markersId);
                    }                   
                } else {
                    if (uitk.map.data[this.configId].markerData.constructor === Array) {
                        count = this.markers.length;
                        for (var j = 0; j < count; j++) {
                            //google.maps.event.clearInstanceListeners(this.markers[i]);
                            if(this.markers[j].markerInfowindow) {
                                this.markers[j].markerInfowindow.close();
                            }
                            this.markers[j].remove();
                        }
                        uitk.map.data[this.configId].markerData.length = 0;
                        this.markers.length = 0;
                    } else {
                        $.each(this.markers, function (key, markers) {
                            for (var k = 0; k < markers.length; k++) {
                                if(markers[k].markerInfowindow) {
                                    markers[k].markerInfowindow.close();
                                }
                                markers[k].remove();
                            }
                            markers.length = 0;
                        });
                        // TODO Delete each markerSet too or leave empty markerSets?
                    }
                    if(this.mapOverlay) {
                    	this.mapOverlay.removeMarkers();
                    }
                }
            },

            /**
             * Update markerData and load markers onto the Map
             *
             * @param markers - new markers
             * @param markersId - the markerData to add new markers to (if targeting a specific markerData obj)
             * @param options
             */
            updateMarkers: function (markers, options) {
                options = options || {};
                options.markers = markers;

                if (options.markersId) {
                    if (options.remove) {
                        this.removeMarkers(options.markersId);
                        uitk.map.data[this.configId].markerData[options.markersId] = markers;
                    } else {
                        Array.prototype.push.apply(uitk.map.data[this.configId].markerData[options.markersId], markers);
                    }
                }
                else {
                    if (options.remove) {
                        this.removeMarkers();
                        uitk.map.data[this.configId].markerData = markers;
                    } else {
                        Array.prototype.push.apply(uitk.map.data[this.configId].markerData, markers);
                    }
                }

                this.loadMarkers(options);
            },

            updateMarker: function(marker, options) {
                options = options || {};
                marker.bounce(options.bounce);
            },

            // Loops through markerData and creates the Markers
            loadMarkers: function (options) {
                var that = this,
                    markerObj,
                    zoomToFit,
                    LatLngList = [],
                    htmlMarkers = [];
                options = options || {};

                // New markers to add
                if (options.markers) {
                    markerObj = options.markers;
                }
                // Initial markers
                else {
                    markerObj = uitk.map.data[this.configId].markerData;
                }
                
                this.currMarkerData = null;

                if (markerObj.constructor === Array) {
                    that.markers = that.markers || [];
                    $.each(markerObj, function (i, markerData) {
                    	var marker = that.addMarker(markerData);
                    	if (options.markersId) {
                            that.markers[options.markersId].push(marker);
                        }
                        else {
                            that.markers.push(marker);
                        }
                    	if(marker.isHtmlMarker) {
                    		htmlMarkers.push(marker); // if marker could not be be added to a map
                    	}
                        var coordinates = that.getLatLong(markerData.coordinates);
                        if (coordinates){
                            LatLngList.push(coordinates);
                        }
                    });
                }
                else {
                    that.markers = {};
                    $.each(markerObj, function (key, markers) {
                        that.markers[key] = that.markers[key] || [];
                        $.each(markers, function (i, markerData) {
                            var marker = that.addMarker(markerData);
                            if(!marker){ return; };

                        	that.markers[key].push(marker);
                        	if(marker.isHtmlMarker) {
                        		htmlMarkers.push(marker); // if marker could not be be added to a map
                        	}
                            var coordinates = that.getLatLong(markerData.coordinates);
                            //TODO this puts ALL markers in and causes trouble when we want only one collection to have zoomToFit
                            if (coordinates){
                                LatLngList.push(coordinates);
                            }
                        });
                        
                    });
                }
                
            	if(!that.mapOverlay) {
            		that.mapOverlay = new uitk.map.MapOverlay({map: this.gmap, markersId: options.markersId, markers: htmlMarkers});
            	}  else {
            		that.mapOverlay.updateMarkers(({markersId: options.markersId, markers: htmlMarkers}));
            	}
                
                // Zoom to fit all markers if the option has been set and when doing the inital load
                // NOTE: when updating markers zoomToFit would only apply for the new markers being added
                if (options.zoomToFit !== null && options.zoomToFit !== undefined) {
                    zoomToFit = options.zoomToFit;
                }
                else {
                    zoomToFit = this.options.zoomToFit;
                }

                if (zoomToFit) {
                    //  Create a new viewpoint bound
                    this.bounds = new google.maps.LatLngBounds();
                    //  Go through each...
                    for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
                        //  And increase the bounds to take this point
                        this.bounds.extend(LatLngList[i]);
                    }
                    //  Fit these bounds to the map
                    this.gmap.fitBounds(this.bounds);
                }

            },

            /**
             * Adds a single marker to the map, binds some Marker events,
             * and depending on marker implementation may add it to the map
             * @param markerData
             * @returns {*}
             */
            addMarker: function (markerData) {
                var that = this,
                    marker,
                    coordinates = that.getLatLong(markerData.coordinates),
                    type = markerData.type,
                    template = uitk.map.templates[type] || uitk.map.templates.tooltip,
                    strippedTitle;

                if(typeof coordinates !== 'undefined') {
                    // Pick the kind of marker this one should be, defaults to Google's red pin
                    switch (type) {
                        case 'tooltip':
                        case 'dot':
                        case 'cdd':
                        case 'pin':
                            marker = new uitk.map.HtmlMarker({
                                id: markerData.id,
                                content: markerData.content,
                                position: coordinates,
                                zIndex: markerData.zIndex,
                                hover: true,
                                labelContent: template(markerData)
                            });                         
                            marker.hover = true; // why here and not above?
                            break;
                        case 'custom':
                            marker = that._customMarker();
                            break;
                        default:
                            if (typeof markerData.name != 'undefined') {
                                // strip html and line breaks for hover tooltip
                                strippedTitle = markerData.name.replace(/(<([^>]+)>)/ig, '').replace(/(\r\n|\n|\r)/gm, '');
                            }
                            marker = new uitk.map.DefaultMarker({
                                map: this.gmap,
                                title: strippedTitle,
                                content: markerData.content,
                                position: coordinates,
                                hover: true,
                                zIndex: (markerData.zIndex) ? markerData.zIndex : that.options.maxZIndex
                            });
                    }
                    
                    /* Will automatically open infowindow*/
                    if (markerData.showContent) {
                        that.showMarkerInfo(marker, markerData);
                    }

                    // Event listener when marker is clicked
                    marker.addListener('click', function () {
                        uitk.publish(uitk.map.events.markerClick, {map: that, marker: markerData});
                        that.showMarkerInfo(marker, markerData);
                    });

                    // Event listeners for changing icons
                    if (marker.hover) {
                        marker.addListener('mouseover', function () {
                            uitk.publish(uitk.map.events.markerMouseOver, {map: that, marker: markerData});
                        });

                        marker.addListener( 'mouseout', function () {
                            uitk.publish(uitk.map.events.markerMouseOut,  {map: that, marker: markerData});
                        });
                    }

                    // Toggle group?
                    var toggleKey = markerData.toggleGroup;
                    if (toggleKey) {
                        that.queueToggle(toggleKey, marker);
                    }

                    // The z-index for each marker in the list will move down by one (doesn't Google do this?)
                    that.options.maxZIndex--;
                } else {
                    console.error(`Invalid marker data: ${JSON.stringify(markerData.coordinates)}`);
                }

                return marker;
            },

            _customMarker: function (markerType, letter) {
                var marker = {},
                    corePath = uitk.corePath();
                marker.icon = {};
                marker.shadow = {};
                marker.letter = letter;
                switch (markerType) {
                    case 'blue-pin':
                        marker.icon.url = corePath + 'images/blu-marker.png';
                        break;
                    case 'red-dot':
                        marker.icon.url = corePath + 'images/reddot.png';
                        marker.icon.size = new google.maps.Size(10, 10);
                        marker.icon.anchor = new google.maps.Point(5, 5);
                        break;
                    case 'red-pin':
                        marker.icon.url = corePath + 'images/red-marker.png';
                        if (typeof marker.letter === 'string' && marker.letter.length) {
                            marker.icon.url = 'http://mt.google.com/vt/icon?psize=16&font=fonts/Roboto-Regular.ttf&color=ff330000&name=icons/spotlight/spotlight-waypoint-b.png&ax=44&ay=48&scale=1&text=' + marker.letter;
                        }
                        break;
                    case 'grey-pin':
                        marker.icon.url = corePath + 'images/grey-marker.png';
                        break;
                    case '':
                    case undefined:
                        marker.icon.url = corePath + 'images/red-marker.png';
                        break;
                    default:
                        var size = markerType.size,
                            anchor = markerType.anchor,
                            url = markerType.url;
                        if (url) {
                            if (size) {
                                marker.icon.size = new google.maps.Size(size.x, size.y);
                            }

                            if (anchor) {
                                marker.icon.anchor = new google.maps.Point(anchor.x, anchor.y);
                            }
                            marker.icon.url = url;
                        } else {
                            marker.icon.url = corePath + 'images/red-marker.png';
                        }
                }
                return marker;
            },

            showMarkerInfo: function (marker, markerData) {

                var that = this,
                    html = '',
                    title,
                    content,
                    context,
                    width;

                // check if marker was found
                if (marker && markerData) {

                    title = markerData.name;
                    content = markerData.content;
                    width = markerData.width;

                    // only open if the markerData has content
                    if (typeof content != 'undefined') {

                        if (!infomarkerTemplate) {
                            infomarkerTemplate = this.options.infowindowTemplate;
                        }
                        try{
                            context = {
                                title:title,
                                secondaryContent:content
                            };
                            if (!!width ){
                                context.markerWidth = width;
                            }
                            html = infomarkerTemplate(context);
                        }catch(e){
                            html='';
                        }

                        if(marker.markerInfowindow) {
                            marker.markerInfowindow.open(that.gmap, marker);
                        } else if (markerData.showContent && !marker.markerInfowindow) {
                            var markerInfowindow = that.customGoogleMapsInfoWindow({maxWidth: this.options.infoWindowMaxWidth || 400});
                            markerInfowindow.setContent(html);
                            markerInfowindow.open(that.gmap, marker);
                            marker.markerInfowindow = markerInfowindow;
                        } else {
                            that.infowindow.setContent(html);
                            that.infowindow.open(that.gmap, marker);
                        }

                        uitk.publish(uitk.map.events.infowindowOpened, {marker: markerData});

                        // set the currMarkerData value so that it can be returned when the infoWindow is closed
                        that.currMarkerData = markerData;

                        // publish map.marker.opened topic
                        this.currMarkerData = markerData;
                    }
                }
            },

            //This will return infowindow with required listeners for bringing infowindow to front on clicking
            customGoogleMapsInfoWindow: function(options) {
                 var googleMaps = google.maps,
                     googleMapsEvent = googleMaps.event,
                     googleMapsInfoWindow = new googleMaps.InfoWindow(),
                     clickEvent;

                    if(!googleMaps.InfoWindowMaxZIndex){
                       googleMaps.InfoWindowMaxZIndex=Number(googleMaps.Marker.MAX_ZINDEX);
                    }

                    googleMapsEvent.addListener(googleMapsInfoWindow,'content_changed',function() {
                        //Content should be an element and not string, since we need a DOMListener instead a listener for the infowindow-object
                        if (typeof this.getContent()=='string') {
                            var n = document.createElement('div');
                             n.innerHTML=this.getContent();
                             this.setContent(n);
                             return;
                        }
                        googleMapsEvent.addListener(this,'domready', function() {
                            var _this=this;
                            _this.setZIndex(++googleMaps.InfoWindowMaxZIndex);
                            if(clickEvent){
                                googleMapsEvent.removeListener(clickEvent);
                            }
                            clickEvent = googleMapsEvent.addDomListener(this.getContent().parentNode
                                             .parentNode.parentNode,'click',
                                             function(){
                                                 _this.setZIndex(++googleMaps.InfoWindowMaxZIndex);
                            });
                        });
                    });

                    if (options){
                        googleMapsInfoWindow.setOptions(options);
                    }
                    return googleMapsInfoWindow;
             },

            closeMarkerInfo: function () {

                this.infowindow.close();
                // publish if currMarkerData is not null
                if (uitk && uitk.publish && (this.currMarkerData !== null)) {
                    uitk.publish(uitk.map.events.infowindowClosed, this.currMarkerData);
                }
                // then set the currMarkerData value to null
                this.currMarkerData = null;
            },

            loadPolygons: function () {
                var that = this,
                    polygonData = that.getData('polygonData'),
                    polygonList = [];

                if (polygonData) {
                    var polygons = polygonData.polygons;

                    if (polygons && $.isArray(polygons)) {
                        for (var i = 0; i < polygons.length; i++) {
                            var polygon = polygons[i],
                                googlePolygon = that.loadPolygon(polygon);
                            polygonList.push(googlePolygon);
                        }
                    }
                }
                that.polygonList = polygonList;
            },

            loadPolygon: function (strPolygon) {
                var that = this,
                    strLatLngs = strPolygon.latLongs,
                    googleLatLngs = that.convertPolygonLatLngsToGoogle(strLatLngs),
                    googlePolygon = that.buildGooglePolygon(strPolygon, googleLatLngs);

                var toggleKey = strPolygon.toggleGroup;
                if (toggleKey) {
                    that.queueToggle(toggleKey, googlePolygon);
                }

                google.maps.event.addListener(googlePolygon, 'mouseover', function(){
                    if(!this.active) {
                        this.setOptions(this.hoverStyle);
                    }
                });

                google.maps.event.addListener(googlePolygon, 'mouseout', function(){
                    if(!this.active) {
                        this.setOptions(this.defaultStyle);
                    }
                });

                google.maps.event.addListener(googlePolygon, 'click', function(){
                    var active = !this.active;
                    that.clearPolygonActiveState();
                    this.active = active;
                    that.togglePolygonActiveState(this);
                    that.openPolygonInfo(this);
                });

                return googlePolygon;
            },

            convertPolygonLatLngsToGoogle: function (strLatLngs) {
                var that = this,
                    googleLatLngs;
                if (strLatLngs && $.isArray(strLatLngs)) {
                    googleLatLngs = [];
                    for (var i = 0; i < strLatLngs.length; i++) {
                        var strLatLng = strLatLngs[i],
                            googleLatLng = that.getLatLong(strLatLng);
                        if (googleLatLng) {
                            googleLatLngs.push(googleLatLng);
                        }
                    }
                }
                return googleLatLngs;
            },

            buildGooglePolygon: function (strPolygon, googleLatLngs) {
                var that = this,
                    defaultStyle,
                    hoverStyle,
                    activeStyle,
                    googlePolygonParams,
                    googlePolygon;
                if (strPolygon && googleLatLngs && $.isArray(googleLatLngs) && googleLatLngs.length > 0) {
                    defaultStyle = that.buildPolygonStyleObj(strPolygon);
                    hoverStyle = that.buildPolygonStyleObj(strPolygon.hoverStyle, defaultStyle);
                    activeStyle = that.buildPolygonStyleObj(strPolygon.activeStyle, defaultStyle);
                    googlePolygonParams = $.extend({map: that.gmap, paths: googleLatLngs}, defaultStyle);
                    googlePolygon = new google.maps.Polygon(googlePolygonParams);
                    googlePolygon.defaultStyle = defaultStyle;
                    googlePolygon.hoverStyle = hoverStyle;
                    googlePolygon.activeStyle = activeStyle;
                    googlePolygon.content = strPolygon.content;
                }
                return googlePolygon;
            },

            buildPolygonStyleObj: function (overrides, defaults) {
                var s = {
                        strokeColor: '#FFF',
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: '#FFF',
                        fillOpacity: 0.5
                    },
                    d = (defaults) ? {
                        strokeColor: defaults.strokeColor,
                        strokeOpacity: defaults.strokeOpacity,
                        strokeWeight: defaults.strokeWeight,
                        fillColor: defaults.fillColor,
                        fillOpacity: defaults.fillOpacity
                    } : {},
                    o = (overrides) ? {
                        strokeColor: overrides.strokeColor,
                        strokeOpacity: overrides.strokeOpacity,
                        strokeWeight: overrides.strokeWeight,
                        fillColor: overrides.fillColor,
                        fillOpacity: overrides.fillOpacity
                    } : {},
                    output = $.extend(s, d, o);
                return output;
            },

            openPolygonInfo: function(polygon) {
                var method = 'removeClass',
                    html;
                if(polygon.content) {
                    if (polygon.active) {
                        html = polygon.content;
                        method = 'addClass';
                    }
                    $('#polygonLegend').html(html);
                    $('.map-overlay')[method]('polygon-legend');
                    google.maps.event.trigger(this.gmap, 'resize');
                }
            },

            togglePolygonActiveState: function(polygon) {
                var that = this,
                    styles = (polygon.active) ?  polygon.activeStyle : polygon.hoverStyle;
                polygon.setOptions(styles);
            },

            clearPolygonActiveState: function() {
                var that = this;
                if(that.polygonList) {
                    for(var i=0; i<that.polygonList.length; i++) {
                        var polygon = that.polygonList[i];
                        polygon.active = false;
                        polygon.setOptions(polygon.defaultStyle);
                    }
                }
            },

            loadMap: function () {

                var that = this,
                    latlong = this.getLatLong(this.options.center),
                    options = this.options,
                    mapOptions,
                    mapData,
                    mapChangeEndCallback;

                var mapChangeEnd = function (options) {
                    that.mapInteractionCallback(uitk.map.events.mapInteractionEnd, options);
                    // Re-bind event listener for zoom start
                    //google.maps.event.addListenerOnce(that.gmap, 'zoom_changed', that.mapInteractionCallback(uitk.map.events.mapInteractionStart));
                };

                if (options.eventDebounceTime && options.eventDebounceTime > 0) {
                    mapChangeEndCallback = _.debounce(mapChangeEnd, options.eventDebounceTime);
                }
                else {
                    mapChangeEndCallback = mapChangeEnd;
                }

                mapOptions = {
                    center: latlong,
                    zoom: options.zoom,
                    mapTypeId: google.maps.MapTypeId[options.mapType],
                    styles: options.styles,
                    // Google Map UI defaults
                    disableDefaultUI: true,
                    zoomControl: true,
                    // Disable clicking the POI
                    clickableIcons: false,
                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.SMALL,
                        position: google.maps.ControlPosition.LEFT_BOTTOM
                    }
                };

                // disable zoom/pan options for map when on touch device and zoomed in
                if (uitk.isTouchDevice && options.fullscreen) {
                    var zoomRatio = document.documentElement.clientWidth / window.innerWidth;
                    if (zoomRatio > 1.01) {
                        mapOptions.draggable = false;
                        mapOptions.scrollwheel = false;
                    }
                }

                // extend mapOptions if any have been added to the map config data
                if (options.configId) {
                    mapData = uitk.map.data[options.configId];
                    if (mapData && typeof mapData.mapOptions === 'function') {
                        $.extend(mapOptions, mapData.mapOptions());
                    }
                }

                this.gmap = new google.maps.Map(document.getElementById(this.canvasId), mapOptions);
                // push Map object to data to expose google map and markers
                uitk.map.data[options.configId].module = this;
                this.infowindow = new google.maps.InfoWindow({
                    maxWidth: options.infoWindowMaxWidth || 400,
                    pixelOffset: new google.maps.Size(0, -4)
                });
                this.loadToggleGroups();
                this.loadMarkers();
                this.loadPolygons();
                this.setInitialToggleStates();
                uitk.mapObj = this.gmap;

                //publish that map has been appended to the page (and loaded)
                uitk.publish(uitk.map.events.mapAppended, this);

                // keep the map centered when the window is resized
                // Use debounce for performance rather than the native browser resize
                uitk.subscribe('debounced.resize', function () {
                    var latlong;
                    if (options.zoomToFit) {
                        that.gmap.fitBounds(that.bounds);
                    } else {
                        latlong = that.getLatLong(that.options.center);
                        that.gmap.setCenter(latlong);
                    }
                });

                // Event listener when infowindow is closed
                google.maps.event.addListener(this.infowindow, 'closeclick', function () {
                    that.closeMarkerInfo();
                });

                // Add event listener to close infowindow when map is clicked
                google.maps.event.addListener(this.gmap, 'click', function () {
                    that.closeMarkerInfo();
                });

                // Add event listeners for pan/zoom

                // Google's fitBounds() causes a zoom_changed event to fire that we don't want to listen to
                // so we listen once the first time, ignore it, and re-bind to listen to all future zoom_changed events.
                if (this.options.zoomToFit) {
                    google.maps.event.addListenerOnce(this.gmap, 'zoom_changed', function () {
                        // Google fires this after zoomToFit and we want to ignore it
                        // Re-bind for all future zoom events
                        google.maps.event.addListenerOnce(that.gmap, 'zoom_changed', function () {
                            that.mapInteractionCallback(uitk.map.events.mapInteractionStart, {eventname: 'zoom_changed'}); // This gets us the first zoom start, no other zoom starts are accessible
                        });
                        google.maps.event.addListener(that.gmap, 'zoom_changed', function () {
                            mapChangeEndCallback({eventname: 'zoom_changed'});
                        });
                    });
                }
                else {
                    google.maps.event.addListenerOnce(this.gmap, 'zoom_changed', function () {
                        that.mapInteractionCallback(uitk.map.events.mapInteractionStart, {eventname: 'zoom_changed'});
                    });
                    google.maps.event.addListener(this.gmap, 'zoom_changed', function () {
                        mapChangeEndCallback({eventname: 'zoom_changed'});
                    });
                }

                google.maps.event.addListener(this.gmap, 'dragstart', function () {
                    that.mapInteractionCallback(uitk.map.events.mapInteractionStart, {eventname: 'dragstart'});
                });

                google.maps.event.addListener(this.gmap, 'dragend', function () {
                    mapChangeEndCallback({eventname: 'dragend'});
                });
            },

            mapInteractionCallback: function (uitkEvent, options) {
                var bounds = this.gmap.getBounds();
                var neBounds = [bounds.getNorthEast().lat(), bounds.getNorthEast().lng()];
                var swBounds = [bounds.getSouthWest().lat(), bounds.getSouthWest().lng()];
                var center = [bounds.getCenter().lat(), bounds.getCenter().lng()];
                var zoom = this.gmap.getZoom();
                var googleEventName = options.eventname || '';

                uitk.publish(uitkEvent, {neBounds: neBounds, swBounds: swBounds, center: center, zoom: zoom, googleEventName: googleEventName});
            },

            open: function () {
                var that = this;

                if (uitk.isTouchDevice) {
                    that.scrollPosition = $document.scrollTop();
                }

                that.appendHtml();
            },

            // close the map
            close: function () {
                var that = this;

                //publish map.close and include this map object so it can be referenced from the subscriber.
                if (uitk && uitk.publish) {
                    uitk.publish('map.close', that);
                }

                //close map
                this.mapHtml.removeClass(activeClass);

                //remove map from dom
                setTimeout(function () {
                    that.mapHtml.remove();
                    uitk.mapObj = null;
                    delete uitk.mapObj;
                }, 600);
            },

            getLatLong: function (obj) {
                var latLng, latitude, longitude, splitChar = ',';

                // String
                if (typeof obj === 'string' && obj.indexOf(splitChar) > 0) {
                    var latlongArray = obj.split(splitChar);
                        latitude = parseFloat(latlongArray[0]);
                    longitude = parseFloat(latlongArray[1]);
                }
                // Array
                else if (Object.prototype.toString.call(obj) === '[object Array]' && obj.length === 2) {
                    latitude = parseFloat(obj[0]);
                    longitude = parseFloat(obj[1]);
                }

                if (typeof latitude === 'number' && !isNaN(latitude) && typeof longitude === 'number' && !isNaN(longitude)) {
                    latLng = new google.maps.LatLng(latitude, longitude);
                }

                return latLng;
            }
        };


        /* JQUERY PLUGIN DEFINITION */
        $.fn[pluginName] = function () {

            var options, method, arg;

            if (typeof arguments[0] === 'object') {
                options = arguments[0];
                method = arguments[1];
                arg = arguments[2];
            } else {
                options = {};
                method = arguments[0];
                arg = arguments[1];
            }

            return this.each(function () {
                if (!$.data(this, pluginName)) {
                    $.data(this, pluginName, new Map(this, options));
                }
                if (typeof method === 'string') {
                    $.data(this, pluginName)[method](arg);
                }
            });
        };

        //init country code select on page load
        $('[data-control="map"]')[pluginName]();

        //helper functions available in uitk.map
        uitk.map.create = function (options) {
            var newMap = new Map(null, options);

            newMap.open();

            //returns the new map's js object
            return newMap;
        };

        // publish a topic when initPlugin() completes
        if (uitk && uitk.publish) {
            uitk.publish(uitk.map.events.mapLoaded);
        }

        //expose object so it can be tested without DOM
        uitk.modules.Map = Map;

        uitk.map.apiLoaded.resolve(true);
    } // end initPlugin()

}(jQuery, window));