// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;(function ( $, window, uitk, Modernizr, undefined ) {

    'use strict';

    var errorPrefix = 'UITK BrowserStorage',

        //error types
        localStorage = 'local',
        sessionStorage = 'session',

        // DEPRECATED
        persistentCookieStorage = 'persistentCookies',
        sessionCookieStorage = 'sessionCookies',
        pageStorage = 'page',

        browserStoragePrototype = {
            //save values
            saveItem : function(key, value, expiry){
                var type = this.type,
                    saveItemErrorPrefix = ' saveItem(key, value, expiry): ';

                if (typeof key !== 'string'){
                    throw new TypeError(errorPrefix + saveItemErrorPrefix + '\'key\' must be a string');
                }
                if (typeof value !== 'string' && type !== pageStorage){
                    throw new TypeError(errorPrefix + saveItemErrorPrefix + '\'value\' must be a string');
                }
                if (expiry && typeof expiry !== 'number' && (type === persistentCookieStorage || type === sessionCookieStorage)){
                    throw new TypeError(errorPrefix + saveItemErrorPrefix + '\'expiry\' must be a number');
                }
                this.endpoint.setItem(key, value, expiry);
            },
            saveItems: function(keyValueObj, expiry){
                if (typeof keyValueObj === 'object'){
                    for (var key in keyValueObj){
                        if (keyValueObj.hasOwnProperty(key)){
                            this.saveItem(key, keyValueObj[key], expiry);
                        }
                    }
                }else{
                    throw new TypeError(errorPrefix + ' saveItems(keyValueObj): \'keyValueObj\' must be an object');
                }
            },
            saveAsJSON : function(key, value, expiry){
                if (JSON) {
                    this.saveItem(key, JSON.stringify(value), expiry);
                }
            },

            //read values
            readItem : function(key){
                if (typeof key === 'string'){
                    return this.endpoint.getItem(key);
                }
                throw new TypeError(errorPrefix + ' readItem(key): \'key\' must be a string');
            },
            readJSONAsObject: function(key) {
                return jQuery.parseJSON(this.readItem(key));
            },
            doesKeyExist : function(key){
                return (this.readItem(key) !== null);
            },

            //delete values
            deleteItem : function(key){
                if (typeof key === 'string'){
                    this.endpoint.removeItem(key);
                }else{
                    throw new TypeError(errorPrefix + ' deleteItem(key): \'key\' must be a string');
                }
            },
            deleteItems : function(keyArray){
                var that = this,
                    arrayLength = keyArray.length,
                    i;

                for(i=0; i<arrayLength; i=i+1){
                    that.endpoint.removeItem(keyArray[i]);
                }
            }
        },

        html5StorageTypes = {},
        hasLocalStorage = Modernizr.localstorage,
        hasSessionStorage = Modernizr.sessionstorage;


    html5StorageTypes[localStorage] = {
        available: hasLocalStorage,
        endpoint: hasLocalStorage ? window.localStorage : {},
        fallback: pageStorage
    };

    html5StorageTypes[sessionStorage] =  {
        available: hasSessionStorage,
        endpoint: hasSessionStorage ? window.sessionStorage : {},
        fallback: pageStorage
    };

    //HTML5 BROWSER STORAGE (used for localStorage and sessionStorage)
    function Html5BrowserStorage(type){
        var that=this;

        if(!html5StorageTypes[type]){
            throw new Error('UITK Html5BrowserStorage: \'type\' must be \'' + localStorage + '\' or \'' + sessionStorage + '\' and not \'' + type + '\'');
        }

        that.type = type;
        that.endpoint = html5StorageTypes[type].endpoint;
    }

    Html5BrowserStorage.prototype = browserStoragePrototype;
    Html5BrowserStorage.prototype.constructor=Html5BrowserStorage;


    //COOKIE BROWSER STORAGE - DEPRECATED
    function CookieBrowserStorage(type, defaultExpiry){
        var that=this;

        if(type !== persistentCookieStorage && type !== sessionCookieStorage){
            throw new Error('UITK CookieBrowserStorage: \'type\' must be \'' + sessionCookieStorage + '\' and not \'' + type + '\'');
        }

        that.type = type;
        that.storage = {};
        that.endpoint = {
            setItem : function(key, value, expiry){
                expiry = expiry || defaultExpiry;

                uitk.createCookie(key, value, expiry);
            },
            getItem : function(key){
                return uitk.readCookie(key);
            },
            removeItem : function(key){
                return uitk.deleteCookie(key);
            }
        };
    }

    CookieBrowserStorage.prototype = browserStoragePrototype;
    CookieBrowserStorage.prototype.constructor=CookieBrowserStorage;


    //PAGE BROWSER STORAGE - DEPRECATED
    function PageBrowserStorage(){
        var that=this;

        that.type = pageStorage;
        that.storage = {};
        that.endpoint = {
            setItem : function(key, value){
                that.storage[key] = value;
            },
            getItem : function(key){
                if (!that.storage.hasOwnProperty(key)){
                    return null;
                }
                return that.storage[key];
            },
            removeItem : function(key){
                if (that.storage.hasOwnProperty(key)){
                    delete that.storage[key];
                }
            }
        };
    }

    PageBrowserStorage.prototype = browserStoragePrototype;
    PageBrowserStorage.prototype.constructor=PageBrowserStorage;


    //add uitk.createBrowserStorage(type[, fallback][, expiry]);
    //'type' equals the type of storage
    //'fallback' equals the type of storage to fallback to if 'type' isn't available. Can equal 'sessionCookie', 'persistentCookie' or 'page'
    uitk.createBrowserStorage = function(type){

        var args = arguments,
            fallback,
            expiry,
            secondArg = args[1],
            thirdArg = args[2];

        //work out what the second argument represents (fallback or expiry)
        if (typeof secondArg === 'string'){
            fallback = secondArg;

            //see if third argument is the expiry
            if(typeof thirdArg === 'number'){
                expiry = thirdArg;
            }
        }else if(typeof secondArg === 'number'){
            expiry = secondArg;
        }


        if (fallback && fallback !== persistentCookieStorage && fallback !== sessionCookieStorage && fallback !== pageStorage){
            throw new Error('uitk.createBrowserStorage: \'fallback\' must be \'' + sessionCookieStorage + '\', or \'\' and not \'' + fallback + '\'');
        }

        if (type === localStorage || type === sessionStorage){
            //check storage type is available
            if (html5StorageTypes[type].available){
                return new Html5BrowserStorage(type);
            }
            //fallback to fallback storage
            type = fallback || html5StorageTypes[type].fallback;
        }

        // DEPRECATED
        if (type === sessionCookieStorage){
            console.warn('Deprecated: browser storage of type \'sessionCookie\' for `uitk.createBrowserStorage` will be removed.');
            return new CookieBrowserStorage(type);
        }

        // DEPRECATED
        if (type === persistentCookieStorage){
            console.warn('Deprecated: browser storage of type \'persistentCookie\' for `uitk.createBrowserStorage` will be removed.');
            expiry = expiry || 365;
            return new CookieBrowserStorage(type, expiry);
        }

        // DEPRECATED
        if (type === pageStorage){
            console.warn('Deprecated: browser storage of type \'page\' for `uitk.createBrowserStorage` will be removed.');
            return new PageBrowserStorage();
        }

        //if type doesn't equal any of the above throw error
        throw new Error('uitk.createBrowserStorage: \'type\' must be \'' + localStorage + '\', \'' + sessionStorage + '\', or \'' + sessionCookieStorage + '\' and not \'' + type + '\'');
    };

}(jQuery, window, uitk, Modernizr));
