;(function($){
    $.fn.ticker = function (finalAmount) {
        if (isNaN(finalAmount)) {
            return this;
        }

        this.amount = parseFloat($(this).data('amount'));

        $(this).animate({amount: parseFloat(finalAmount)},{
            duration: 600,
            easing: 'linear',
            step: function(){
                $(this).data('amount', this.amount).text(uitk.i18n.currency(this.amount));
            },
            complete: function(){
                $(this).data('amount', this.amount).text(uitk.i18n.currency(this.amount));
            }
        });
        return undefined;
    };
})(jQuery);

(function () {
    var pluginName = 'uitk_checkoutBar';

    /* CONSTRUCTOR */
    function CheckoutBar(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions);
    }

    CheckoutBar.prototype = {

        constructor: CheckoutBar,

        init: function () {
            this.$total = $('#checkout-bar-total');
            this.$saveBtn = $('#checkout-bar-save');
            this.$confirmBtn = $('#checkout-bar-confirm');

            // User events
            this.$saveBtn.on('click', $.proxy(this.save, this));
            this.$confirmBtn.on('click', $.proxy(this.confirm, this));

            // Listens for price changes
            uitk.subscribe('checkoutbar.update', this, this.update);
        },

        save: function() {
            uitk.publish('checkoutbar.save', {});
        },

        confirm: function () {
            uitk.publish('checkoutbar.confirm', {});
        },

        update: function(topic, data) {
            this.$total.ticker(data.amount);
        }

    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, CheckoutBar);
    // Expose object for testing
    uitk.modules.CheckoutBar = CheckoutBar;

    // init on page load
    $('[data-control=checkout-bar]')[pluginName]();

})();