/*
 *  Project: UI Toolkit mobileMenu Plugin
 *  Description: jQuery mobileMenu Plugin for use in the Egencia.com UI Toolkit
 *  Author: a-echen@expedia.com
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function ($, window, undefined) {
    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_mobileMenu',
        $html = $('html'),
        $body = $('body'),
        $page = $('#page'),
        $primaryMenu = $('#primary-nav-mobile'),
        $accountMenu = $('#account-nav-mobile'),
        $panel = $('.nav-panel'),
        $panelItem = $panel.children(),
        defaults = {},
        menuContainer = 'nav',
        btnPrimaryToggleSelector = '#primary-nav-toggle',
        btnAccountOpenSelector = '#account-nav-mobile-open',
        btnAccountCloseSelector = '#account-nav-mobile-close',
        hasSubmenuSelector = '.has-submenu',
        hidePanelSelector = '.nav-panel-close',
        visibleClass = 'is-visible',
        showPanelClass = 'show-panel',
        showOffcanvasClass = 'show-offcanvas',
        lockClass = 'lock-scroll',
        dataMenuId = 'data-menu-id',
        clickEvent = 'click.' + pluginName,
        isTouchDevice = uitk.isTouchDevice;


    /* CONSTRUCTOR */
    function MobileMenu(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    MobileMenu.prototype = {

        constructor: MobileMenu,

        init: function () {
            this.swipeControl();
        },

        // Open/close the entire mobile menu
        toggle: function () {
            const that = this;

            if ($primaryMenu.hasClass(visibleClass)) {
                $html.removeClass(lockClass);
                this.element.removeClass(visibleClass);

                this.element.one(uitk.topics.transitionEnd, function(e){
                    e.stopPropagation();
                    if(e.target === that.element[0]) {
                        that.element.removeClass(showPanelClass);
                    }
                });
            }
            else {
                $html.addClass(lockClass);
                this.element.addClass(visibleClass);

                // Close account offcanvas menu if open
                $page.removeClass(showOffcanvasClass);
                $accountMenu.removeClass(showOffcanvasClass);
                $(btnAccountOpenSelector).removeClass(showOffcanvasClass);
            }
        },

        // Slide the menu over to show submenu options
        showPanel: function () {
            const submenuId = this.element.attr(dataMenuId);

            this.element.closest(menuContainer).addClass(showPanelClass);

            // Hide previously selected submenu
            $panelItem.removeClass(visibleClass);

            // Loop through to find corresponding submenu
            $panelItem.each(function() {
                if (submenuId === this.id) {
                    $(this).addClass(visibleClass);
                }
            });
        },

        // Slide the menu back
        hidePanel: function () {
            this.element.closest(menuContainer).removeClass(showPanelClass);
        },

        // Off-canvas menu
        offcanvas: function () {
            if ($accountMenu.hasClass(showOffcanvasClass)) {
                $html.removeClass(lockClass);
                $body.removeClass(showOffcanvasClass);
                $accountMenu.removeClass(showOffcanvasClass);
                $(btnAccountOpenSelector).removeClass(showOffcanvasClass);
            }
            else {
                $html.addClass(lockClass);
                $body.addClass(showOffcanvasClass);
                $accountMenu.addClass(showOffcanvasClass);
                this.element.addClass(showOffcanvasClass);

                // Close primary menu if open
                $primaryMenu.removeClass(visibleClass + ' ' + showPanelClass);
                $(btnPrimaryToggleSelector).removeClass(visibleClass);
            }
        },

        // Bind swipe gesture to the given element
        // TODO Set this up for any element. Currently only used on $accountMenu element
        swipeControl: function () {
            let gestureHandling;
            const that = this;

            if (isTouchDevice) {
                gestureHandling = new Hammer($accountMenu[0]);

                gestureHandling.on('swipe', function(e) {
                    that.slideEnd();
                });
            }
        },

        // Touch Sliding
        slideEnd: function () {
            this.offcanvas();
        }

    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, MobileMenu);

    // Primary menu toggle button
    $body.on(clickEvent, btnPrimaryToggleSelector, function (e) {
        const $target = $(btnPrimaryToggleSelector);

        e.preventDefault();
        $target[pluginName]('toggle');
        $primaryMenu[pluginName]('toggle');
    });

    // Show submenu options
    $(hasSubmenuSelector).on(clickEvent, function (e) {
        e.preventDefault();
        $(this)[pluginName]('showPanel');
    });

    // Hide submenu options
    $(hidePanelSelector).on(clickEvent, function (e) {
        e.preventDefault();
        $(this)[pluginName]('hidePanel');
    });

    // Account mobile open/close button
    $body.on(clickEvent, btnAccountOpenSelector, function (e) {
        const $target = $(btnAccountOpenSelector);

        e.preventDefault();
        $target[pluginName]('offcanvas');
    });

    $body.on(clickEvent, btnAccountCloseSelector, function (e) {
        const $target = $(btnAccountCloseSelector);

        e.preventDefault();
        $target[pluginName]('offcanvas');
    });

    // Expose object so it can be tested more easily
    uitk.modules.mobileMenu = MobileMenu;

}(jQuery, window));