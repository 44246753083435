// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function ($, window, undefined) {

    'use strict';

    var $window = $(window),
        throttleDelay = 300,
        defaultDuration = 300,
        $scrollToTopBtn = $(Handlebars.templates['partials/uitk/scroll-to-top']()),
        scrollToTop = 0,
        showScrollToTop = 1000;

    var isOn = false;


    function enable() {
        if(!isOn) {
            $scrollToTopBtn
                .on('click', top)                       // Binding user events to the element
                .on('animationend', animationEnd);      // Binging TransitionEnd event to the element

            // Binding Window scroll to the element
            $window.on('scroll', $.proxy(_.throttle(toggle, throttleDelay), this, $window));

            $scrollToTopBtn.appendTo('body');

            isOn = true;
        }
    }

    function top() {
        toggle($window);
        scrollTo(scrollToTop, defaultDuration);
    }

    function toggle($window) {
        var isHidden = $scrollToTopBtn.hasClass('hidden');
        if ($window && ($window.scrollTop() > showScrollToTop)) {
            if(isHidden) {
                show();
            }
        } else {
            if (!isHidden) {
                hide();
            }
        }
    }

    function animationEnd(e) {
        e.stopPropagation();
        if(e.target === $scrollToTopBtn[0]) {
            if (e.originalEvent.animationName === 'fadeOutDown') {
                hideEnd();
            }

            $scrollToTopBtn.removeClass('animate-fade-in-up animate-fade-out-down');
        }
    }

    function show() {
        $scrollToTopBtn.removeClass('hidden').addClass('animate-fade-in-up');
    }

    function hide() {
        $scrollToTopBtn.addClass('animate-fade-out-down');
    }

    function hideEnd() {
        $scrollToTopBtn.addClass('hidden');
    }

    function disable() {
        if(isOn) {
            $scrollToTopBtn.remove();
            isOn = false;
        }
    }

    /** This is a modified version of scroll from article by pawelgrzybek.com (https://pawelgrzybek.com/page-scroll-in-vanilla-javascript/) **/
    function scrollTo(destination, duration) {
        var start = window.pageYOffset;
        var startTime = new Date().getTime();

        var documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body[0].clientHeight;
        var destinationOffsetToScroll = Math.round(documentHeight - destination < windowHeight ? documentHeight - windowHeight : destination);

        function scroll() {
            var now = new Date().getTime();
            var ratio = Math.min(1, ((now - startTime) / duration));
            window.scroll(0, Math.ceil((ratio * (destinationOffsetToScroll - start)) + start));

            if (window.pageYOffset === destinationOffsetToScroll) {
                return;
            }

            requestAnimationFrame(scroll);
        }

        scroll();
    }

    // Expose functions for scrollToTop
    uitk.scrollToTop = {
        enable: enable,
        disable: disable
    };

    // Expose functions so it can be tested more easily
    uitk.modules.scrollToTop = {
        $scrollToTopBtn: $scrollToTopBtn,
        enable: enable,
        top: top,
        toggle: toggle,
        animationEnd: animationEnd,
        disable: disable
    };

}(jQuery, window));
