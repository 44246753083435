;(function ($, window, undefined) {

    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_tag',
        $delegate = $('body'),
        defaults = {
            type: null,
            value: null
        },
        clickEvent = 'click.' + pluginName;

    /* CONSTRUCTOR */
    function Tag(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    Tag.prototype = {

        constructor: Tag,

        remove: function(nofade) {
            var data,
                that = this,
                $element = $(this.element),
                $tagGroup = this.element.parent('.tag-group');

            data = {
                id: $element.attr('id') || null,
                groupId:  $tagGroup.data('groupId') || null, // 'data-group-id' is only used by UITK components, apps use regular id attr
                type: this.options.type,
                value: this.options.value
            };

            if (nofade) {
                this.element.remove();
                uitk.publish('tag.remove', data);
            } else {
                // Set up animation
                this.element.addClass('remove-animated animated-fade');

                // Once animation has completed remove object from dom
                this.element.one(uitk.topics.transitionEnd, function(e) {
                    e.stopPropagation();
                    if(e.target === that.element[0]) {
                        that.element.remove();
                        uitk.publish('tag.remove', data);
                    }
                });
            }
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, Tag);

    // Remove button
    $delegate.on(clickEvent, '.tag-remove', function (e) {
        $(this).closest('.tag')[pluginName]('remove');
    });

    // Expose object so it can be tested
    uitk.modules.Tag = Tag;

}(jQuery, window));