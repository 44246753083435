/**
 * Project: UITK Dropdown Plugin
 * Author: Avi Eisner, contact cseteam@expedia.com
 * OPTIONS (passed via data-* attributes in the html)
 */

/* jslint plusplus:true */
/* global jQuery */

/**
 * The semi-colon before function invocation is a safety net against concatenated
 * scripts and/or other plugins which may not be closed properly.
 */

;(function ( $, window, uitk, undefined ) {
    'use strict';

    /** Create the defaults */
    var pluginName = 'uitk_dropDown',
        defaults = {
            gutter: 6
        },
        toggleSelector = '[data-control=menu]',
        $window = $(window),
        windowWidth,
        bodyMinWidth = parseFloat($('body').css('min-width')),
        nonResponsivePage = bodyMinWidth > 950 || Modernizr.mediaqueries === 'false' || Modernizr.mediaqueries === false,
        isIe8 = !document.addEventListener,
        ie8ArrowClass = 'ie8-menu-arrow',
        arrowClass = 'menu-arrow',
        openClass = 'open',
        centerClass = 'pin-center',
        isCentered,
        menuWidth,
        triggerWidth,
        shiftC;

    /** CONSTRUCTOR */
    function DropDown( element, jsOptions ) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    DropDown.prototype = {
        constructor: DropDown,

        /** METHODS */
        init: function() {
            /**
             * First, look for a parent selector in the data-target attribute.
             * If this doesn't exist or successfully select a parent, use the actual parent element.
             */
            var that = this,
                target = that.element,
                selector = target.attr('data-target');

            that.$parent = $(selector);
            that.$parent = that.$parent.length ? that.$parent : target.parent();
        },

        open: function() {
            var that = this;

            that.$parent.addClass(openClass);
            that.element.attr('aria-expanded','true');

            /** Needs storing in case user clicks away and we want to access last opened menu. */
            that.storeOpen(that.element);
            uitk.publish('menu.opened', {$menu:that.element});
        },

        close: function() {
            var that = this;

            that.$parent.removeClass(openClass);
            that.element.attr('aria-expanded','false');
            uitk.publish('menu.closed', {$menu:that.element});
        },

        closeAll: function() {
            var $open = !!$(toggleSelector).parent().hasClass(openClass);

            $(toggleSelector).parent().removeClass(openClass);
            $(toggleSelector).attr('aria-expanded','false');

            if ($open) {
                uitk.publish('menu.closed', $('body').data('menuStored'));
            }
        },

        storeOpen: function($elem){
            $('body').data('menuStored', {$menu: $elem});
        },

        getWindowWidth: function(){
            return $window.width();
        },

        centerMenu: function(){
            var that = this,
                $trigger = that.element,
                $menu = $trigger.next('.menu');

            menuWidth = $menu.outerWidth();
            triggerWidth = $trigger.outerWidth();
            shiftC = (triggerWidth - menuWidth) / 2;
        },

        setPos: function() {
            var that = this,
                trigger = that.element,
                $menu = trigger.next('.menu').first(),
                isNavTab = trigger.hasClass('nav-tab'),
                isPinLeft = trigger.hasClass('pin-left'),
                isBtnGrp = $menu.parent().hasClass('btn-group'),
                isPinRight = trigger.hasClass('pin-right'),
                gutter = that.options.gutter;

            windowWidth = that.getWindowWidth();
            menuWidth = $menu.outerWidth();
            triggerWidth = trigger.outerWidth();
            shiftC = (triggerWidth - menuWidth) / 2;
            isCentered = trigger.hasClass(centerClass);

            /** Set menu position */
            var rect = $menu[0].getBoundingClientRect(),
                menuOffset = {
                    top: rect.top + window.pageYOffset,
                    left: rect.left + window.pageXOffset
                },
                menuOffsetL = menuOffset.left,
                menuOffsetR = windowWidth - (menuOffsetL + menuWidth),
                shiftL = parseInt(menuOffsetL - gutter, 10),
                shiftR = parseInt(menuOffsetR - gutter, 10),
                cssR = parseFloat($menu.css('right')),
                shiftLC = cssR + shiftL,
                shiftRC = cssR - shiftR,
                menuPosL,
                maxShift;
        },

        /** HANDLERS */
        toggle: function() {
            var that = this,
                isActive,
                autoPos = that.options.autoPos,
                trigger = that.element,
                hasArrow = trigger.hasClass(arrowClass),
                last = trigger.children().last(),
                hasIe8Arrow = last.hasClass(ie8ArrowClass);

            isCentered = trigger.hasClass(centerClass);

            if (trigger.is('.disabled, :disabled')) {
                return undefined;
            }

            isActive = that.$parent.hasClass(openClass);

            that.closeAll();

            if (!isActive) {
                that.$parent.toggleClass(openClass);
                /** Needs storing in case user clicks away and we want to access last opened menu. */
                that.storeOpen(that.element);
                uitk.publish('menu.opened', {$menu:that.element});
                that.element.attr('aria-expanded', 'true');

                if (isIe8 && hasArrow) {
                    /**
                     * Only create the arrow if it does not already exist.
                     * Arrow is added via JS for IE8 only. Modern browsers use :after
                     * IE8 can not rotate a pseudo-element bc IE8
                     **/
                    if (!hasIe8Arrow) {
                        trigger.append('<span class="ie8-menu-arrow"></span>');
                    }
                }

                if (autoPos !== false && !nonResponsivePage) {
                    that.setPos();
                }
                else {
                    /** Center menu if autoPos is false or if nonResponsive page */
                    if (isCentered) {
                        that.centerMenu();
                    }
                }
            }
            return false;
        },

        handleResize: function($target) {
            var that = this,
                i,
                autoPos = $target.data('autoPos');

            windowWidth = that.getWindowWidth();

            /** Sets a minWidth to fix issues in IE */
            if (!bodyMinWidth) {
                bodyMinWidth = 255;
            }

            /** Only sets position is autoPos is set and window is wider than 255 */
            if (autoPos !== false && windowWidth > bodyMinWidth) {
                for (i = 0; i < $target.length; i++) {
                    $target[pluginName]('setPos');
                }
            }
            else {
                return false;
            }
            return undefined;
        }
    };

    /** JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, DropDown);

    /**
     * Positions open menus on resize.
     * Excludes old browsers and nonresponsive pages.
     */
    $(window).on('resize.' + pluginName, function(){
        var $target = $(toggleSelector + '[aria-expanded="true"]');

        if (!nonResponsivePage && $target.length > 0) {
            DropDown.prototype.handleResize($target);
        }
    });

    $('html')
        .on('click.dropdown.data-api', {preventDefault:false}, function(e) {
            var $menu = $(e.target).closest('.menu'),
                $toggle = $(e.target).closest(toggleSelector);

            if ($menu && $menu.length > 0 && $menu.hasClass('sticky')) {
                return;
            }

            if ($menu && $menu.length > 0){
                /** Prevents sticky menus from closing when the menu content is clicked. */
                if ($menu.hasClass('sticky')){
                    return;
                }
                /** Timeout before closing menu, which compensates for delay on touch devices. */
                setTimeout(function(){
                    DropDown.prototype.closeAll();
                }, 500);
            }
            else if ($toggle.length === 0) {
                DropDown.prototype.closeAll();
            }
        });
    $('body')
        .on('click.dropdown', '.dropdown form', function(e) {e.stopPropagation();})
        .on('click.dropdown.data-api', toggleSelector, function(e) {
            e.preventDefault();
            var $target = $(e.target).closest(toggleSelector);

            $target[pluginName]('toggle');
        })
        .on('click', '.menu .close', function(e) {
            e.preventDefault();
            var $target = $(e.target).closest('.menu').prev(toggleSelector);

            $target[pluginName]('close');
            uitk.utils.focusElement($target);
        });

    /** Expose object so it can be tested without DOM */
    uitk.modules.DropDown = DropDown;

}(jQuery, window, window.uitk ));