/*
 *  Project: UI Toolkit Alert Plugin
 *  Description: jQuery Alert Plugin for use in the Egencia.com UI Toolkit
 *  Author: a-echen@expedia.com
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function ($, window, undefined) {

    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_alert',
        $body = $('body'),
        defaults = {},
        clickEvent = 'click.' + pluginName;

    /* CONSTRUCTOR */
    function Alert(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    Alert.prototype = {

        constructor: Alert,

        init: function () {
            var that = this,
                timeOut,
                $element = this.element;

            // Remove the element with a dismiss time, default 6000ms
            if (typeof(this.options.dismiss) === 'boolean' && this.options.dismiss) {
                timeOut = 4000;

                setTimeout(function () {
                    that.remove($element);
                }, timeOut);
            } else if (typeof(this.options.dismiss) === 'number') {
                timeOut = this.options.dismiss * 1000;

                setTimeout(function () {
                    that.remove($element);
                }, timeOut);
            }
        },

        remove: function ($target) {
            var that = this;

            // Set up animation
            this.element.addClass('remove-animated animated-fade');

            // Once animation has completed remove object from dom
            this.element.one(uitk.topics.transitionEnd, function (e) {
                e.stopPropagation();
                if(e.target === that.element[0]) {
                    that.element.remove();
                    uitk.publish('alert.remove', $target);
                }
            });
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, Alert);

    // INITIALISE ALERTS
    // for alerts that are present on page load
    var initAlert = function () {
        $('[data-control=alert]')[pluginName]();
    };

    initAlert();

    // Expose initAlert function for dynamic client-side rendering.
    uitk.initAlert = initAlert;

    // Close button
    $body.on(clickEvent, '.alert-remove', function (e) {
        var $target = $(this).closest('[data-control="alert"]');

        e.preventDefault();
        $target[pluginName]('remove', $target);
    });

    // Expose object so it can be tested more easily
    uitk.modules.Alert = Alert;

}(jQuery, window));