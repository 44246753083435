;
(function ($, window, undefined) {
    'use strict';

    var pluginName = 'uitk_modal',
        $body = $('body'),
        $html = $('html'),
        $document = $(document),
        defaults = {
            alert: false,
            title: false,
            titleIcon: '',
            optOut: false,
            ajax: false,
            footer: false,
            confirm: false,
            dismiss: true,
            panel: false,
            panelSize: 'medium',
            template: 'partials/uitk/modal-template',
            state: false,
            container: $body
        },
        activeClass = 'active',
        activePageClass = 'active-modal',
        containerClass = 'modal-wrap',
        innerClass = 'modal-inner',
        backgroundClass = 'modal-background',
        closeClass = 'modal-close',
        modalCache = {data:{}, content:{}},
        clickEvent = 'click.' + pluginName,
        optOutText = 'uitk_dont_show_this_again',
        confirmText = 'uitk_confirm',
        cancelText = 'uitk_cancel',
        currentFocus,
        focusable,
        focusableLast;

    /* CONSTRUCTOR */
    function Modal(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    Modal.prototype = {

        constructor: Modal,

        init: function () {
            this.id = this.options.modalId;
            this.optOutStorage = this.options.optOut && Modernizr.sessionstorage;
            this.modalTemplate = Handlebars.templates[this.options.template];
            this.modalTitleTemplate = Handlebars.templates['partials/uitk/modal-title'];

            if (this.optOutStorage) {
                this.storageKey = 'modal-opt-out-' + this.id;

                // If user opted out halt the init
                if (sessionStorage.getItem(this.storageKey) === 'true') {
                    return false;
                }
            }

            this.setContent();
            this.setHtml();
            return undefined;
        },

        isHtml: function (data) {
            return data.indexOf('>') !== -1;
        },

        setContent: function () {
            var content, cachedContent;
            var that = this;

            if (this.options.content) {
                content = this.options.content;
            }
            else {
                content = 'modal-content-' + this.options.modalId;
                cachedContent = modalCache.content[this.options.modalId];
            }

            if (this.options.ajax) {
                this.contentRequest = $.get(content, function (data) {
                    that.updateContent(data);
                });
            } else {
                if (cachedContent) {
                    this.content = cachedContent;
                }
                else if (
                    (typeof content === 'string' && this.isHtml(content)) 
                    || content instanceof jQuery 
                    || ($.zepto && $.zepto.isZ(content))
                )  {
                        this.content = content;
                        modalCache.content[this.options.modalId] = this.content;
                }
                else if (typeof content === 'string') {
                    if(this.options.state) {
                        this.content = $('#' + content).find('.modal-content').detach();
                    } else {
                        this.content = $('#' + content).find('.modal-content').html();
                        $('#' + content).find('.modal-content').html('');
                    }

                    modalCache.content[this.options.modalId] = this.content;
                }
                else {
                    this.content = '';
                }
            }
        },

        getContent: function() {
            return this.content || modalCache.content[this.options.modalId];
        },

        updateTitle: function (title, subtitle) {
            var $headerElem = this.modalWrap.find('.modal-header'),
                $titleElem = this.modalWrap.find('.modal-title'),
                $subtitleElem = this.modalWrap.find('.modal-subtitle');

            if (!title && !subtitle) {
               $headerElem.remove();
                this.options.title = false;
                this.options.subtitle = false;
            } else {
                if ($headerElem.length > 0) {
                    if($titleElem.length > 0 && title) {
                        $titleElem.html(title);
                        this.options.title = title;
                    } else if ($titleElem.length === 0 && title) {
                        this.modalWrap.find('.modal-inner').prepend($(this.modalTitleTemplate({title: title, id: this.options.modalId})));
                        this.options.title = title;
                    } else{
                        $titleElem.remove();
                        this.options.title = false;
                    }

                    if($subtitleElem.length > 0 && subtitle) {
                        $subtitleElem.html(subtitle);
                        this.options.subtitle = subtitle;
                    } else if ($subtitleElem.length === 0 && subtitle){
                        this.modalWrap.find('.modal-inner').prepend($(this.modalTitleTemplate({subtitle: subtitle, id: this.options.modalId})));
                        this.options.subtitle = subtitle;
                    } else {
                        $subtitleElem.remove();
                        this.options.subtitle = false;
                    }
                } else {
                    this.modalWrap.find('.modal-inner').prepend($(this.modalTitleTemplate({title: title, subtitle: subtitle, id: this.options.modalId})));
                    this.options.title = title || false;
                    this.options.subtitle = subtitle || false;
                }
            }

            return this;
        },

        updateContent: function (content, title, subtitle) {
            if ((typeof content === 'string' && this.isHtml(content)) || content instanceof jQuery || ($.zepto && $.zepto.isZ(content))) {
                this.content = content;
            } else {
                this.content = '';
                this.modalInnerHtml.addClass('loading').attr('aria-busy', 'true');
            }

            if (this.modalWrap) {
                this.modalWrap.find('.modal-body').html(this.content);
                //uitk.initCalendarInputs(this.modalWrap); //this is throwing an error when appending a msg

                this.updateTitle(title, subtitle);

                uitk.publish('modal.appended', this);

                if (this.content !== '') {
                    this.modalInnerHtml.removeClass('loading').attr('aria-busy', 'false');
                }
            }

            return this;
        },

        getModalData: function () {
            var modalData = {};

            if (modalCache.data[this.options.modalId]) {
                modalData = modalCache.data[this.options.modalId];
            }
            else {
                modalData.alert = this.options.alert;
                modalData.alertTitle = this.options.alertTitle;
                modalData.alertIcon = this.options.alertIcon;
                modalData.title = this.options.title;
                modalData.subtitle = this.options.subtitle;
                modalData.titleIcon = this.options.titleIcon;
                modalData.id = this.options.modalId;
                modalData.optOut = this.optOutStorage;
                modalData.optOutText = this.options.optOutText || optOutText;
                modalData.theme = this.options.jsTheme;
                modalData.confirm = this.options.confirm;
                modalData.confirmText = this.options.confirmText || confirmText;
                modalData.cancelText = this.options.cancelText || cancelText;
                modalData.panel = this.options.panel;
                modalData.panelSize = this.options.panelSize;
                modalData.footer = this.options.footer || this.options.optOut || this.options.confirm;

                modalCache.data[this.options.modalId] = modalData;
            }

            return modalData;
        },

        setHtml: function () {
            var modalData = this.getModalData();
            var $renderedTemplate = $(this.modalTemplate(modalData));
            this.modalHtml = $renderedTemplate;
            this.modalHtml.find('.modal-body').html(this.content);
            this.modalWrap = $renderedTemplate.filter('.' + containerClass);
            this.modalBg = $renderedTemplate.filter('.' + backgroundClass);
            this.modalInnerHtml = this.modalWrap.find('.' + innerClass);
        },

        setModalFocus: function () {
            currentFocus = 0;
            // Find focusable elements
            focusable = this.modalWrap.find('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]').filter(':visible').not('[tabindex="-1"]');
            focusableLast = focusable.length - 1;

            if(focusable.length > 0) {
                focusable.first().focus();
            } else {
                this.lastFocused.blur();
            }
        },

        setNextFocus: function (currentFocus, focusableLast) {
            if (currentFocus === focusableLast) {
                return 0;
            }
            return currentFocus + 1;
        },

        setPrevFocus: function (currentFocus, focusableLast) {
            if (currentFocus <= 0) {
                return focusableLast;
            }
            return currentFocus - 1;
        },

        removeModalFocus: function () {
            // Make sure the lastFocused element is still in the DOM before focusing on it
            if (this.lastFocused && this.lastFocused.length > 0) {
                this.lastFocused.focus();
            }
        },

        appendHtml: function () {
            var that = this;

            //store reference to modal on modal
            this.modalWrap.data('modal', this);

            // Update content
            this.modalHtml.find('.modal-body').html(this.getContent());
            //add the html to the page
            this.modalHtml.appendTo(that.options.container);

            //publish that modal has been appended to the page but only if content is included
            if (this.content && uitk && uitk.publish) {
                uitk.publish('modal.appended', that);
            }

            setTimeout(function () {
                $html.addClass(activePageClass);
                // Required to make Firefox animate in
                setTimeout(function () {
                    that.modalWrap.addClass(activeClass);
                    that.modalBg.addClass(activeClass);

                    // Set focus
                    that.setModalFocus();

                    // Close inactive modals
                    closeHiddenModal();
                }, 150);
            }, 0);
        },
        
        open: function (checkManual, event) {
            hideModal();

            // If needed publish modal.beforeOpen and prevent open
            if ((this.options.manual === 'open' || this.options.manual === 'both') && checkManual) {
                uitk.publish('modal.beforeOpen', this, event);
                return false;
            }

            // Store last focused page element
            this.lastFocused = this.element;

            if (this.optOutStorage) {
                // Don't show Modal if user has opted out
                if (sessionStorage.getItem(this.storageKey) === 'true') {
                    return false;
                }
            }

            this.appendHtml();

            return this;
        },

        close: function (type, checkManual, event) {
            var optOutCheckbox, optOut, removeAfterAnim, $modalBackground;
            var that = this;

            // If dismiss is false, don't close on background click
            if ((type === 'background' && !this.options.dismiss) || (type === 'esc' && !this.options.dismiss)) {
                return false;
            }

            // Check data-manual, if need publish modal.beforeClose and stop close
            if ((this.options.manual === 'close' || this.options.manual === 'both') && checkManual) {
                uitk.publish('modal.beforeClose', this, event);
                return false;
            }

            // Focus on last focused element
            this.removeModalFocus();

            // Store details around opting out of modal
            if (this.optOutStorage) {
                optOutCheckbox = this.modalWrap.find('input[name=' + this.options.modalId + '-opt-out]');

                if (optOutCheckbox && optOutCheckbox.prop('checked')) {
                    optOut = 'true';
                } else {
                    optOut = 'false';
                }

                sessionStorage.setItem(this.storageKey, optOut);
            }

            removeAfterAnim = function ($elem) {
                $elem.on(uitk.topics.transitionEnd, function (e) {
                    e.stopPropagation();

                    if(e.target === $elem[0]) {
                        $elem.off(uitk.topics.transitionEnd).remove();
                    }
                });
            };

            // Add listener for removal
            removeAfterAnim(this.modalWrap);
            removeAfterAnim(this.modalBg);

            // Now close modal
            $html.removeClass(activePageClass);
            if (type !== 'confirm') {
                uitk.publish('modal.close', this);
            }

            this.modalWrap.removeClass(activeClass);

            setTimeout(function () {
                that.modalBg.removeClass(activeClass);
            }, 150);

            return this;
        },

        confirm: function(e) {
            uitk.publish('modal.confirm', this);
            this.close('confirm', true, e);
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, Modal);


    /* EVENT LISTENERS (delegated to body) */
    $body.on(clickEvent, '[data-control="modal"]', function (e) {
        var $target = $(e.target).closest('[data-control="modal"]');
        e.preventDefault();
        $target[pluginName]('open', true, e);
    });

    // Close when wrapper is clicked (an off-Modal click)
    $body.on(clickEvent, '.' + containerClass, function (e) {
        if ($(e.target).hasClass(containerClass)) {
            e.preventDefault();
            closeModal('background', true, e);
        }
    });

    // Close and publish when Confirm is clicked
    $body.on(clickEvent, '#modal-confirm', function (e) {
        e.preventDefault();
        $('.' + containerClass).data('modal').confirm(e);
    });

    // Close when Cancel button is clicked
    $body.on(clickEvent, '#modal-cancel', function (e) {
        e.preventDefault();
        closeModal('close-button', true, e);
    });

    // Close when Close button is clicked
    $body.on(clickEvent, '.' + closeClass, function (e) {
        e.preventDefault();
        closeModal('close-button', true, e);
    });

    $document.on('keydown.focus', function (e) {
        var currentModal = $('.' + containerClass + '.' + activeClass);
        if(currentModal.length > 0) {
           switch (e.keyCode) {
               // Tab
               case 9:
                   e.preventDefault();

                   if(focusable.length > 0) {
                       if (e.shiftKey) {
                           currentFocus = currentModal.data('modal').setPrevFocus(focusable.index(e.target), focusableLast);
                       }
                       else {
                           currentFocus = currentModal.data('modal').setNextFocus(focusable.index(e.target), focusableLast);
                       }
                       focusable.eq(currentFocus).focus();
                   }
                   break;
               // Esc
               case 27:
                   closeModal('esc', true, e);
                   break;
               default:
                   break;
           }
        }
    });

    $(window).on('orientationchange', function () {
        var focusedElem = document.activeElement,
            $focusedElem = $(focusedElem),
            focusedElemTag = focusedElem.tagName;

        // If focused element is input or a textarea and it is inside the modal, hide the keyboard to correct the Modal on rotation
        if ((focusedElemTag === 'INPUT' || focusedElemTag === 'TEXTAREA') && !focusedElem.hasAttribute('readonly') && $focusedElem.closest('.modal-inner').length > 0) {
            $focusedElem.blur().focus();
        }
    });

    //fixes an issue on the iPad where bluring an input inside a modal breaks the modal
    $body.on('blur', '.modal-inner input', function (e) {
        if ($(e.target).data('control') !== 'calendar') {
            $body.css('height', '+=10000').css('height', '-=10000');
            setTimeout(function () {
                $body.css('height', '');
            }, 0);
        }
    });

    // Function to close modals
    var closeModal = function (type, checkManual, event) {
        var elems = $('.' + containerClass);

        elems.each(function () {
            $(this).data('modal').close(type, checkManual, event);
        });
    };

    var hideModal = function () {
        var elems = $('.' + containerClass);

        elems.removeClass(activeClass);
        elems.siblings('.' + backgroundClass + '.active').removeClass(activeClass);
    };

    var closeHiddenModal = function() {
        var hidden = $('.' + containerClass + ':not(.' + activeClass + ')');

        hidden.each(function () {
            $(this).data('modal').close();
        });
    };

    //helper functions available in uitk.modal
    uitk.modal = {
        create: function (options, open) {
            var newModal = new Modal(null, options);

            if (open) {
                newModal.open(true);
            }
            //returns the new Modal's js object
            return newModal;
        },
        close: closeModal
    };

    // Expose object so it can be tested without DOM
    uitk.modules.Modal = Modal;

}(jQuery, window));