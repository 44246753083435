;
(function () {

    'use strict';

    var pluginName = 'uitk_searchBar',
        $document = $(document),
        defaults = {},
        clickEvent = 'click.' + pluginName;

    /* CONSTRUCTOR */
    function SearchBar(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions);
    }

    SearchBar.prototype = {

        constructor: SearchBar,

        init: function () {
            this.$searchBarDisplay = $('#search-bar-display');
            this.$primaryContent = $('#search-bar-content-primary');
            this.$secondaryContent = $('#search-bar-content-secondary');
            this.$tertiaryContent = $('#search-bar-content-tertiary');
            this.$searchBarEdit = $('#search-bar-edit');
            this.$changeSearchBtn = $('#search-bar-change-search');

            // User click change search
            this.$changeSearchBtn.on(clickEvent, $.proxy(this.edit, this));
            // User click anywhere else but search bar
            $document.on(clickEvent, $.proxy(this.cancel, this));

            // Listen for search bar update
            uitk.subscribe('searchbar.update', this, this.update);
        },

        edit: function() {
            // Click on change search button will trigger edit
            this.openEditView();
        },

        update: function(topic, data) {
            // When data key is not specified or empty object then the content will remain the same.
            // When data value is empty string then the content will be removed.
            // When data value is provided then the content will be replaced by the new value.

            if((data.primary && typeof data.primary == 'string') || typeof data.primary == 'string') {
                this.$primaryContent.html(data.primary);
            }

            if((data.secondary && typeof data.secondary == 'string') || typeof data.secondary == 'string' ) {
                this.$secondaryContent.html(data.secondary);
            }

            if((data.tertiary && typeof data.tertiary == 'string') || typeof data.tertiary == 'string') {
                this.$tertiaryContent.html(data.tertiary);
            }

            this.closeEditView();
        },

        cancel: function(e) {
            // Click outside of search bar or outside of a tooltip will trigger cancel
            var $target = $(e.target).closest('[data-control="search-bar"]').length || $(e.target).closest('.uitk-tooltip').length;

            if(!$target) {
                uitk.publish('searchbar.cancel', {});
                this.closeEditView();
            }
        },

       openEditView: function() {
           this.$searchBarEdit.removeClass('hidden');
           this.$searchBarDisplay.addClass('hidden');
        },

        closeEditView: function() {
            this.$searchBarEdit.addClass('hidden');
            this.$searchBarDisplay.removeClass('hidden');
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, SearchBar);
    // Expose object for testing
    uitk.modules.SearchBar = SearchBar;

    // init on page load
    $('[data-control="search-bar"]')[pluginName]();

})();