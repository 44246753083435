(function ($) {
    'use strict';

    var $body = $('body'),
        $document = $(document),
        tooltipPlugin = 'uitk_tooltip',
        googleMapsSessionToken;

    // Constructor
    var Typeahead = function (input, template, options) {
        this.$element = $(input);
        this.$autocomplete = this.$element.closest('.autocomplete');
        this.template = Handlebars.templates[template]; // Template comes from the Autocomplete's data-template attribute
        this.opts = $.extend(true, {}, Typeahead.defaultOptions, options);
        this.cache = {};
        this.selected = {};
        this.ignoreNext = false; // prevents keyup?
        this.preventSelect = false;
        this.isClickOn = false;
        this.bodyClick = false;
        this.dirty = true;
        this.lastValidValue = null;
        this.elementSelected = false;

        // Set up the input and listeners
        this.$element.autocomplete = 'off';

        // For gecko-based browsers, we turn autocomplete off on the <form>, not the <input>
        this.$element.closest('form').attr('autocomplete', 'off');

        // Bind user events
        this.$element
            .on('autocomplete.cleared', $.proxy(this.showDefaultResults, this))
            .on('focus', $.proxy(this.focus, this))
            .on('keydown', $.proxy(this.keyDown, this))
            .on('keyup', $.proxy(this.keyUp, this))
            .on('keypress', $.proxy(this.keyPress, this))
            .on('blur', $.proxy(this.blur, this));

        // Typeahead dialog and its listeners:
        this.$ta = $('<div class="typeahead">');

        if (this.opts.tooltip) {
            this.$element[tooltipPlugin]({
                content: this.$ta,
                template: '<div class="uitk-tooltip"><div class="tooltip-inner"></div><span class="tooltip-arrow-border"></span><span class="tooltip-arrow"></span></div>'
            });
        } else {
            this.$ta.appendTo(document.body);

            if (!uitk.isTouchDevice) {
                this.$ta
                    .on('mousedown', $.proxy(this.clickOn, this))
                    .on('mouseup', $.proxy(this.clickOff, this))
                    .on('click', $.proxy(this.click, this))
                    .on('mouseenter', 'a', $.proxy(this.mouseEnter, this));
            } else {
                this.$ta.on('click', (e) => e.preventDefault());
            }
        }

        // Init the CDDs (Company Defined Destinations)
        if (this.opts.cdd) {
            this.cdds = new EG.Models.CddCollection(null, {lob: this.opts.lob});
            if (!this.cdds.length) {
                this.cdds.fetch({
                    data: {
                        line_of_business: this.cdds.lob,
                        company_id: this.opts.companyId
                    },
                    processData: true
                });
            }
        }

        // For tags input
        if (this.opts.tags) {
            this.$tagsInputWrap = this.$autocomplete.find('.autocomplete-tags-wrap');

            $.each(this.$tagsInputWrap.find('.tag'), (idx, tag) => {
                var $tag = $(tag);

                $tag.on('click', '.autocomplete-tag-remove', $.proxy(this.removeTag, this));
                // Make sure that pre pop tags are selected
                this.selected[$tag.data('value')] = $tag.attr('id');
            });

            this.checkTagLimit();
        }

        // Attach a reference to this instance to the input
        this.$element.data('typeahead', this);

        // Making this optional in case some app is initializing somewhere
        // before user interaction.
        if (this.opts.giveFocusOnInit) {
            this.focus();
        }
    };

    // Prototype
    Typeahead.prototype = {
        constructor: Typeahead,
        shouldBeOpen: false,
        timeout: null,

        // TODO lots of work to do around sources...
        sources: {
            users: (query, callback, options) => Typeahead.prototype.userElasticSearchCallback(query, callback, options),
            flights: (query, callback, options) => Typeahead.prototype.essSourcesCallback(query, callback, options),
            hotels: (query, callback, options) => Typeahead.prototype.essSourcesCallback(query, callback, options),
            cars: (query, callback, options) => Typeahead.prototype.essSourcesCallback(query, callback, options),
            postalCode: function (query, callback, options) {
                // Overwriting the default options
                options.lob = Typeahead.CATEGORIES.HOTEL;
                options.features = 'postal_code';
                Typeahead.prototype.essSourcesCallback(query, callback, options);
            }
        },
        updateAutocompleteWithResult: function (autocompleteId, result) {
            var $this = $('#' + autocompleteId);
            if ($this.length > 0) {
                if (!$this.data('typeahead')) {
                    var _ = new Typeahead($this, $this.data('template'), $this.data());
                }

                $this.data('typeahead').updateTextField(result);
            }
        },
        essSourcesCallback: function (query, callback, options) {
            const ess = Typeahead.expediaSuggest(query, options);

            $.getJSON(ess.url, ess.data, results => {
                const responseCode = results.rc.toUpperCase();
                if (responseCode === 'GOOGLE_AUTOCOMPLETE') {
                    this.getGooglePlaces(query, results => {
                        callback(this.remapGooglePlacesResults(query, results), 'google');
                    });
                } else {
                    callback(this.remapEssResults(results), 'default');
                }
            });
        },
        userElasticSearchCallback: function (query, callback, options) {
            // user service elastic search
            var uses = Typeahead.userServiceElasticSearch(query, options);

            if (uses) {
                $.getJSON(uses.url, uses.data, function (response) {
                    callback(response);
                });
            }
        },

        // Helps testing to expose this logic
        remapEssResults: function (results) {
            return {
                query: results.q,
                search_results: results.sr ? results.sr.map(function (item) {
                    return {
                        id: item.gaiaId,
                        display_value: item.regionNames.displayName,
                        child: item.hierarchyInfo ? item.hierarchyInfo.isChild : false,
                        category: item.type,
                        value: (item.type === "ADDRESS") ? item.regionNames.fullName : item.regionNames.lastSearchName, // in the case of ADDRESS results the lastSearchName, was too short in all tested cases, we can use full, name in that case (EGE-349968).
                        result: JSON.stringify(item)
                    };
                }) : []
            };
        },

        remapGooglePlacesResults: function (query, placesResult) {
            if (!placesResult) {
                return {
                    query,
                    search_results: []
                };
            }
            return {
                query,
                search_results: placesResult.map(r => {
                    return {
                        id: r['place_id'],
                        category: 'GOOGLE',
                        display_value: this.formatGooglePlace(r.structured_formatting),
                        value: r.description
                    };
                })
            };
        },

        formatGooglePlace(structuredFormat) {
            const lastMatch = structuredFormat.main_text_matched_substrings[structuredFormat.main_text_matched_substrings.length - 1];
            const lastIndex = lastMatch.offset + lastMatch.length;
            const remainder = structuredFormat.main_text.substr(lastIndex);
            return structuredFormat.main_text_matched_substrings
                .map(range => structuredFormat.main_text.substr(range.offset, range.length))
                .map(txt => `<b>${txt}</b>`)
                .join(' ') + remainder + ' ' + structuredFormat.secondary_text;
        },

        dataSources: function (source, query, successCallback) {
            return this.sources[source](query, successCallback, this.opts);
        },

        startSearch: function () {
            var query = this.$element.val();

            if (this.opts.useDefaultResults && !query) {
                this.showDefaultResults();
            } else if (this.opts.useDefaultResults || query.length >= this.opts.minchar) {
                // Send search request
                const cacheResult = this.cache[query];

                if (!cacheResult) {
                  //debounce
                  clearTimeout(this.timeout);
                  this.timeout = setTimeout(() => { this.request(query); }, 200);

                  if (this.opts.cdd) {
                      this.cdds.results = this.cdds.getAutocompleteResults(query, {
                          max: this.opts.cdd ? parseInt(this.opts.cdd, 10) || 3 : 3
                      }); // max has to be populated with an number or we won't get any cdds (the default is 3)
                  }
                }
                // Use cache
                else {
                    this.render(this.preRender(query, cacheResult.results, cacheResult.provider));
                }
            } else {
                this.close();
            }
        },

        clearCache: function () {
            this.cache = {};
        },

        // ignoreResults is used to ping the Typeahead service on init. Apparently this makes things faster...
        request: function (text, ignoreResults) {
            var url,
                successCallback,
                data = {},
                query = text;

            if (ignoreResults) {
                successCallback = $.noop;
            } else {
                successCallback = $.proxy(this.callback, this);
            }

            // They explicitly set the Autocomplete service url (the preferred easy way)
            if (this.opts.url) {
                url = this.opts.url.replace('{q}', query); // e.g. http://egencia.com/my-service/autocomplete/{q} or like ?query={q}
                $.getJSON(url, data, successCallback)
                .fail(function(jqXHR, textStatus, errorThrown) {
                    uitk.logger.error('Typeahead failed with status: '+textStatus+'. error: '+errorThrown);
                });
            }
            // They set a source, i.e. uses built-in sources or custom source
            else {
                this.dataSources(this.opts.source, query, successCallback);
            }
        },

        // Removes selected items from results to prevent selecting the same result more than once
        removeSelectedItemsFromResults: function (results) {
            const copy = results ? results.slice(): [];
            if (Object.keys(this.selected).length > 0 && copy.length > 0) {
                // Reverse loop since we're potentially removing elements from the array, which changes indexes and causes a miss on the next iteration
                for (var i = copy.length - 1; i >= 0; i--) {
                    if (this.selected.hasOwnProperty(copy[i].value)) {
                        copy.splice(i, 1);
                    }
                }
            }

            return copy;
        },

        // Takes the JSON returned from the Autocomplete source and gets it ready to be rendered
        callback: function (data, provider) {
            var results = data.search_results ? data.search_results : data.results; // The array with matching results
            var q = data.query; // query is what the user had typed at the time of this search

            // Add CDDs to top of list for Hotel, bottom for all others
            if (this.opts.cdd && this.cdds.results.length > 0) {
                if (this.opts.source === 'cars' || this.opts.source === 'hotels' || this.opts.source === 'postalCode') {
                    Array.prototype.unshift.apply(results, this.cdds.results); // Prepends
                } else {
                    Array.prototype.push.apply(results, this.cdds.results); // Appends
                }
            }

            if (results && this.shouldBeOpen) {
                // Cache results for this search
                this.cache[q] = {
                    results,
                    provider
                };

                // Render
                this.render(this.preRender(q, results, provider));

                uitk.publish('autocomplete.displayed', {
                    $autocomplete: this.$element,
                    provider
                });
            } else {
                this.cache[q] = {};
                this.close();
            }
        },

        getGooglePlaces(query, callback) {
            if (!google) {
                console.error('Google maps library could not get loaded (either library was blocked or not added to the scripts list)');
            } else if (!google.maps.places) {
                console.error('You must load google maps with places library to use address autocomplete');
            } else if (googleMapsSessionToken == null) {
                //generate google map session token
                googleMapsSessionToken = new google.maps.places.AutocompleteSessionToken();
            }

            const autocompleteService = new google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions({
                input: query,
                sessionToken: googleMapsSessionToken
            }, (predictions, status) => {
                callback(predictions);
            });
        },

        preRender: function (text, results, provider) {
            var sanitizedResult,
                resultsByCategory = {};

            // Remove results that have already been selected (only applies to Tags option)
            // Doing it here is better because it works w/ any source (e.g. cached results, new results, default results, etc.) and it will filter w/o messing with the source of the data
            if (this.opts.tags) {
                results = this.removeSelectedItemsFromResults(results);
            }

            if (this.shouldRender(text)) {
                // If there are results, prepare and render them. Otherwise show 'no results' if set.
                if (results && results.length > 0) {

                    // Slice results if greater than max
                    if (results.length > this.opts.maxitems) {
                        results = results.slice(0, this.opts.maxitems);
                    }

                    // Loop over the results and
                    $.each(results, function (i, result) {
                        // 1. remove html tags from result.display_value
                        // 2. add <b> </b> for searched reference
                        sanitizedResult = uitk.utils.removeHtmlInString(result.display_value);
                        result.display_value = sanitizedResult.replace(new RegExp(uitk.utils.escapeSpecialRegexChars(text), 'gi'), function (match) {
                            return '<b>' + match + '</b>';
                        });

                        // 3. do stuff for icons and categories
                        var category = Typeahead.CATEGORIES[result.category];
                        var categoryName = category ? category.name : 'Other'; // TODO : does this case exist? can we move the string to config?

                        if (typeof resultsByCategory[categoryName] === 'undefined') {
                            resultsByCategory[categoryName] = [];
                        }

                        if (!result.result) {
                            result.result = JSON.stringify(result);
                        }

                        resultsByCategory[categoryName].push(result);

                        if (result.child) {
                            result.icon = 'angled-arrow-right';
                        } else if (category) {
                            result.icon = category.icon;
                        }

                        if (typeof result.value !== 'undefined') {
                            result.value = result.value.replace(/'/g, '&#39;'); // Replace apostrophes with the HTML char... why?
                        }
                    });

                    return this.template({
                        provider: provider,
                        autocompleteId: this.opts.autocompleteId,
                        results: results,
                        resultsByCategory: resultsByCategory
                    });

                } else if (this.opts.noResultsTitle || this.opts.noResultsBody) {

                    return Handlebars.templates['partials/uitk/autocomplete-no-results']({
                        title: this.opts.noResultsTitle,
                        body: this.opts.noResultsBody
                    });
                }
            }
            return undefined;
        },

        // Displays the results
        render: function (template) {
            if (template) {
                this.$ta.html(template);
                this._showTA();
            }
        },

        _showTA: function () {
            if (this.$ta.is(':hidden')) {
                this.$ta.show();
            }

            if (this.opts.tooltip) {
                this.$element[tooltipPlugin]('show');
                this.$element[tooltipPlugin]('checkPos', true, true);
                this.$ta
                    .off()
                    .on('click', $.proxy(this.click, this))
                    .on('mousedown', $.proxy(this.clickOn, this))
                    .on('mouseup', $.proxy(this.clickOff, this))
                    .on('mouseenter', 'a', $.proxy(this.mouseEnter, this));
            } else {
                var height = parseInt(this.$element.css('height'), 10);
                var offset = this.$element.offset();
                this.$ta.css({
                    top: offset.top + height + 'px',
                    left: offset.left + 'px'
                });
                this.$ta.show();
            }
        },

        shouldRender: function (query) {
            var val = this.$element.val();
            // we may get query in encoded form so we are decoding it before comparing with value.
            if (query) {
                try {
                    query = decodeURIComponent(query);
                } catch (e) {
                    // decodeURIComponent() may throw URIError, try unescape() first,
                    // before giving up completely
                    try {
                        query = unescape(query);
                    } catch (e) {
                        // fall back to using the unprocessed query if nothing works
                        query = query;
                    }
                }
            }
            // Using defaults AND nothing has been typed yet (or they backspaced all the way) OR the input still matches the query (i.e. these results are for the current input) then ok to render
            if (this.opts.useDefaultResults && (query === '' || query === val)) {
                return true;
            }
            // Input still matches the query (i.e. these results are for the current input), ok to render
            return query === val;
        },

        close: function () {
            this.removeHighlights();

            if (this.opts.tooltip) {
                //the hide method will destroy events in jq but not in zepto, this leads to numerous bugs because we don't actually destroy the reference, just remove it from the DOM to hide
                //To deal with this consistently we always remove events, and always add them on _showTA
                this.$ta.off();
                this.$element[tooltipPlugin]('hide');
            } else {
                this.$ta.hide();
                this.$ta.html('');
            }

            this.shouldBeOpen = false;

            // If empty or whitespaces only, remove whitespaces and hide clear button
            if (this.$element.val().trim() === '') {
                this.$element.val('');
            }

            if (this.bodyClick) {
                this.$ta.unbind('mousedown', $.proxy(this.cancelEvent, this));
                $(document.body).unbind('mousedown', $.proxy(this.close, this));
                this.bodyClick = false;
            }
        },

        prev: function () {
            var links = this.$ta.find('a'),
                current = this.$ta.find('.highlight').removeClass('highlight'),
                i = links.index(current),
                prev = links[i - 1];

            if (prev) {
                $(prev).addClass('highlight');
            }
        },

        next: function () {
            var links = this.$ta.find('a'),
                current = this.$ta.find('.highlight').removeClass('highlight'),
                i = links.index(current),
                next = links[i + 1];

            if (next) {
                $(next).addClass('highlight');
            }
        },

        removeHighlights: function () {
            this.$ta.find('.highlight').removeClass('highlight');
        },

        findHighlightedItem: function (e) {
            var $item = this.$ta.find('.highlight');

            // Used for touch devices as they don't have hover and therefore don't highlight
            if (e && $item.length === 0) {
                $item = $(e.target).closest('.results-item').find('a');
            }
            return $item;
        },

        // Choose the currently selected item:
        selectHighlighted: function (e) {
            var $item = this.findHighlightedItem(e);

            // Clicking the Tooltip padded area can lead to an undefined $item so we check length. TODO could be dealt with earlier...
            if ($item.length) {
                if (this.opts.tags) {
                    this.addTag($item);
                } else {
                    this.updateTextField({
                        id: $item.data('id'),
                        value: $item.data('value')
                    });
                }

                this.close();
                if (!this.opts.blurOnSelect) this.$element.focus();
                uitk.publish('autocomplete.selected', {
                    selectedId: $item.data('id'),
                    result: $item.data('result'),
                    $item: $item,
                    $autocomplete: this.$element
                });
                $item.removeClass('highlight');
                this.dirty = false;
            } else {
                this.dirty = true;
            }
        },

        updateTextField: function (result) {
            result = result || {};
            var value = (result.value != null && result.value != undefined) ? uitk.utils.removeHtmlInString(result.value.toString().replace(/&#39;/g, '\'')) : '';
            this.$element.val(value).trigger('input');
            this.$element.data('prev-selected-item-id', this.$element.data('selected-item-id'));
            this.$element.data('selected-item-id', result.id);
            this.dirty = false;
            // this.$element.data('result', result); the result should be unmapped (raw) search result here, but it is not available
        },

        // Adds a Tag to the Autocomplete's Tag Group
        addTag: function ($item) {
            var id = $item.data('id');
            var type = $item.data('type');
            var value = uitk.utils.removeHtmlInString($item.data('value'));
            var tag = Handlebars.templates['partials/uitk/tag']({
                id: id,
                type: type,
                value: value,
                label: value,
                autocomplete: true
            }); //TODO how to get a type and label?
            var $tag = $(tag);
            $tag.on('click', '.autocomplete-tag-remove', $.proxy(this.removeTag, this));

            // Add Tag and reset input
            this.selected[value] = id; // Using value as the key because the same real location can have multiple ids (they come from different categories) TODO does value need to be escaped? key names can be elaborate strings, but what are the limits if any?
            this.$tagsInputWrap.find('input.autocomplete-input').before($tag);
            this.$element.val('').attr('placeholder', '').select().trigger('input');

            // Determine if Tag limit has been reached and prevent future searching
            this.checkTagLimit();
        },

        // Removes a Tag from the Autocomplete's Tag Group and selected hash
        removeTag: function (event) {
            var $tag = event && event.target ? $(event.target).closest('.tag') : this.$tagsInputWrap.find('.tag').last();

            //Prevent bubbling
            event?.stopPropagation();

            // Remove from selected hash
            this.selected[$tag.data('value')] = null;
            delete this.selected[$tag.data('value')];

            // Remove the Tag
            $tag['uitk_tag']('remove', true);

            uitk.publish('autocomplete.tag.remove', {
                tagId: $tag.attr('id'),
                $tagsInputWrap: this.$tagsInputWrap,
                tagType: $tag.data('type'),
                tagValue: $tag.data('value'),
                $autocomplete: this.$element
            });

            // Allow searching in case the limit was previously reached
            this.checkTagLimit();

            // Put back placeholder when tag group is empty
            // including pre-populate tags
            if (this.$tagsInputWrap.find('.tag').length <= 0) {
                this.$element.attr('placeholder', this.opts.placeholder);
            }

            // Keep focus on input
            this.$element[0].focus();
        },

        // Clear all tags from the Autocomplete
        clearTags: function () {
            this.selected = {};

            // Remove all the tags
            this.$tagsInputWrap.find('.tag').each(function(key, tag) {
                $(tag)['uitk_tag']('remove', true);
            });

            this.$element.attr('placeholder', this.opts.placeholder);
        },

        focus: function () {
            if (this.opts.tags && !this.$tagsInputWrap.hasClass('focus')) {
                this.$tagsInputWrap.addClass('focus');
            }

            if (this.opts.validate) {
                this.lastValidValue = this.$element.val();
            }

            if (!this.opts.hideDropdownOnSelect) {
                if (!this.opts.dropdownOnFocus) { //default behavior
                    this.showDefaultResults();
                }
                else {
                    this.showLastResults()
                }
            } else { //behavior to hide dropdown when an element is selected
                if (!this.elementSelected) {
                    this.showDefaultResults();
                } else {
                    this.elementSelected = false;
                }
            }
        },

        showDefaultResults: function () {
            if (this.opts.useDefaultResults && !this.preventSelect && this.$element.val() === '') {
                this.opts.shouldBeOpen = true;
                // There is no query, so just show the defaults (Note the empty string passed as the query arg)
                this.dataSources(this.opts.source, '', $.proxy(this.defaultResultsCallback, this));
            }
        },

        showLastResults: function () {
            if (this.lastValidValue !== undefined && this.lastValidValue !== null) {
                this.dataSources(this.opts.source, this.lastValidValue, $.proxy(this.defaultResultsCallback, this));
            }
            else {
                this.showDefaultResults();
            }
        },

        // Clears the input and publishes a cleared event
        clearInput: function () {
            var $el = this.$element;
            var val = $el.val();
            var lastId = $el.data('selected-item-id');

            $el.val('').data('selected-item-id', '').data('result', '').trigger('input');
            uitk.publish('autocomplete.cleared', {
                lastVal: val,
                lastId: lastId,
                $autocomplete: $el
            });
        },

        defaultResultsCallback: function (data) {
            this.render(this.preRender(data.query, data.search_results, ''));
        },

        keyDown: function (e) {
            var keyCode;

            if (!e) {
                e = window.event;
            }

            keyCode = e.keyCode;

            switch (keyCode) {
                case 27: // Escape
                    this.close();
                    e.stopPropagation();
                    break;

                case 13: // Enter
                    if (this.$ta.find('.highlight').length) {
                        e.stopPropagation();
                        if (keyCode === 13) {
                            e.preventDefault();
                        }
                        this.selectHighlighted();
                    }
                    this.blur(e); //forms submit before blur, this allows us to validate on key submit
                    break;

                case 8: // Backspace
                    if (this.$element.val().length === 1 && !this.opts.dropdownOnFocus) {
                        this.close();
                    }

                    if (this.opts.tags && this.$element.val() === '') {
                        this.removeTag();
                    }
                    this.dirty = true;
                    break;

                case 9: // Tab
                case 39: // Right
                    if (this.$ta.find('.highlight').length) {
                        this.ignoreNext = true;
                        e.preventDefault(); // Prevents tabbing to the next input, we want the Autocomplete to stay focused after selection
                        e.stopPropagation();
                        this.selectHighlighted();
                    }
                    break;
                default:
                    // Prevent typing (e.g. max Tags have already been selected)
                    if (this.preventSelect) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        this.dirty = true;
                    }
                    break;
            }
        },

        keyPress: function (e) {
            if (!e) {
                e = window.event;
            }
            if (e.keyCode === 13 /* Enter/Return */ ) {
                this.close();
            }
        },

        keyUp: function (e) {
            // Prevents a key up event after an item has been selected by the key board
            if (this.ignoreNext) {
                this.ignoreNext = false;
                return;
            }
            if (!e) {
                e = window.event;
            }
            switch (e.keyCode) {
                case 27: // Escape
                    break;

                case 37: // Left
                    this.removeHighlights();
                    break;

                case 38: // Up
                    this.prev();
                    break;

                case 40: // Down
                    this.next();
                    break;

                case 16: // Shift
                case 17: // Ctrl
                case 18: // Alt
                    break;
                case 13: // Enter/Return
                    this.shouldBeOpen = false;
                    break;

                case 8: //Backspace
                    if (this.$element.val().length === 0 && this.dirty) {
                        uitk.publish('autocomplete.cleared', {
                            $autocomplete: this.$element
                        });
                        if (!this.opts.dropdownOnFocus) break;
                    }
                    // Backspace should also trigger an update to dropdown
                default:
                    this.shouldBeOpen = true;
                    this.startSearch();
                    this.dirty = true;
                    break;
            }
        },


        validateInput: function (e) {
            var theInputIsValid = true;
            if (!this.opts.tags && this.opts.validate && this.dirty) {
                theInputIsValid = false;
                var inputValue = this.$element.val();
                var inputValueLowerCase = inputValue ? inputValue.toLowerCase() : '';
                var lastValidValueLowerCase = this.lastValidValue ? this.lastValidValue.toLowerCase() : '';
                if (inputValueLowerCase !== lastValidValueLowerCase) {
                    // if value has changed, there should have been queries issued
                    var currentValidResult;
                    if (inputValue) {

                        if (this.cache[inputValue] && this.cache[inputValue].results &&
                            this.cache[inputValue].results.length > 0) {
                            currentValidResult = _.find(this.cache[inputValue].results, (cacheValue) => inputValueLowerCase === cacheValue.value.toString().toLowerCase());
                        }

                        if (currentValidResult) {
                            theInputIsValid = true;
                            this.updateTextField(currentValidResult);
                            var $item = this.findHighlightedItem(e);
                            uitk.publish('autocomplete.selected', {
                                selectedId: currentValidResult.id,
                                result: currentValidResult,
                                $item: $item && $item.length ? $item : null,
                                $autocomplete: this.$element
                            });
                        }
                    }
                } else {
                    // value has not changed, still vaild
                    theInputIsValid = true;
                }
            }
            return theInputIsValid;
        },

        blur: function (e) {
            // Need a delay so we can cancel if the Autocomplete is being clicked on
            if (!this.isClickOn) {
                if (!this.validateInput(e)) {
                    this.clearInput();
                }
                this.close();
            } else {
                this.$ta.bind('mousedown', $.proxy(this.cancelEvent, this));
                $(document.body).bind('mousedown', $.proxy(this.close, this));
                this.bodyClick = true;
            }

            // For autocomplete with tags
            if (this.opts.tags) {
                this.$tagsInputWrap.removeClass('focus');
            }
        },

        clickOn: function () {
            this.isClickOn = true;
            this.elementSelected = true;
        },

        clickOff: function () {
            this.isClickOn = false;
        },

        click: function (e) {
            //this is dumb and assumes A LOT
            e.stopPropagation();
            e.preventDefault();
            this.removeHighlights();
            $(e.target).closest('a').addClass('highlight');
            this.selectHighlighted(e);
        },

        mouseEnter: function (e) {
            this.removeHighlights();
            $(e.currentTarget).addClass('highlight');
        },

        cancelEvent: function (e) {
            e.cancelBubble = true;
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            if (e.preventDefault) {
                e.preventDefault();
            }
            e.cancel = true;
            e.returnValue = false;
        },

        remove: function () {
            this.$element.autocomplete = 'on';
            this.$element.closest('form').attr('autocomplete', 'on');

            this.$element
                .off('focus', $.proxy(this.showDefaultResults, this))
                .off('keydown', $.proxy(this.keyDown, this))
                .off('keyup', $.proxy(this.keyUp, this))
                .off('keypress', $.proxy(this.keyPress, this))
                .off('blur', $.proxy(this.blur, this));
            this.$ta
                .off('mousedown', $.proxy(this.mouseDown, this))
                .off('mouseup', $.proxy(this.mouseUp, this))
                .off('click', $.proxy(this.click, this))
                .off('mouseenter', 'a', $.proxy(this.mouseEnter, this))
                .remove();
        },

        checkTagLimit: function () {
            if (this.opts.taglimit) {
                if (this.$tagsInputWrap.find('.tag').length >= this.opts.taglimit) {
                    uitk.publish('autocomplete.taglimit', {
                        $autocomplete: this.$element
                    });
                    this.preventSelect = true;
                } else {
                    this.preventSelect = false;
                }
            }
        }
    };

    Typeahead.expediaSuggest = function (query, options) {
        var params = {};
        params.url = 'https://www.expedia.com/api/v4/typeahead/' + query + '?callback=?';
        params.data = {
            client: options.clientId,
            lob: Typeahead.LOB[options.source.toUpperCase()] || options.lob, // source is also used to specify lob
            regiontype: options.regiontype,
            locale: options.locale.replace('-', '_'),
            format: 'jsonp',
            ab: options.abtest,
            siteid: options.siteId,
            destination: options.dest,
            maxresults: options.maxitems,
            features: options.features.replace(/\-/g, '_') || 'ta_hierarchy',
            sortcriteria: Typeahead.SORTCRITERIA[options.sortcriteria.toUpperCase()] || Typeahead.SORTCRITERIA.REGIONTYPE
        };

        $.extend(params.data, options.essQueryParams);
        return params;
    };

    Typeahead.userServiceElasticSearch = function (query, options) {
        var params = {};
        // user service requirements:
        //  query length > 2
        //  the user id is defined
        if (query && query.length > 2 && options.userId) {
            params.url = options.userId ? '/user-service/v2/users/' + options.userId + '/search' : '';
            params.data = {
                query: decodeURIComponent(query),
                context: 'user-arrangees',
                company_id: options.companyId,
                include: 'SELF',
                count: options.maxitems,
                return_guest_account_id: true
            };
            _.each(_.keys(params.data), function (key) {
                if (typeof params.data[key] === 'undefined') {
                    delete params.data[key]; // we don't want to send ...&param=&... in the params
                }
            });
        } else {
            return null;
        }

        return params;
    };

    // Constants:
    Typeahead.REGIONTYPE = {
        AIRPORT: 'AIRPORT', //1
        CITY: 'CITY', //2
        MULTICITY: 'MULTICITY', //4
        NEIGHBORHOOD: 'NEIGHBORHOOD', //8
        POI: 'POI', //16
        ADDRESS: 'ADDRESS', //32
        METROCODE: 'METROCODE', //64
        HOTEL: 'HOTEL' //128
    };

    Typeahead.LOB = {
        'HOTELS': 'HOTELS',
        'PACKAGES': 'PACKAGES',
        'FLIGHTS': 'FLIGHTS',
        'CARS': 'CARS'
    };

    Typeahead.CATEGORIES = {
        //If you are changing name or icon for CITY make the same change to MULTICITY and NEIGHBORHOOD
        'CITY': {
            'id': 0,
            name: uitk.i18n.msg('uitk_autocomplete_region') || 'Region/City',
            icon: 'locationalt'
        },
        //If you are changing name or icon for ATTRACTION make the same change to POI
        'ATTRACTION': {
            'id': 1,
            name: uitk.i18n.msg('uitk_autocomplete_attractions') || 'Attractions',
            icon: 'locationalt'
        },
        'AIRPORT': {
            'id': 2,
            name: uitk.i18n.msg('uitk_autocomplete_airports') || 'Airports',
            icon: 'flightsalt'
        },
        'HOTEL': {
            'id': 3,
            name: uitk.i18n.msg('uitk_autocomplete_hotels') || 'Hotels',
            icon: 'hotelsalt'
        },
        'ADDRESS': {
            'id': 4,
            name: uitk.i18n.msg('uitk_autocomplete_address') || 'Address',
            icon: 'locationalt'
        },
        'ARRANGEE_LIST': {
            'id': 5,
            name: uitk.i18n.msg('uitk_autocomplete_arrangee_list') || 'Arrangee list',
            icon: 'traveleralt'
        },
        'OTHER_TRAVELER': {
            'id': 6,
            name: uitk.i18n.msg('uitk_autocomplete_other_travelers') || 'Other travelers',
            icon: 'traveler'
        },
        'CUSTOM_DEST': {
            'id': 8,
            name: uitk.i18n.msg('uitk_autocomplete_custom_destination') || 'Company location',
            icon: 'destination'
        },
        'TRAINSTATION': {
            'id': 9,
            name: uitk.i18n.msg('uitk_autocomplete_train_station') || 'Train station',
            icon: 'trainalt'
        },
        'METROSTATION': {
            'id': 10,
            name: uitk.i18n.msg('uitk_autocomplete_metro_station') || 'Metro station',
            icon: 'trainalt'
        },
        //The icon and name should be in sync with 'Attraction'. ESS V4 returns attractions as POI
        'POI': {
            'id': 11,
            name: uitk.i18n.msg('uitk_autocomplete_attractions') || 'Attractions',
            icon: 'locationalt'
        },
        //The icon and name should be in sync with 'City'. ESS V4 returns some cities as multicity
        'MULTICITY': {
            'id': 12,
            name: uitk.i18n.msg('uitk_autocomplete_region') || 'Region/City',
            icon: 'locationalt'
        },
        //The icon and name should be in sync with 'City'. ESS V4 returns some cities as neighborhood
        'NEIGHBORHOOD': {
            'id': 13,
            name: uitk.i18n.msg('uitk_autocomplete_region') || 'Region/City',
            icon: 'locationalt'
        }
    };

    Typeahead.SORTCRITERIA = {
        'REGIONTYPE': 'regiontype', // Default
        'CATEGORY': 'category',
        'POPULARITY': 'popularity'
    };

    // This should probably be called 'ESSOptions'
    Typeahead.defaultOptions = {
        // 'Options' - to be moved into this.opts, which is a merge from Typeahead.default
        // The options are overwritten with HTML data attributes and need to be all lower case
        clientId: 'Egencia.Uitk.Autocomplete',
        siteId: '',
        minchar: 3,
        maxitems: 5,
        locale: 'en_US', // ESS requires the non-standard Java-style format DO NOT CHANGE
        abtest: '',
        mask: Typeahead.REGIONTYPE.AIRPORT + '|' + Typeahead.REGIONTYPE.CITY + '|' + Typeahead.REGIONTYPE.MULTICITY + '|' + Typeahead.REGIONTYPE.NEIGHBORHOOD + '|' + Typeahead.REGIONTYPE.POI,
        regiontype: '',
        lob: Typeahead.LOB.FLIGHTS,
        cdd: false,
        dest: false,
        tooltip: true,
        forceicon: false,
        validate: false,
        features: '',
        sortcriteria: '',
        essQueryParams: {},
        giveFocusOnInit: false
    };

    function init() {
        // this selector was a bit too inclusive and adding the 'not' pseudo selector
        // seemed to be the simplest way to not clash with the lss version of autocomplete
        var $autofocus = $('[data-control="typeahead"][data-autofocus]');
        var $autoCompleteTagsInputWrap = $('.autocomplete-tags-wrap:not(.autocomplete-lss *)');

        // For removing placeholder and pre-populated tags.
        if ($autoCompleteTagsInputWrap.length > 0) {
            $.each($autoCompleteTagsInputWrap, function (index, tagsInputWrap) {
                var $tagsInputWrap = $(tagsInputWrap);

                $tagsInputWrap.on('click', function () {
                    $(this).find('input.autocomplete-input').select();
                });

                if ($tagsInputWrap.find('.tag').length > 0) {
                    var $autoCompleteTagInput = $tagsInputWrap.find('input.autocomplete-input');

                    // Clear the placeholder
                    $autoCompleteTagInput.attr('placeholder', '');

                    // Init first if it needs to pre-populate the tag
                    if ($autoCompleteTagInput.data('typeahead')) {
                        return; // Has already been instantiated, so just reuse
                    }

                    var _ = new Typeahead(
                        $autoCompleteTagInput,
                        $autoCompleteTagInput.data('template'),
                        $autoCompleteTagInput.data()
                    );
                }
            });
        }

        if ($autofocus.length > 0) {
            $autofocus[0].focus();
        }
    }

    // Expose Autocomplete to uitk, used for testing and exposing sources
    uitk.modules.Autocomplete = Typeahead;
    uitk.autocomplete = {
        init: init,
        sources: Typeahead.prototype.sources,
        callback: Typeahead.prototype.callback,
        updateAutocompleteWithResult: Typeahead.prototype.updateAutocompleteWithResult
    };

    // Listen for focuses on typeahead elements
    $body.on('focus.typeahead', '[data-control="typeahead"]', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.data('typeahead')) {
            return; // Has already been instantiated, so just reuse
        }
        var _ = new Typeahead($this, $this.data('template'), $this.data());
    });

    // Auto-select text for easy delete
    $body.on('click', '[data-control="typeahead"]', function (e) {
        $(this).trigger('select');
    });

    // Stop propagation on icon click for autocomplete-tags
    $body.on('click', '.autocomplete-tags-wrap .icon', (e) => e.stopPropagation());

    // For autofocus feature and pre-populate tag placeholder
    $document.ready(init);

    function clearGoogleMapSessionToken (topic, data) {
        if(data.result.category === "GOOGLE") {
            googleMapsSessionToken = null;
        }
    }

    //empty and re-generate google map session token on google map autocomplete result selection
    uitk.subscribe('autocomplete.selected', clearGoogleMapSessionToken);

})(window.jQuery);