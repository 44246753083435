/*JSHint options*/
/*global define */

/*
 *  Project: UITK Content Slider Plugin
 *  Author: dstrugnell@expedia.com
 */

/*
 *  OPTIONS (passed via data-* attributes in the html)
 *
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;(function ( $, window, undefined ) {

    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_contentSlider',
        $body = $('body'),
        $window = $(window),
        dataController = '[data-control="content-slider"]',
        controlButtons = '.prev, .next',
        defaults = {
            infinite: false,
            scrollTime: '0.5s'
        },
        clickEvent = 'click',
        isTouchDevice = uitk.isTouchDevice,
        hasMediaqueries = Modernizr.mediaqueries;


    /* CONSTRUCTOR */
    function ContentSliderResp( element, jsOptions ) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    ContentSliderResp.prototype = {

        constructor: ContentSliderResp,

        init: function(){

            var that = this,
                $elem = that.element,
                elemDimensions = $elem[0].getBoundingClientRect();

            // cancel the init if the element is not visible

            if ($elem.css('display')==='none' || !(elemDimensions.height || elemDimensions.width)){
                return;
            }

            that.contentMask = $elem.find('.content-slider-mask');
            that.tileGrid = $(that.contentMask).children('.row');
            that.numVisibleTiles = that.getNumVisibleTiles();
            that.uniqueId = that.options.modalId || uitk.createUniqueId();

            $elem.attr('data-unique-id', that.uniqueId);

            // Set current page
            that.moveToPage(1, true);

            // if media queries are supported
            if (hasMediaqueries){

                // listen for window resize
                $window.on('resize.event'+that.uniqueId, function(){
                    that.handleResize();
                });

                $window.on('orientationchange.event'+that.uniqueId, function(){
                    that.handleResize();
                });
            }

            // Treating touch the same way as click
            // set up swipe (hasTouch doesn't include Windows 8 Touch hence the other parameter)
            if (Modernizr.touch || window.navigator.msPointerEnabled) {
                that.swipeCheck = true;

                var gestureHandling = new Hammer(that.contentMask[0]);

                gestureHandling.on('swipe pan', function(e){
                    var $slider = null,
                        distance = (-1) * e.deltaX,
                        direction = distance <  0 ? 'right': 'left',
                        touchType = e.srcEvent.type,
                        move = touchType === 'pointermove' || touchType === 'touchmove';

                    if (!$slider) {
                        $slider = $(e.target).closest('[data-control="content-slider"]');
                    }

                    if (e.type === 'swipe') {
                        that.swipeCheck = false;
                        $slider[pluginName]('slideEnd', direction, distance);
                    }

                    if (e.deltaTime > 150 && e.type === 'pan' && move) {
                        $slider[pluginName]('slideMove', distance);
                        return;
                    }

                    if (e.type === 'pan' && !move) {
                        if(!that.swipeCheck) {
                            that.swipeCheck = true;
                        } else {
                            $slider[pluginName]('slideEnd', direction, distance);
                        }
                    }
                });
            }

            // subscribe to debounced.resize if device is non-touch. This is to take in to account
            // of px being used for the positioning (isTouchDevice includes Windows 8 Touch)
            if(!isTouchDevice) {
                that.deboucedResize = uitk.subscribe('debounced.resize', function(){
                    that.positionPage(that.currentPage);
                });
            }
        },


        //METHODS
        updateControls: function(){
            var that = this,
                numOfPages = that.getNumOfPages(),
                currentPage = that.currentPage;

            if (numOfPages <= 1) {
                that.element.find(controlButtons).addClass('invisible');
            } else {
                if (currentPage === 1 && !that.options.infinite) {
                    that.element.find('.prev').addClass('invisible');
                    that.element.find('.next').removeClass('invisible');
                } else if (currentPage === numOfPages && !that.options.infinite) {
                    that.element.find('.prev').removeClass('invisible');
                    that.element.find('.next').addClass('invisible');
                } else {
                    that.element.find(controlButtons).removeClass('invisible');
                }
            }
        },
      
        updateCurrentClasses: function(){
            var that = this,
                currentTileClass = 'current',
                currentPage = that.currentPage,
                numVisibleTiles = that.getNumVisibleTiles(),
                tiles = that.tileGrid.find('.col'),
                activeTiles;

            // remove all currentTile classes and set all tiles to aria-hidden=true
            tiles.removeClass(currentTileClass).attr('aria-hidden','true');
            tiles.find('a').attr('tabindex','-1');

            // get a handle on the new set of current tiles and set the currentTile class and aria-hidden=false
            activeTiles = tiles.slice(numVisibleTiles*(currentPage-1),numVisibleTiles*currentPage);
            activeTiles.addClass(currentTileClass).attr('aria-hidden','false');
            activeTiles.find('a').removeAttr('tabindex');

            //publishes page change
            //uitk.publish('contentSlider.pageChange', that.element, currentPage, viewChange);
        },

        unregister: function(){
            var that = this;

            $(window).off('.event'+that.uniqueId);

            // if debouced.resize has been subscribed to then unsubscribe
            if (that.deboucedResize){
                uitk.unsubscribe('debounced.resize', that.deboucedResize);
            }
        },

      getPagePosition: function (page) {
        var pagePosition,
          domTileWidth;

        domTileWidth = this.tileGrid.find('.col')[0].getBoundingClientRect().width;
        pagePosition = (((page - 1) * this.getNumVisibleTiles()) * -domTileWidth) + 'px';

        return pagePosition;
      },

        positionPage: function(page, stopAnimation){
            var that = this,
                pagePosition = that.getPagePosition(page),
                scrollTime = stopAnimation ? '0s' : that.options.scrollTime;

            that.tileGrid.css({
                '-webkit-transition-duration':scrollTime,
                'transition-duration':scrollTime
            });

            that.tileGrid.css({left:pagePosition});
        },


        // MOVE TO PAGES
        moveToPage: function(page, stopAnimation){
            var that = this;
            // if page is valid move to page
            if (page > 0 && page <= that.getNumOfPages()) {
                that.positionPage(page, stopAnimation);
                that.setCurrentPage(page);
                that.updateControls();
                that.updateCurrentClasses();
            }
        },

        next: function(){
            var that = this,
                currentPage = that.currentPage,
                nextPage = currentPage + 1;

            if (nextPage > that.getNumOfPages()) {
                if (that.options.infinite === true) {
                    that.moveToPage(1);
                }else{
                    that.moveToPage(currentPage);
                }
            } else {
                that.moveToPage(nextPage);
            }
            that.setPageFocus();
        },

        prev: function(){
            var that = this,
                currentPage = that.currentPage,
                prevPage = currentPage - 1;

            if (prevPage < 1) {
                if (that.options.infinite === true) {
                    that.moveToPage(that.getNumOfPages());
                }else{
                    that.moveToPage(currentPage);
                }
            } else {
                that.moveToPage(prevPage);
            }
            that.setPageFocus();
        },

        // SETTERS
        setPageFocus: function() {
            var that = this,
                firstTile = that.element.find('.current:first');

            that.element.one(uitk.topics.transitionEnd, function(e){
                e.stopPropagation();
                if($(e.target).closest('[data-control=content-slider]')[0] === that.element[0]) {
                    firstTile.attr('tabindex','-1').focus().css('outline','none');
                }
            });
        },

        setCurrentPage: function(page){
            var that = this;

            that.currentPage = page;

            // adds the page number to the DOM to make it easier to automate testing
            that.element.attr('data-current-page', page);
        },


        // GETTERS
      getNumVisibleTiles: function () {
        var mask = this.contentMask,
          tiles = mask.find('.col'),
          maskWidth = mask[0].getBoundingClientRect().width,
          tileWidth = tiles[0].getBoundingClientRect().width,
          numOfVisibleTiles = Math.floor(maskWidth / tileWidth);
        return numOfVisibleTiles;
      },

        getNumOfPages: function(){

            var that = this,
                tileGrid = that.tileGrid,
                tiles = tileGrid.children('.col'),
                numOfTiles = tiles.length,

                numOfPages = Math.ceil(numOfTiles/that.numVisibleTiles);

            return numOfPages;
        },

        getNewCurrentPage: function(numVisibleTiles, oldNumVisibleTiles){
            var that = this,
                newCurrentPage,
                currentPage = that.currentPage,
                numOfPages = that.getNumOfPages(),

            // Work out first visible tile
                firstVisibleTile = oldNumVisibleTiles*(currentPage-1)+ 1,
                lastVisibleTile = oldNumVisibleTiles*currentPage;

            // Work out what the new current page should be
            // If old number of tiles < new number of tile use last tile in current page
            // Else if old number of tiles > new number of tiles use first tile in current page
            if (oldNumVisibleTiles < numVisibleTiles){
                newCurrentPage = Math.ceil(lastVisibleTile/numVisibleTiles);
            }else if(oldNumVisibleTiles > numVisibleTiles){
                newCurrentPage = Math.ceil(firstVisibleTile/numVisibleTiles);
            }else{
                newCurrentPage = currentPage;
            }

            // make sure new current page isn't bigger than numOfPages
            newCurrentPage = newCurrentPage > numOfPages ? numOfPages : newCurrentPage;

            return newCurrentPage;
        },


        //HANDLERS
        handleViewChange: function(numVisibleTiles){
            var that = this;
            //check to see if content-slider is still valid / still in the DOM
            if (that.uniqueId !== that.element.attr('data-unique-id') || $('[data-unique-id="'+that.uniqueId+'"]').length === 0){
                that.unregister();
                return;
            }

            var currentPage,
                oldNumVisibleTiles = that.numVisibleTiles,
                newCurrentPage;


            // add class so we know the view is being changed
            that.tileGrid.addClass('view-change');

            that.numVisibleTiles = numVisibleTiles;

            currentPage = that.currentPage;

            newCurrentPage = that.getNewCurrentPage(numVisibleTiles, oldNumVisibleTiles);

            // to handle the display of prev or/and next button when changing orientation or resizing the page
            that.updateControls();

            if (currentPage !== newCurrentPage){
                setTimeout(function(){
                    that.moveToPage(newCurrentPage, true);
                },10);
            }

            // remove class once view has changed
            setTimeout(function(){
                that.tileGrid.removeClass('view-change');
            }, 50);
        },

        handleResize: function(){

            var that = this,
                numVisibleTiles = that.getNumVisibleTiles();

            // check for change in number of visible tiles
            if (that.numVisibleTiles !== numVisibleTiles){
                that.handleViewChange(numVisibleTiles);
            }
        },


        // Touch Sliding
        slideMove: function(distance) {
            var that = this,
                pagePos = parseInt(that.getPagePosition(that.currentPage), 10),
                leftPos;

            leftPos = pagePos + (distance * -1);
            that.scrollPages(leftPos);
        },

        slideEnd: function(direction, distance){

            var that = this,
                relationalDistance = Math.abs(distance/that.contentMask.width());

            if (relationalDistance > 0.08){
                if (direction === 'left') {
                    that.next();
                } else {
                    that.prev();
                }
            }else{
                that.moveToPage(that.currentPage);
            }
        },

        scrollPages: function(leftPos) {
            var that = this;

            that.tileGrid.css({
                '-webkit-transition-duration':'0.1s',
                'transition-duration':'0.1s',
                'left': leftPos+'px'
            });
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, ContentSliderResp);

    // Init content sliders on page load
    $('[data-control=content-slider]')[pluginName]();

    /* EVENT LISTENERS (delegated to body) */

    $body.on(clickEvent + '.' + pluginName, dataController + ' ' + controlButtons, function(e){
        var $target = $(e.target),
            $control = $target.closest(controlButtons),
            $slider = $target.closest(dataController);

        e.preventDefault();

        if($control.hasClass('next')){
            $slider[pluginName]('next');
        }else if($control.hasClass('prev')){
            $slider[pluginName]('prev');
        }
    });

    if (isTouchDevice) {
        //prevent click on touch devices
        $body.on('click.'+pluginName, dataController + ' ' + controlButtons, function(e){
            e.preventDefault();
        });
    }

    uitk.modules.ContentSlider = ContentSliderResp;

}(jQuery, window));