; // Safety net against concatenated scripts
(function ($, window, undefined) {
    'use strict';
    var pluginName = 'uitk_timepicker';
    var $delegate = $(document);
    var defaults = {
        increment: 60,
        firstValidTime: 0, // 00:00
        lastValidTime: 1440 // 24:00
    };

    /* CONSTRUCTOR */
    function TimePicker(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    /* PROTOTYPE */
    TimePicker.prototype = {
        constructor: TimePicker,

        selectTime: function($target) {
            var val = $target.val();
            uitk.publish('timepicker.selectTime', {time: val});
        },

        createTimes: function() {
            var hours, minutes, ampm, label;
            for(var i = this.firstValidTime; i <= this.lastValidTime; i += this.increment){
                hours = Math.floor(i / 60);
                minutes = i % 60;
                if (minutes < 10){
                    minutes = '0' + minutes; // adding leading zero
                }
                ampm = hours % 24 < 12 ? 'AM' : 'PM';
                hours = hours % 12;
                if (hours === 0){
                    hours = 12;
                }

                label = hours + ':' + minutes + ' ' + ampm;
                label = uitk.i18n.time();

                times.push({value: i, label: label});
            }
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, TimePicker);

    // User events
    $delegate.on('change', '[data-control="timepicker"]', function (e) {
        var val = $(this).val();
        uitk.publish('timepicker.selectTime', {time: val});
//        var timepicker = $target.closest('.timepicker').data('timepicker');
//        timepicker[pluginName]('selectTime', $(e.target));
    });

})(jQuery, window);