;((window) => {
    'use strict';
    let initializationStarted = false;
    let hasClicked = false;
    const INITIALIZED_STATE = {
        success: 1,
        skip: 2
    };

    const initialize = async (launchPoint) => {
        if(uitk.adapt.webView) return;
        if (window.vac) return;
        if (initializationStarted) {
            // Prevents the VAC from being loaded twice.
           return INITIALIZED_STATE.skip;
        }

        const isIframe = window.self !== window.top;
        if (isIframe) return;

        const isChatFabEnabled = EG?.chatFabEnabled;
        const chatBotSessionEnabled = !!sessionStorage.getItem('chatBot.enabled');
        if(!chatBotSessionEnabled && !isChatFabEnabled) return;


        const pageName = encodeURIComponent(EG?.pageName);
        const applicationName = encodeURIComponent(EG?.appName);
        const glassboxCookie = encodeURIComponent(uitk.readCookie("_cls_s"));
        const timezoneOffset = new Date().getTimezoneOffset();

        if(!pageName && !applicationName) return;

        // Prevents chat loading on login page
        if(pageName === 'page.Auth') return;

        const eventsList = ['VAC_WidgetOpened'];

        try {
            initializationStarted = true;
            const domain = window.location.hostname;

            if (!launchPoint) launchPoint = pageName;

            const payloadResponse = await fetch(`/conversation-service/v1/initiate?page_name=${pageName}&launch_point=${launchPoint}&application_name=${applicationName}
            &domain=${domain}&glassbox_cookie=${glassboxCookie}&timezone_offset=${timezoneOffset}`, {
                credentials: 'same-origin',
            });

            if (!payloadResponse.ok) {
                throw new Error(`Conversation service fetch failed: ${payloadResponse.status}`);
            }

            const payload = await payloadResponse.json();

            if(payload?.enabled) {

                EG.analytics.configs["chat.enabled_type"] = {value: payload.liveChatAbTestValue, bucket: payload.liveChatAbTestBucket, abTestId: payload.liveChatAbTestId}
                uitk.analytics.setAbTests("chat.enabled_type").track('abtest')

                const initArguments = await new Promise((resolve, reject) => {
                    const script = document.createElement('script');
                    script.src = payload.scriptUrl;
                    document.body.appendChild(script);

                    script.addEventListener('load', () => {
                        resolve(payload.vacV2Params);
                    });

                    script.addEventListener('error', e => {
                        reject(new Error(`VAC script url: ${e.target.attributes.src.value} failed to load.`));
                    });
                });

                // Step 2: Initialize VAC with token and configuration. (Step 1 is adding the script to the dom).
                try {
                    await VirtualAgentControl.initialize(
                        initArguments.initializeParams.authToken,
                        initArguments.initializeParams.channelOriginId,
                        initArguments.initializeParams.eventBehaviors,
                        initArguments.initializeParams.analyticsData);
                } catch (err) {
                    initializationStarted = false;
                    throw new Error('Failed to initialize the VAC:' + err);
                }

                // Step 3: Create frame to configure the VAC chat widget
                initArguments.createFrameParams.eventBehaviors.subscribedEvents = eventsList;
                initArguments.createFrameParams.eventBehaviors.eventHandler = eventHandler;
                let vacFrame;
                try {
                    vacFrame = await VirtualAgentControl.createFrame(
                        initArguments.createFrameParams.uiBehaviors,
                        initArguments.createFrameParams.capabilityBehaviors,
                        initArguments.createFrameParams.eventBehaviors,
                        initArguments.createFrameParams.channelOverrides);
                } catch (err) {
                    initializationStarted = false;
                    throw new Error('Failed to create vac frame. Chat will not load: ' + err);
                }

                // Step 4: Start VAC frame and connect to a conversation
                return vacFrame.start(
                    initArguments.startParams.modeOptions,
                    initArguments.startParams.participantData,
                    initArguments.startParams.conversationProperties)
                    .then(function () {
                        window.vac = vacFrame;
                        // If the FAB is being loaded in the background and the user clicks the "Chat" button be sure to open the FAB after it's loaded
                        if (hasClicked) {
                            window.vac.openChatWidget();
                            hasClicked = false;
                        }
                        return INITIALIZED_STATE.success;
                    }).catch(function (err) {
                        initializationStarted = false;
                        throw new Error("Failed to start VAC: " + err);
                    });
            }
        } catch(err) {
            initializationStarted = false;
            uitk.logger.error('UITK: chatBot.initialize failed, ', err);
        }
    };

    const open = (fromPanel = false, launchPoint = "") => {
        sessionStorage.setItem('chatBot.enabled', 'true');
        hasClicked = true;

        if (window.vac) {
            uitk.helpPanel.panelClose();
            window.vac.openChatWidget();
            hasClicked = false;
        } else {
            // First time opening the bot from the buttons
            uitk.chatBot.initialize(launchPoint).then(cpResponse => {
                if (cpResponse === INITIALIZED_STATE.success) {
                    uitk.helpPanel.panelClose();
                    window.vac.openChatWidget();
                    hasClicked = false;
                }
                if (!cpResponse) {
                    uitk.logger.error('UITK: chatBot.open failed');
                    const helpPanelIframe = document.querySelector('.contextual-help-content')
                    if (helpPanelIframe && fromPanel) {
                        helpPanelIframe.contentWindow.postMessage("VAC_LOAD_FAILED");
                    } else {
                        window.postMessage("VAC_LOAD_FAILED");
                    }
                }
            });
        }
    };

    const eventHandler = e => {
        const pageName = EG?.pageName;
        switch(e.type) {
            case 'VAC_WidgetOpened':
                uitk.analytics.track('a', {
                    linkId: pageName + '.Chatbot.Floating.Clicked'
                })
                sessionStorage.setItem('chatBot.enabled', 'true');
                break;
        }
    }

    uitk.chatBot = {
        initialize,
        open
    };

    uitk.chatBot.initialize().catch((x) => {
        uitk.logger.error('UITK: chatBot.initialize process failed', x);
    });


})(window);
