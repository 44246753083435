;(function ($, window, undefined) {
    'use strict';

    const BASE_VERSION_PATH = '/base/version';
    const ASSETS_BASE_URL = 'https://assets.egencia.com/';
    // Prefetch Apps main JS bundle
    // Uses conventions of FE builder, and other STEP components (base/version) to 
    // identify and prefetch the proper version of selected assests for upcoming 
    // paths. More specifically, it sends out a request to base/version endpoint of each 
    // app listed in EG.prefetchApps. It then builds the app js bundle url from the 
    // resuling JSON response and writes pre-fetch link tags to the DOM. 
    //
    // To use, each app populates an prefetch field on the EG object of an app.
    // 
    // For example:
    //   Auth app might add: 
    //   EG.prefetchApps = ['home'];
    //
    //   GHP might add: 
    //   EG.prefetchApps = ['hotels', 'flights', 'cars', 'rails'];
    //
    function prefetchAppAssets() {
        let promisesAr = [];

        // this param is used for linking back to somewhere besides expected
        // in this case we can bypass prefetching for now.
        const isDeepLink = location.search.includes('call-back-url');

        if (Array.isArray(EG.prefetchApps) && !isDeepLink) {
            promisesAr = EG.prefetchApps.map( (appStr) => {
                return fetch(`/${appStr}${BASE_VERSION_PATH}`)
                    .then((response) => {
                        if (!response.ok) {throw Error(response.statusText);}
                        return response;
                    })
                    .then((response) => response.json())
                    .catch((e) => uitk.logger.warn(`UITK prefetchAppAssets(): request to ${appStr}/base/version FAILED: ${e}`))
            });
        }
        Promise.all(promisesAr)
            .then( (results) => {
                return results.map( (item) => {
                    if (item && item.application_name && item.build_number) {
                        addPreFetchLink(`${ASSETS_BASE_URL}${item.application_name}/${item.build_number}/js/app.js`);
                    } else {
                        uitk.logger.warn(`UITK prefetchAppAssets(): building asset url FAILED. fetch results:`, results)
                    }
                });
            });
    }

    function addPreFetchLink(url) {
        const fetchEl = document.createElement('link');
        fetchEl.rel = 'prefetch';
        fetchEl.href = url;
        document.getElementsByTagName('head')[0].appendChild(fetchEl);
    }

    // Setup Prefetcher
    // if requestIdleCallback is supported lets just use that to kick things off
    // otherwise we'll just use the load event
    if ('requestIdleCallback' in window) {
        requestIdleCallback(prefetchAppAssets);
    } else {
        window.addEventListener('load', prefetchAppAssets);
    }

    // Expose so it can be tested
    uitk.modules.prefetchAppAssets = prefetchAppAssets;

}(jQuery, window));


