/*
 *  Project: UITKv2 UITK plugin utility
 *  Description: A helper constructor and fn to use across different modules
 *  Author: a-sszenti@expedia.com
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;(function ( $, window, uitk, undefined ) {

    'use strict';

    var defaults = {};

    function newModule( element, jsOptions, defaults ) {
        var newOptions;

        this.element = $(element);

        jsOptions = jsOptions || {};
        newOptions = this.element.data() || {};

        this.options = $.extend(true, {}, defaults, newOptions, jsOptions);

        if (this.init){
            this.init();
        }

    }

    function initPlugin(pluginName, constructor) {
        /* JQUERY PLUGIN DEFINITION */
        $.fn[pluginName] = function () {

            var options, method, arg, arg2;

            if (typeof arguments[0] === 'object'){
                options = arguments[0];
                method = arguments[1];
                arg = arguments[2];
                arg2 = arguments[3];
            }else{
                options = {};
                method = arguments[0];
                arg = arguments[1];
                arg2 = arguments[2];
            }

            return this.each(function () {
                if (!$.data(this, pluginName)) {
                    $.data(this, pluginName, new constructor( this, options ));
                }
                if (typeof method === 'string') {
                    $.data(this, pluginName)[method](arg, arg2);
                }
            });
        };
//        return $.fn[pluginName];
    }

    uitk.utils.newModule = newModule;
    uitk.utils.initPlugin = initPlugin;



}(jQuery, window, uitk));