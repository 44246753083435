/*
 *  Project: UI Toolkit Stepper Plugin
 *  Description: jQuery Stepper Plugin for use in the Egencia.com UI Toolkit
 *  Author: jobrennan@egencia.com
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;(function ($, window, undefined) {

    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_stepper',
        disabledClass = 'disabled',
        limitClass = 'limit',
        $body = $('body'),
        defaults = {
            step: 1
        };

    /* CONSTRUCTOR */
    function Stepper(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    Stepper.prototype = {

        constructor: Stepper,

        init: function() {
            this.value = this.currentValue();
            this.stepperUp = this.element.siblings('.stepper-up');
            this.stepperDown = this.element.siblings('.stepper-down');
            this.isDisabled = this.element.prop(disabledClass);

            if(!this.isDisabled) {
                this.toggleDisabled();
            }
        },

        stepUp: function (e) {
            if(!this.isDisabled) {
                var newVal = this.currentValue() + this.options.step;
                this.setValue(newVal);
            }
        },

        stepDown: function (e) {
            if(!this.isDisabled) {
                var newVal = this.currentValue() - this.options.step;
                this.setValue(newVal);
            }
        },

        setValue: function (val) {
            var result = this.validate(val);

            if (result !== false) {
                this.value = result;
                this.element.val(this.value);
                this.element.trigger('change'); // Spoof the native event because we don't want to prevent devs from binding to it
                this.toggleDisabled();
            }
        },

        validate: function(newValue) {
            var min = this.options.min;
            var max = this.options.max;

            // setValue() is public, so newValue could be garbage
            try {
                newValue = parseInt(newValue, 10);
            } catch (e) {
                return false;
            }

            // Negative value is not allowed
            if (newValue < 0) {
                return false;
            }
            // Prevent values below min
            else if (min && newValue < min) {
                return min;
            }
            // Prevent values above max
            else if (max && newValue > max) {
                return max;
            }
            // Not negative and there's no min/max limits
            return newValue;
        },

        currentValue: function () {
            return parseInt(this.element.val(), 10);
        },

        toggleDisabled: function() {
            var val = this.value;
            // Has reached min/max limits
            if (this.options.min || this.options.max) {
                if (val === this.options.min) {
                    this.stepperDown.addClass(limitClass);
                }
                else {
                    this.stepperDown.removeClass(limitClass);
                }

                if (val === this.options.max) {
                    this.stepperUp.addClass(limitClass);
                }
                else {
                    this.stepperUp.removeClass(limitClass);
                }
            }

            // Has reached zero and no min
            if (val <= 0 && !this.options.min) {
                this.stepperDown.addClass(limitClass);
            }
            else if (!this.options.min) {
                this.stepperDown.removeClass(limitClass);
            }
        },

        enable: function() {
            this.isDisabled = false;
            this.element.prop(disabledClass, this.isDisabled);
            this.stepperUp.removeClass(disabledClass);
            this.stepperDown.removeClass(disabledClass);
            this.toggleDisabled();
        },

        disable: function() {
            this.isDisabled = true;
            this.element.prop(disabledClass, this.isDisabled);
            this.stepperUp.addClass(disabledClass);
            this.stepperDown.addClass(disabledClass);
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, Stepper);

    /* UI EVENTS */
    // Click up button
    $body.on('click', '.stepper-up', function (e) {
        e.preventDefault();
        var $target = $(this).prev('[data-control=stepper]');

        $target[pluginName]('stepUp', e);
    });

    // Click down button
    $body.on('click', '.stepper-down', function (e) {
        e.preventDefault();
        var $target = $(this).next('[data-control=stepper]');

        $target[pluginName]('stepDown', e);
    });

    // Permit certain keys to change the value
    $body.on('keyup', '[data-control=stepper]', function (e) {
        e.preventDefault();

        switch (e.keyCode) {
            // NOTE: Don't do left/right arrows because the user expects to move their cursor
            case 38: // Up
                $(e.target)[pluginName]('stepUp', e);
                break;
            case 40: // Down
                $(e.target)[pluginName]('stepDown', e);
                break;
            case 8: // Backspace
            case 46: // Delete
                break;
            case 48: // Numbers 0-9
            case 49:
            case 50:
            case 51:
            case 52:
            case 53:
            case 54:
            case 55:
            case 56:
            case 57:
            case 96: // Numpad 0-9
            case 97:
            case 98:
            case 99:
            case 100:
            case 101:
            case 102:
            case 103:
            case 104:
            case 105:
                $(e.target)[pluginName]('setValue', this.value);
                break;
            default:
                break;
        }
    });

    // White-list certain keys
    $body.on('keydown', '[data-control=stepper]', function (e) {
        switch (e.keyCode) {
            case 37: // Left
            case 39: // Right
            case 8: // Backspace
            case 46: // Delete
            case 48: // Numbers 0-9
            case 49:
            case 50:
            case 51:
            case 52:
            case 53:
            case 54:
            case 55:
            case 56:
            case 57:
            case 96: // Numpad 0-9
            case 97:
            case 98:
            case 99:
            case 100:
            case 101:
            case 102:
            case 103:
            case 104:
            case 105:
                break;
            default:
                break;
        }
    });

    // Allow user delete and type in number and check for valid input when blur
    $body.on('blur', '[data-control=stepper]', function (e) {
        if (this.value === ''){
            this.value = 0;
        }
        $(e.target)[pluginName]('setValue', this.value);
    });

    // Expose initAlert function for dynamic client-side rendering and stepper functions.
     function initStepper () {
        $('[data-control="stepper"]')[pluginName]('init');
     }

    initStepper();

    uitk.initStepper = initStepper;

    // Expose object so it can be tested
    uitk.modules.Stepper = Stepper;
}(jQuery, window));
