/*
 *  Project: UI Toolkit Tooltip Plugin
 *  Description: jQuery Tooltip Plugin for use in the Expedia.com UI Toolkit
 *  Author: dstrugnell@expedia.com
 */

/*
 *  OPTIONS (passed via data-* attributes in the html)
 *
 *  @param data-arrow (optional) should the tooltip have an arrow. Default: true
 *  @param data-delay (optional) the delay before the tooltip is show / hidden. Default: 150
 *  @param data-fade (optional) should the tooltip fade in and out. 'true'|'false'|'out' Default: true
 *  @param data-pos (optional) The position of the tooltip. Default: 'tc'. Can be:
 *          'tc' (top-center),
 *          'tl'(top left),
 *          'tr'(top right),
 *          'ml'(middle left),
 *          'mr'(middle right),
 *          'bc' (top-center),
 *          'bl'(top left),
 *          'br'(top right)
 *  @param data-pos-offset (optional) Extra space between the tooltip and the trigger . Default: 5
 *  @param data-text-align (optional) The alignment of the text with in the tooltip. Default: 'left'
 *  @param data-trigger (optional) How the tooltip is triggered. 'hover' | 'click' Default: 'hover'
 *  @param data-width (optional) The width of the tooltip. Default: 150
 *  @param data-content (optional) Contents of the tooltip if a string.
 *  @param data-content-id (optional) Id of html containing html (use href instead if its not needed to link else where).
 *  @param data-manual (optional) should the tooltip be manually open / close / both. Default: none.
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function ($, window, undefined) {

    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_tooltip',
        $window = $(window),
        $body = $('body'),
        $document = $(document),
        activeTooltipClass = 'active-tooltip',
        tooltipOpenClass = 'tooltip-open',
        fadeClass = 'fade',
        bodyMinWidth = parseFloat($body.css('min-width'), 10),
        nonResponsivePage = bodyMinWidth > 950 || Modernizr.mediaqueries === 'false' || Modernizr.mediaqueries === false,
        defaults = {
            arrow: true,
            delay: 0,
            fade: 'out',
            pos: 'tc',
            showTooltip: true,
            posClass: {
                arrow: 'show-arrow',
                t: 'top',
                b: 'bottom',
                m: 'mid',
                mTop: 'mid-top',
                mBottom: 'mid-bottom',
                l: 'left',
                r: 'right',
                c: 'center'
            },
            posOffset: 6,
            template: '<div class="uitk-tooltip"><div class="tooltip-inner"></div><span class="tooltip-arrow"></span></div>',
            textAlign: 'left',
            trigger: 'click',
            width: 288,
            arrowSize: 7,
            arrowOffset: 24,
            hideOffScreen: true, // Hide Tooltip when the Tooltip's trigger is off the screen
            announce: true, // Live announce Tooltip content to screen readers
            respondToSmallScreen: false, // Setting to allow full-width at small screen size
            offsetBefore: false
        },
        theme = {
            standard: {},
            'standard-inverse': {},
            hover: {
                delay: 0,
                pos: 'tc',
                trigger: 'hover',
                width: 'auto'
            },
            invalid: {
                pos: 'mr',
                width: 'auto',
                trigger: 'click'
            },
            calendar: {
                delay: {show: 0, hide: 0},
                pos: 'bl',
                posOffset: 7,
                trigger: 'focus',
                width: 600,
                hideOffScreen: false, //make sure the calendar is visible even when the input has been scrolled off the screen
                announce: false,
                respondToSmallScreen: true,
                offsetBefore: true
            },
            'annual-calendar': {
                delay: {show: 0, hide: 0},
                pos: 'bl',
                posOffset: 7,
                trigger: 'focus',
                width: 900,
                respondToSmallScreen: true,
                offsetBefore: true
            },
            typeahead: {
                delay: {show: 0, hide: 0},
                pos: 'bl',
                posOffset: 7,
                preventFlip: true,
                width: 390,
                hideOffScreen: false,
                announce: false,
                respondToSmallScreen: true,
                offsetBefore: true
            },
            'typeahead-by-category': {
                delay: {show: 0, hide: 0},
                pos: 'bl',
                posOffset: 7,
                preventFlip: true,
                width: 480,
                hideOffScreen: false,
                announce: false,
                respondToSmallScreen: true,
                offsetBefore: true
            }

        },
        hideAll,
        handleScroll,

        isTouchDevice = uitk.isTouchDevice,
        clickEvent = 'click.' + pluginName;

    //set the theme for calendar button to same as calendar.
    theme['calendar-button'] = theme.calendar;

    //function to hide all tooltips
    hideAll = function (currentTooltip) {

        var i,
            $activeTooltip = $('.' + activeTooltipClass);


        for (i = $activeTooltip.length - 1; i >= 0; i = i - 1) {

            var tooltipToRemove = $activeTooltip.eq(i),
                isTypeAhead = (tooltipToRemove.hasClass('theme-typeahead') || tooltipToRemove.hasClass('theme-typeahead-by-category'));

            if (isTypeAhead) {
                continue;
            }

            if (!(currentTooltip && (tooltipToRemove[0] === currentTooltip[0]))) {
                var trigger = tooltipToRemove.data('trigger');
                var plugin = $(trigger).data(pluginName);
                if (plugin) {
                    plugin.hide(true, undefined);
                }
            }
        }
    };

    uitk.subscribe('modal.appended', hideAll);


    /* CONSTRUCTOR */
    function Tooltip(element, jsOptions) {

        var newOptions,
            themeName,
            themeObj;

        this.element = $(element);

        jsOptions = jsOptions || {};
        newOptions = this.element.data() || {};

        if (jsOptions.jsTheme) {
            //a theme in the jsOptions take priority
            themeName = jsOptions.jsTheme;
        } else if (this.element.data('jsTheme')) {
            //if no theme is found in the jsOptions data-js-theme takes priority
            themeName = this.element.data('jsTheme');
        } else {
            //if no the theme is passed then the default is the trigger
            themeName = jsOptions.trigger || newOptions.trigger || defaults.trigger;
            newOptions.jsTheme = themeName;
        }

        themeObj = theme[themeName] || {};
        this.options = $.extend(true, {}, defaults, themeObj, newOptions, jsOptions);

        this.options.vPos = this.options.pos.charAt(0);
        this.options.hPos = this.options.pos.charAt(1);

        if (this.options.delay && typeof this.options.delay === 'number') {
            this.options.delay = {
                show: this.options.delay,
                hide: this.options.delay
            };
        }

        this.init();
    }

    Tooltip.prototype = {

        constructor: Tooltip,

        init: function () {
            var options = this.options,
                posClass = options.posClass;

            this.isShowing = false;

            //store position classes as a string
            this.posClasses = this.getPosClasses(posClass);

            //store content
            this.content = this.getContent();

            //store aria data
            if (options.content) {
                this.ariaData = options.tooltipId || 'tooltip' + new Date().getTime();
            }

            this.manual = options.manual || 'none';
        },

        getPosClasses: function (posClass) {
            var posClasses = '';

            for (var x in posClass) {
                if (posClass.hasOwnProperty(x) && x !== 'arrow') {
                    posClasses = posClasses + posClass[x] + ' ';
                }
            }

            return posClasses;
        },

        getContent: function () {
            var content,
                contentElem,
                href,
                options = this.options;

            if (options.content && typeof options.content === 'string' || typeof options.content === 'number') {
                content = '<p>' + options.content + '</p>';
            } else if (options.content && typeof options.content === 'object') {
                content = options.content;
            } else {
                if (options.contentId) {
                    contentElem = $('#' + options.contentId);
                } else {
                    href = this.element.attr('href');

                    if (href) {
                        href = href.replace(window.location.href.split('#')[0], '');
                    }
                    contentElem = $(href);
                }
                content = contentElem.html();
                contentElem.remove();
            }

            return content;
        },

        makeVisible: function () {
            this.tooltip.css({visibility: 'visible'});
        },

        makeInvisible: function () {
            if (uitk.isTouchDevice) {
                var zoomRatio = document.documentElement.clientWidth / window.innerWidth;
                if (zoomRatio > 1.01) {
                    return;
                }
            }

            if (this.tooltip && this.tooltip.css) {
                this.tooltip.css({visibility: 'hidden'});
            }
        },

        generateTooltip: function () {

            var that = this,
                options = this.options,
                tooltipHtml = $(options.template),
                ariaData = this.ariaData,
                standardThemeAsDefault = options.jsTheme === 'click' || options.jsTheme === 'calendar' || options.jsTheme === 'calendar-button' || options.jsTheme === 'typeahead' || options.jsTheme === 'typeahead-by-category' || options.jsTheme === 'country-code';

            if(options.respondToSmallScreen) {
                tooltipHtml.css('max-width','100%').addClass('full-width-small-screen');
            }

            tooltipHtml.find('.tooltip-inner').prepend(this.content);
            tooltipHtml.find('.tooltip-inner p').last().addClass('last');

            tooltipHtml.addClass(activeTooltipClass).css({
                width: options.width,
                'text-align': options.textAlign,
                visibility: 'hidden'
            }).data('trigger', that.element);

            if (options.jsTheme) {
                tooltipHtml.addClass('theme-' + options.jsTheme);
                if (standardThemeAsDefault) {
                    tooltipHtml.addClass('theme-standard');
                }
            }

            //add fade class for fade in and out
            if (options.fade && (options.fade === true || options.fade === 'true')) {
                tooltipHtml.addClass(fadeClass);
            }

            if (options.arrow) {
                tooltipHtml.addClass(options.posClass.arrow);
            }

            if (options.tooltipClasses) {
                tooltipHtml.addClass(options.tooltipClasses);
            }
            // Typeahead
            if (ariaData) {
                if (options.jsTheme === 'typeahead' || options.jsTheme === 'typeahead-by-category') {

                    // Accessibility:
                    // Many attributes and roles moved from being set here to being part of the markup.

                    // INPUT
                    //that.element

                    // TOOLTIP
                    //tooltipHtml
                    tooltipHtml.attr('id', ariaData);

                } else {
                    tooltipHtml.attr('id', ariaData).attr('role', 'tooltip');
                }
            } else if (options.tooltipId) {
                tooltipHtml.attr('id', options.tooltipId);
            }

            if (that.options.trigger === 'hover' && !isTouchDevice) {
                tooltipHtml.find('.close').remove();
            }

            return tooltipHtml;
        },

        show: function (checkManual, event) {
            var that = this,
                options = this.options;

            //check data-manual, if need publish tooltip.beforeOpen and stop open
            if ((this.options.manual === 'open' || this.options.manual === 'both') && checkManual) {
                //publish before show
                uitk.publish('tooltip.beforeOpen', that.element, event);

                //return to stop the show
                return;
            }

            this.isShowing = true;

            //clear show timeout
            if (this.hideTimeout) {
                clearTimeout(this.hideTimeout);
            }

            //check to make sure tooltip isn't already created or open
            if (!that.tooltip || that.tooltip.closest('html').length === 0) {

                //remove other tooltips
                hideAll();

                that.tooltip = that.generateTooltip();
                that.tooltipArrow = that.tooltip.find('.tooltip-arrow');

                if (options.jsTheme === 'typeahead' || options.jsTheme === 'typeahead-by-category') {

                    var receivingDomElement = $('#uitk_ta_content'),
                        uitkElementExists = receivingDomElement.length;

                    if (uitkElementExists > 0) {
                        that.tooltip.appendTo(receivingDomElement);
                    } else {
                        that.tooltip.appendTo('body');
                    }

                } else {
                    // This is not a typeahead -- it is some other kind of tooltip,
                    // so append to BODY
                    that.tooltip.appendTo('body');
                }

                this.changePos();

                //set position
                that.setPos();

                //check position
                that.checkPos(false, true);
            } else {
                hideAll(that.tooltip);
            }

            // If a click Tooltip we need to track if it's open or not
            if (options.trigger !== 'hover' && options.jsTheme !== 'hover') {
                this.element.addClass(tooltipOpenClass);
            }

            setTimeout(function () {
                //reveal tooltip
                that.tooltip.addClass('on');

                // add fade class for fade out only
                if (options.fade && options.fade === 'out') {
                    setTimeout(function () {
                        that.tooltip.addClass(fadeClass);
                    }, 10);
                }
                // live announce the content of the tooltip
                if (options.announce) {
                    uitk.utils.liveAnnounce($(that.content), 'polite');
                }

                // publish an event that the tooltip is open
                uitk.publish('tooltip.open', that.element, event);
            }, options.delay.show);
        },

        hide: function (checkManual, event) {
            var that = this,
                tooltip,
                needToRemove = $('input[aria-activedescendant]');

            if (needToRemove.length > 0) {
                needToRemove.removeAttr('aria-activedescendant');
            }

            //check data-manual, if need publish tooltip.beforeOpen and stop open
            if ((this.options.manual === 'close' || this.options.manual === 'both') && checkManual) {
                //publish before hide
                uitk.publish('tooltip.beforeClose', that.element, event);

                //return to stop the hide
                return;
            }

            this.isShowing = false;

            //clear show timeout
            if (this.showTimeout) {
                clearTimeout(this.showTimeout);
            }

            //check to make sure tooltip isn't already created or open
            if (this.tooltip) {

                tooltip = this.tooltip;

                this.hideTimeout = setTimeout(function () {
                    tooltip.removeClass('on');

                    //publish tooltip.hidden so other modules no a tooltip has been hidden
                    uitk.publish('tooltip.hidden', that.element);

                    //give time for fade animation
                    setTimeout(function () {
                        if (!tooltip.hasClass('on') && !that.isShowing) {
                            tooltip.remove();
                        }
                    }, 100);
                }, this.options.delay.hide);
            }

            //remove class toolTipActive when hide the tooltip on clickable tooltip
            if (this.element.hasClass(tooltipOpenClass)) {
                this.element.removeClass(tooltipOpenClass);
            }
        },

        setPos: function (newVPos, newHPos) {
            var options = this.options,
                vPos = newVPos || options.vPos,
                hPos = newHPos || options.hPos,
                pos = this.getPos(vPos, hPos);

            this.tooltip.css({left: pos.left, top: pos.top});
            this.tooltipArrow.css({left: pos.arrowLeft, top: pos.arrowTop});
        },

        getTopPos: function (vPos, element) {
            const targetRect = element.getBoundingClientRect();
            const targetOffset = {
                top: targetRect.top + window.pageYOffset
            };
            const targetHeight = targetRect.height;
            const posOffset = this.options.posOffset;
            const tooltipRect = this.tooltip[0].getBoundingClientRect();
            const tooltipHeight = tooltipRect.height;
            const arrowTipHeight = this.options.arrowSize;
            const arrowOffset = this.options.arrowOffset;
            const anchorPos = targetOffset.top + (targetHeight / 2);
            const modalOffset = $('.modal-wrap').scrollTop;
            let topPos,
                arrowTopPos;

            targetOffset.top -= isNaN(modalOffset) ? 0 : modalOffset;

            if (vPos === 't') {
                topPos = targetOffset.top - tooltipHeight - posOffset;
                arrowTopPos = tooltipHeight;
            } else if (vPos === 'm') {
                topPos = anchorPos - (tooltipHeight / 2);
                arrowTopPos = anchorPos - topPos - arrowTipHeight;
            } else if (vPos === 'mTop') {
                topPos =  anchorPos + arrowTipHeight + arrowOffset - tooltipHeight;
                arrowTopPos = anchorPos - topPos - arrowTipHeight;
            } else if (vPos === 'mBottom') {
                topPos = anchorPos - arrowTipHeight - arrowOffset;
                arrowTopPos = anchorPos - topPos - arrowTipHeight;
            } else if (vPos === 'b') {
                topPos = targetOffset.top + targetHeight + posOffset;
                arrowTopPos = tooltipHeight - tooltipHeight - arrowTipHeight;
            }

            return {top: topPos, arrowTop: arrowTopPos};
        },

        getWindowWidth: function () {
            return $window.width();
        },

        getLeftPos: function (hPos, vPos, element) {
            const targetRect = element.getBoundingClientRect();
            const targetOffset = {
                left: targetRect.left + window.pageXOffset
            };
            const targetWidth = targetRect.width;
            const posOffset = this.options.posOffset;
            const tooltipRect = this.tooltip[0].getBoundingClientRect();
            const tooltipWidth = tooltipRect.width;
            const arrowTipWidth = this.options.arrowSize;
            const arrowOffset = this.options.arrowOffset;
            let anchorPos = targetOffset.left + (targetWidth / 2);
            let leftPos,
                arrowLeftPos,
                hPosPx,
                hPosPxR,
                hPosPxL,
                windowWidth;

            if (hPos === 'c') {
                if(this.options.offsetBefore) {
                    anchorPos = targetOffset.left + 20;
                }

                hPosPxR =  anchorPos - (tooltipWidth / 2);
                hPosPxL = anchorPos + (tooltipWidth / 2);
                windowWidth = this.getWindowWidth();
                if (hPosPxR < 0) {
                    leftPos = 0;
                } else if (hPosPxL > windowWidth) {
                    leftPos = windowWidth - tooltipWidth;
                } else {
                    leftPos = hPosPxR;
                }

                arrowLeftPos = anchorPos - leftPos - arrowTipWidth;

            } else if (hPos === 'r') {
                if (vPos === 'm' || vPos === 'mTop' || vPos === 'mBottom') {
                    leftPos = targetOffset.left + targetWidth + arrowTipWidth + posOffset;
                    arrowLeftPos = 0 - arrowTipWidth;
                } else {

                    if(this.options.offsetBefore) {
                        anchorPos = targetOffset.left + 20; // Icon padding offset
                    }

                    hPosPx = anchorPos - tooltipWidth + arrowOffset + arrowTipWidth;
                    if (hPosPx > 0) {
                        leftPos = hPosPx;
                    } else {
                        leftPos = 0;
                    }

                    arrowLeftPos = anchorPos - leftPos - arrowTipWidth;
                }
            } else if (hPos === 'l') {
                if (vPos === 'm' || vPos === 'mTop' || vPos === 'mBottom') {
                    leftPos = targetOffset.left - tooltipWidth - arrowTipWidth - posOffset;
                    arrowLeftPos = tooltipWidth;
                } else {

                    if(this.options.offsetBefore) {
                        anchorPos = targetOffset.left + 20; // Icon padding offset
                    }

                    hPosPx = anchorPos - arrowOffset - arrowTipWidth;
                    windowWidth = this.getWindowWidth();
                    if (hPosPx + tooltipWidth < windowWidth) {
                        leftPos = hPosPx;
                    } else {
                        leftPos = windowWidth - tooltipWidth;
                    }

                    arrowLeftPos = anchorPos - leftPos - arrowTipWidth;
                }
            }

            return {left: leftPos, arrowLeft: arrowLeftPos};
        },

        getPos: function (newVPos, newHPos) {

            var pos = {},
                options = this.options,
                posClass = options.posClass,
                vPos = newVPos || options.vPos,
                hPos = newHPos || options.hPos,
                element = this.element[0],
                tooltip = this.tooltip,
                getLeftPos,
                getTopPos;

            //remove arrow position classes and add new position class
            tooltip.removeClass(this.posClasses);
            tooltip.addClass(posClass[vPos] + ' ' + posClass[hPos]);

            //set vertical position
            getTopPos = this.getTopPos(vPos, element);
            pos.top = getTopPos.top;
            pos.arrowTop = getTopPos.arrowTop;

            //set horizontal position
            getLeftPos = this.getLeftPos(hPos, vPos, element);
            pos.left = getLeftPos.left;
            pos.arrowLeft = getLeftPos.arrowLeft;

            return pos;
        },
        //Allow tooltips to have different positions at different breakpoints
        changePos: function () {
            if (this.options.pos.length !== 2) {
                var switchPos = this.options.pos.split(','),
                    newPos = 0,
                    windowWidth = this.getWindowWidth(),
                    ie8 = $('html').hasClass('ie8');

                for (var i = 0; i < switchPos.length; i++) {
                    if (switchPos[i].length > 2 && !ie8) {

                        switchPos[i] = switchPos[i].trim();

                        newPos = parseInt(switchPos[i].substring(3, switchPos[i].length), 10);
                        if (windowWidth <= newPos) {
                            this.options.vPos = switchPos[i].charAt(0);
                            this.options.hPos = switchPos[i].charAt(1);
                        }
                    }
                    else {
                        this.options.vPos = this.options.pos.charAt(0);
                        this.options.hPos = this.options.pos.charAt(1);
                    }
                }

                return;
            }

        },

        //check the position of the tooltip to see if it needs flipping
        testTopPos: function (tooltipTop, paddingTop, scrollTopPos) {
            return tooltipTop + paddingTop > scrollTopPos;
        },

        testBottomPos: function (tooltipBottom, paddingTop, scrollTopPos, windowHeight) {
            return tooltipBottom - paddingTop < scrollTopPos + windowHeight;
        },

        testLeftPos: function (tooltipLeft, paddingSide, scrollLeftPos) {
            return tooltipLeft + paddingSide > scrollLeftPos;
        },

        testRightPos: function (tooltipRight, paddingSide, scrollLeftPos, windowWidth) {
            return tooltipRight - paddingSide < scrollLeftPos + windowWidth;
        },

        checkPos: function (reset, recheck, newVPos, newHPos) {
            //only check the position if tooltip has been created
            if (this.tooltip) {

                let vPos = newVPos || this.options.vPos;
                let hPos = newHPos || this.options.hPos;

                this.makeInvisible();

                //if preventFlip == true then set position to original value and do nothing else
                if (this.options.preventFlip) {
                    this.changePos();
                    this.setPos(vPos, hPos);
                    this.makeVisible();
                    return;
                }

                const origPos = this.getPos(vPos, hPos);

                const paddingSide = parseInt(this.tooltip.css('padding-left'), 10);
                const paddingTop = parseInt(this.tooltip.css('padding-top'), 10);
                const tooltipTop = origPos.top;
                const tooltipLeft = origPos.left;
                const tooltipRect = this.tooltip[0].getBoundingClientRect();
                const tooltipRight = tooltipLeft + tooltipRect.width;
                const tooltipBottom = tooltipTop + tooltipRect.height;

                const scrollTopPos = $window.scrollTop();
                const scrollLeftPos = $window.scrollLeft();
                const windowHeight = $window.height();
                const windowWidth = $window.width();

                //test the position of the tooltip against the edge of the window
                const testTop = this.testTopPos(tooltipTop, paddingTop, scrollTopPos);
                const testBottom = this.testBottomPos(tooltipBottom, paddingTop, scrollTopPos, windowHeight);
                const testLeft = this.testLeftPos(tooltipLeft, paddingSide, scrollLeftPos);
                const testRight = this.testRightPos(tooltipRight, paddingSide, scrollLeftPos, windowWidth);

                let update = false;

                //if all tests are true then nothing is outside an edge, therefore return and do nothing else
                if (testTop && testBottom && testLeft && testRight) {
                    if (reset) {
                        this.changePos();
                        this.setPos(vPos, hPos);
                    }
                    this.makeVisible();
                    return;
                }

                //horizontal edge detection
                if (vPos === 'm') {
                    if (hPos === 'l' && !testLeft) {
                        hPos = 'r';
                        update = true;
                    } else if (hPos === 'r' && !testRight) {
                        hPos = 'l';
                        update = true;
                    }
                } else {
                    if (hPos === 'l' && !testRight) {
                        hPos = 'l';
                        update = true;
                    } else if (hPos === 'r' && (!testLeft || nonResponsivePage)) {
                        hPos = 'r';
                        update = true;
                    } else if (hPos === 'c' && (!testRight || !testLeft)) {
                        hPos = 'c';
                        update = true;
                    }
                }

                //vertical edge detection
                if (vPos === 't' && !testTop) {
                    vPos = 'b';
                    update = true;
                } else if (vPos === 'b' && !testBottom) {
                    vPos = 't';
                    update = true;
                } else if (vPos === 'm') {
                    if (testTop && !testBottom) {
                        vPos = 'mTop';
                        update = true;
                    } else if (testBottom && !testTop) {
                        vPos = 'mBottom';
                        update = true;
                    }
                }

                //if an update to position is required re-run setPos()
                if (update) {
                    this.changePos();
                    this.setPos(vPos, hPos);

                    if (recheck) {
                        this.checkPos(false, false, vPos, hPos);
                    } else {
                        this.makeVisible();
                    }
                } else if (!this.options.hideOffScreen) {
                    // if the trigger is offscreen then 'update' will be false and therefore tooltip won't be redisplayed
                    // which is what is expected in most cases, however if option.hideOffScreen is set to false we want
                    // to call makeVisible anyway without changing the position
                    this.makeVisible();
                }

            }

        },

        updateContent: function (newContent) {
            if (newContent && typeof newContent === 'string') {
                this.content = '<p>' + newContent + '</p>';
            } else if (newContent && typeof newContent === 'object') {
                this.content = newContent;
            }
        }
    };


    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, Tooltip);


    /* EVENT LISTENERS (delegated to body) */

    // Detect orientation change on the ipad and fire the positioning function again.
    $(window).on('orientationchange', function (event) {
        $('[data-control="tooltip"]').each(function(e){
            var $element = $(this);
            if($element.css('visibility') === 'hidden') {
                $element[pluginName]('hide', true, e);
            } else {
                $element[pluginName]('checkPos', true, e);
            }
        });
    });

    // Create a unique event ID to share between to all events for '[data-control="tooltip"]'
    var tooltipEventId = uitk.createUniqueId();


    $body.on(clickEvent, '[data-control="tooltip"]', {eventId: tooltipEventId}, function (e) {
        var $target = $(e.target).closest('[data-control="tooltip"]'),
            trigger = $target.data('trigger'),
            chosenTheme = theme[$target.data('jsTheme')],
            showTooltip = $target.data('showTooltip'),
            checkToolTip = $document.find('.' + activeTooltipClass).hasClass('theme-click');

        if ((trigger && trigger !== 'hover') || (!trigger && chosenTheme && chosenTheme.trigger !== 'hover') || ($target.attr('href') && $target.attr('href').charAt(0) === '#')) {
            e.preventDefault();
            if (checkToolTip) {
                if ($target.hasClass(tooltipOpenClass)) {
                    $target[pluginName]('hide', true, e);
                } else if ($('[data-control="tooltip"]').hasClass(tooltipOpenClass)) {
                    hideAll();
                    $target[pluginName]('show', true, e);
                }
            } else {
                $target[pluginName]('show', true, e);
            }
        }
    });

    // NON-CLICK TOOLTIPS
    var eventCallback = function (e, triggerType, action) {
        var $target = $(e.target).closest('[data-control="tooltip"]'),
            trigger = $target.data('trigger'),
            chosenTheme = theme[$target.data('jsTheme')];

        if (trigger === triggerType || (!trigger && chosenTheme && chosenTheme.trigger === triggerType)) {
            e.preventDefault();
            $target[pluginName](action);
        }
    };


    // MOUSE ENTER / LEAVE USED FOR HOVER TOOLTIPS
    // Hover tooltips also need focus/blur for users who are tabbing to them
    $body.on(clickEvent + ' focus.' + pluginName, '[data-control="tooltip"]', {eventId: tooltipEventId}, function (e) {
        eventCallback(e, 'hover', 'show', true, e);
    });

    $body.on('mouseenter.' + pluginName + ' focus.' + pluginName, '[data-control="tooltip"]', {eventId: tooltipEventId}, function (e) {
        eventCallback(e, 'hover', 'show', true, e);
    });

    $body.on('mouseleave.' + pluginName + ' blur.' + pluginName, '[data-control="tooltip"]', {eventId: tooltipEventId}, function (e) {
        eventCallback(e, 'hover', 'hide', true, e);
    });

    // Trigger focus (used to trigger tooltips on inputs)
    $body.on('focus.' + pluginName, '[data-control="tooltip"]', function (e) {
        eventCallback(e, 'focus', 'show', true, e);
    });

    $body.on('blur.' + pluginName, '[data-control="tooltip"]', function (e) {
        eventCallback(e, 'focus', 'hide', true, e);
    });


    //OTHER TOOLTIP INTERACTIONS

    //close button
    $body.on(clickEvent, '.active-tooltip .close', function (e) {
        var $target = $(e.target).closest('.active-tooltip');
        e.preventDefault();
        $target.data('trigger')[pluginName]('hide', true, e);
    });

    //close the tooltip on clicking else where on the page
    $document.on(clickEvent + ' focusin.' + pluginName, function (e) {
        if (e.type === 'focusin' && !uitk.utils.isFocusableElement($(e.target))) {
            return;
        }

        var $target = $(e.target).closest('[data-control="tooltip"]');

        if ($target.length <= 0) {
            $target = $(e.target);
        }

        //if clicked item isn't related to tooltip then close the tooltip
        if ($target.data('control') !== 'tooltip' && $target.data('control') !== 'calendar' && $target.data('control') !== 'calendar-button' && !$target.hasClass(activeTooltipClass) && $target.closest('.' + activeTooltipClass).length === 0) {
            hideAll();
        }
    });

    //close tooltip of Esc is pressed
    $document.on('keydown', function (e) {
        if (e.keyCode === 27) {
            hideAll();
        }
    });

    //SCROLLING / PAGE RESIZE
    handleScroll = function () {
        var i,
            $activeTooltip = $('.' + activeTooltipClass);

        for (i = $activeTooltip.length - 1; i >= 0; i = i - 1) {
            if ($($activeTooltip.eq(i).data('trigger')).css('visibility') === 'hidden') {
                $($activeTooltip.eq(i).data('trigger'))[pluginName]('hide', true, true);
            } else {
                $($activeTooltip.eq(i).data('trigger'))[pluginName]('checkPos', true, true);
            }
        }
    };

    //reposition tooltip on page scroll or resize
    $(window).on('scroll.' + pluginName + ' resize.' + pluginName, function () {
        handleScroll();
    });

    // when a modal is added to the page listen for scroll on modal container.
    uitk.subscribe('modal.appended', function () {
        //reposition tooltip on page scroll or resize
        $('.modal-wrap').on('scroll.' + pluginName, function () {
            handleScroll();
        });
    });

    //expose hideAll so it can be used elsewhere
    uitk.tooltips = {
        hideAll: hideAll
    };

    //expose object so it can be tested more easily
    uitk.modules.Tooltip = Tooltip;
}(jQuery, window));