/*
 *  Project: UI Toolkit Character-count Plugin
 *  Description: jQuery Character-count Plugin for use in the Egencia.com UI Toolkit
 *  Author: sasandhu@egencia.com
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.

;(function ($, window, undefined) {
    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_characterCount',
        defaults = {},
        warningThreshold = 10;

    /* CONSTRUCTOR */
    function CharacterCount (element, jsOptions) {
    	uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    CharacterCount.prototype = {

        constructor: CharacterCount,

        init: function () {
            this.maxlength = this.element.data('maxlength') || false;
            this.allowoverflow = this.element.data('allowoverflow') || false;
            const charCount = this.element.parent().find('.character-count');
            if (charCount.length > 0) {
                this.$counter = charCount;
            } else {
                this.$counter= $('<span class="character-count"></span>');
                this.element.parent().append(this.$counter);
            }
            this.getDisplayCount(this.maxlength);
            this.element.on('input', $.proxy(this.getDisplayCount, this, this.maxlength));
        },

        getDisplayCount: function(hasMaxlength) {
            if(hasMaxlength) {
                if (!this.allowoverflow) {
                    this.validateTextarea(hasMaxlength);
                }
                const count = this.calculateRemainingCharCount(hasMaxlength);
                this.updateRemainingWarning(count);
                this.$counter.text(count);
            } else {
                this.$counter.text(this.element.val().length);
            }
        },

        validateTextarea: function(maxlength) {
            if (maxlength < this.element.val().length) {
                this.element.val(this.element.val().substring(0, maxlength));
            }
        },

        calculateRemainingCharCount: function(maxlength) {
            return maxlength - this.element.val().length;
        },

        updateRemainingWarning: function(count) {
            let eventName = 'character-count.maxlengthNotExceeded';
            if(count <= warningThreshold) {
                this.$counter.addClass('max-count-reached');
                if (count < 0) {
                    eventName = 'character-count.maxlengthExceeded';
                } 
            }
            else {
                this.$counter.removeClass('max-count-reached');
            }
            uitk.publish(eventName, {element: this.element});
        }

    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, CharacterCount);

    // INITIALISE CHARACTER COUNT
    function initCharacterCount() {
        $('[data-control=character-count]')[pluginName]();
    }

    initCharacterCount();

    uitk.initCharacterCount = initCharacterCount;

    // Expose object so it can be tested
    uitk.modules.CharacterCount = CharacterCount;


}(jQuery, window));
