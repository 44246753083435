 ;
(function ($, window, undefined) {
    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_table',
        $body = $('body'),
        defaults = {},
        clickEvent = 'click.' + pluginName,
        msgContainerTemplate =  '<tr class="table-msg-container"><td></td></tr>',
        loaderTemplate = '<span class="loader loader-dark loader-secondary loading"></span>',
        noMoreResultTemplate = '<p>No more results.</p>',
        tryAgainTemplate = '<p>Failed to load results. <span class="table-try-again">Try Again</span>?</p>';

    /* CONSTRUCTOR */
    function Table (element, jsOptions) {
        var newOptions;

        this.element = $(element);

        jsOptions = jsOptions || {};
        newOptions = this.element.data() || {};

        this.options = $.extend({}, defaults, newOptions, jsOptions);
        this.init();
    }

    Table.prototype = {

        constructor: Table,

        init: function () {
            var $currentTable = this.element,
                $nextSibling = $currentTable.next(),
                $parent = $currentTable.parent();

            $currentTable.detach();

            if($currentTable.data('tableInit')){
                return;
            }

            $currentTable.data('tableInit', true);
            this.addDataTitle($currentTable);

            if($nextSibling.length > 0){
                $currentTable.insertBefore($nextSibling);
            } else {
                $currentTable.appendTo($parent);
            }

            if($currentTable.parent().hasClass('data-table-lazyload')){
                var loadMore = _.debounce($currentTable[pluginName].bind($currentTable, 'onNeedLoadMore', $currentTable), 500, true);
                $currentTable.parent().on('scroll', function() {
                    var clientHeight = this.offsetHeight,
                        scrollTop = this.scrollTop,
                        scrollHeight = this.scrollHeight;
            
                    if(scrollTop + clientHeight >= scrollHeight) {
                       loadMore();
                    }
                });
            }
        },

        sort: function ($column) {
            var data = {tablebody: this.element.find('tbody'), attribute: $column.data('attribute')};
            // Column is going from ascending to descending
            if ($column.hasClass('asc')) {
                $column.siblings().removeClass('asc dsc');
                $column.removeClass('asc').addClass('dsc');
                data.ascending = false;
                data.descending = true;
            } else {
                // Column has yet to be sorted or is descending, sort ascending
                $column.siblings().removeClass('asc dsc');
                $column.removeClass('dsc').addClass('asc');
                data.ascending = true;
                data.descending = false;
            }

            uitk.publish('tables.sort', data);
        },

        onNeedLoadMore: function($table){
            Table.prototype.addLoadMsg($table, 'loading');
            uitk.publish('tables.loadMore', $table);
        },

        templateMessageContainer: function($table){
            if($table.find('.table-msg-container').length === 0){
                const tableColumnCount = $table.find('tr th').length;
                const messageContainer = $(msgContainerTemplate);
                messageContainer.children('td').attr('colspan', tableColumnCount);
                $table.append(messageContainer);
            }
        },

        addLoadMsg: function ($table, loadState) {
            Table.prototype.templateMessageContainer($table);
            switch(loadState){
                case 'finish':
                    return $table.find('.table-msg-container td').html(noMoreResultTemplate);
                case 'error':
                    return $table.find('.table-msg-container td').html(tryAgainTemplate);
                case 'loading':
                    return $table.find('.table-msg-container td').html(loaderTemplate);
                default:
                    return undefined;
            }
        },

        removeLoadMsg: function ($table) {
                var messageContainer = $table.find('.table-msg-container');
                messageContainer.remove();
        },

        appendTableData: function ($table, data) {
            var tdItems = data.tableData,
                loaderState = data.tableResultStatus;

            if(tdItems) {
                this.removeLoadMsg($table);
                $table.find('tbody').append(tdItems);
                this.addDataTitle(data.tableElem);
            } else  {
                this.addLoadMsg ($table, loaderState);
            }
        },
        addDataTitle: function($currentTable) {
            var $tableRows = $currentTable.find('tr'),
                $tableHeadings = $tableRows.eq(0).find('th'),
                t,
                numberOfTableRows = $tableRows.length,
                headings = [];
            $tableHeadings.each(function(){
                headings.push($(this).text());
            });
            for(t = 0; t < numberOfTableRows; t++){
                var $currentRow = $tableRows.eq(t),
                    $tableCells = $currentRow.find('td'),
                    c,
                    numberOfTableCells = $tableCells.length;
                for(c = 0; c < numberOfTableCells; c++){
                    var $currentCell = $tableCells.eq(c);
                    $currentCell.attr('data-title', headings[c]);
                }
            }
        }
    };

    /* JQUERY PLUGIN DEFINITION */
    uitk.utils.initPlugin(pluginName, Table);

    // INITIALISE TABLES
    // For tables that are present on page load
    function initTable() {
        $('[data-control=table]')[pluginName]();
    }

    initTable();

    uitk.initResponsiveTables = initTable;

    // Expose object so it can be tested
    uitk.modules.Table = Table;

    // PUBSUB LISTENERS
    uitk.subscribe('tables.loadFinish', function(topic, data) {
        var $currentTable = data.tableElem;

        $currentTable[pluginName]('appendTableData', $currentTable, data);
    });

    // EVENT LISTENERS
    // Sortable column.
    $body.on(clickEvent, '.table-sortable', function () {
        var $table = $(this).closest('[data-control=table]');

        $table[pluginName]('sort', $(this));
    });

    $body.on(clickEvent, '.table-try-again', function () {
        var $table = $(this).closest('[data-control=table]');

        $table[pluginName]('addLoadMsg', $table, 'loading');
        uitk.publish('tables.loadMore', $table);
    });

}(jQuery, window));
