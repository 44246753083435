;(function ($, window, undefined) {
    'use strict';

    /* SETUP */
    var pluginName = 'uitk_toggle',
        $body = $('body'),
        openClass = 'open',
        defaults = {
            transition: 'slide',
            paneHeight: 0
        };

    /* TOGGLE */
    function Toggle(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }

    Toggle.prototype = {

        constructor: Toggle,

        init: function () {
            this.togglePane = this.options.contentId ? $('#' + this.options.contentId) : $(this.element.attr('href'));
            this.togglePane.on(uitk.topics.transitionEnd, $.proxy(this.transitionEnd, this));

            // Set open state
            this.isOpen = (this.element.hasClass(openClass) || this.togglePane.hasClass(openClass)) ? true : false;

            // Set the initial height for partial toggle first
            if(!this.isOpen && this.options.paneHeight > 0) {
                this.togglePane.attr('style', 'height:'+this.options.paneHeight+'px');
            }
        },

        // Transition callback
        transitionEnd: function (e) {
            e.stopPropagation();
            if(e.target === this.togglePane[0]) {
                if (this.isOpen) {
                    this.openEnd();
                } else {
                    this.closeEnd();
                }
            }
        },

        // Sets the height of the Toggle Pane which aids the transition
        setOpenHeight: function () {
            this.togglePane.attr('style', 'height:'+this.togglePane[0].scrollHeight+'px');
        },

        // Opens the Toggle
        open: function (event) {
            // Publish and stop if manual
            if ((this.options.manual === 'open' || this.options.manual === 'both') && event) {
                uitk.publish('toggle.beforeOpen', this.element, event);
                return;
            }

            // Set transition method
            this.togglePane.addClass(this.options.transition);

            // Set open height -> scrollHeight for transition
            this.setOpenHeight();

            this.element.addClass(openClass);
            this.isOpen = true;
            if(this.options.collapseText) {
                this.element.html(this.options.collapseText);
            }
        },

        // Post-open work (required because transitions are async)
        openEnd: function () {
            this.togglePane.addClass(openClass).removeClass(this.options.transition);
            uitk.publish('toggle.opened', this.element);
        },

        // Closes the Toggle
        close: function (event) {
            var that = this;

            // Set open height -> scrollHeight for transition
            this.setOpenHeight();

            // Publish and stop if manual
            if ((this.options.manual === 'close' || this.options.manual === 'both') && event) {
                uitk.publish('toggle.beforeClose', this.element, event);
                return;
            }

            // Remove open class (remove height:auto css rule)
            this.togglePane.removeClass(openClass);

            // Set open height -> scrollHeight for transition (firefox)
            this.setOpenHeight();

            // Set transition method
            this.togglePane.addClass(this.options.transition);

            setTimeout(function(){
                that.togglePane.attr('style', 'height:' + that.options.paneHeight + 'px');
            }, 0);

            this.element.removeClass(openClass);
            this.isOpen = false;
        },

        // Post-close work (required because transitions are async)
        closeEnd: function () {
            this.togglePane.removeClass(this.options.transition);

            if(this.options.expandText) {
                this.element.html(this.options.expandText);
            }

            uitk.publish('toggle.closed', this.element);
        },

        // Open or close the Toggle
        toggle: function (event) {
            if(this.isOpen){
                this.close(event);
             } else {
                this.open(event);
             }
        }
    };

    // EVENT LISTENERS
    $body.on('click', '[data-control="toggle"]', function (e) {
        var $target = $(e.target), isFormElem;
        // Validation to form element within data-control='toggle' for toggle prevention.
        isFormElem = $target.is('input, select, textarea, label, [data-control="slider"]') || $target.hasClass('stepper-control') || $target.parent().hasClass('stepper-control');

        if(!isFormElem) {
            e.preventDefault();
            $(this)[pluginName]('toggle', e);
        }
    });

    // JQUERY PLUGIN DEFINITION
    uitk.utils.initPlugin(pluginName, Toggle);

    // Initiate partial toggle
    function initPartialToggle () {
        $('[data-control="toggle"][data-pane-height]')[pluginName]();
    }

    initPartialToggle();

    // Expose init partial toggle for dynamic client-side rendering
    uitk.initPartialToggle = initPartialToggle;

    // Expose object so it can be tested more easily
    uitk.modules.Toggle = Toggle;

}(jQuery, window));