; // Safety net against concatenated scripts
(function ($, window, undefined) {

    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_calendar',
        tooltipPlugin = 'uitk_tooltip',
        $delegate = $(document),
        defaults = {
            tooltip: true,
            template: 'partials/uitk/uitk-calendar-template',
            dayTemplate: 'partials/uitk/calendar-day',
            minBookingLength: null
        },
        hasTouch = Modernizr.touch,
        hasWin8Touch = Modernizr.win8touch,
        clickEvent = 'click.' + pluginName,
        openCalendar,
        todaysDate = moment().startOf('day'), // moment today's day
        momentLocaleData = moment().localeData(),
        initCalendarInputs,
        isSmallScreen,
        backspaceKeyCode = 8,
        december = 11;

    initCalendarInputs = (function () {
        if (hasTouch || hasWin8Touch) {
            // For touch devices, return a function that sets calendar inputs to read only to
            // prevent the on-screen keyboard from appearing
            // Needs to be run before an input is clicked
            return function () {
                $('[data-control="calendar"]').attr('readonly', 'readonly');
            };
        }
        // For non-touch devices return a blank function
        return function () {};
    })();

    //attach initCalendarInput to the uitk object
    uitk.initCalendarInputs = initCalendarInputs;


    /* CONSTRUCTOR */
    function Calendar(element, jsOptions) {
        uitk.utils.newModule.call(this, element, jsOptions, defaults);
    }


    Calendar.prototype = {

        constructor: Calendar,
        monthCache: {},
        calendarObj: false,
        calendarConfig: false,
        daysHeader: false,
        END: 'end',
        START: 'start',

        init: function () {
            var that = this;

            this.dateError = {};

            // Setup calendar template
            if (!this.calTemplate) {
                Calendar.prototype.calTemplate = uitk.getCompiledTemplate(this.options.template);
                Calendar.prototype.dayTemplate = uitk.getCompiledTemplate(this.options.dayTemplate);
            }

            // Render days header
            if (!this.daysHeader) {
                Calendar.prototype.daysHeader = this.getDaysHtml();
            }

            // Set up for date range
            if (this.options.startDate || this.options.endDate) {

                this.dateRange = this.options.dateRange;

                if (this.options.startDate) {
                    this.startDateSelector = this.options.startDate;
                    this.startDateField = this.calendarPair = $(this.startDateSelector);
                } else {
                    this.startDateSelector = '#' + this.element.attr('id');
                    this.startDateField = this.element;
                    this.dateType = this.START;
                }

                if (this.options.endDate) {
                    this.endDateSelector = this.options.endDate;
                    this.endDateField = this.calendarPair = $(this.endDateSelector);
                } else {
                    this.endDateSelector = '#' + this.element.attr('id');
                    this.endDateField = this.element;
                    this.dateType = this.END;
                }

                // Set default stay length
                this.options.defaultBookingLength = this.startDateField.data('default-booking-length');
                this.options.maxBookingLength = this.startDateField.data('max-booking-length');

                if (!this.options.lastValidDate) {
                    this.options.lastValidDate = this.startDateField.data('last-valid-date');
                }

            }
            // Set up for Single date (includes inline Calendar)
            else {
                this.calendarPair = false;
                this.startDateField = this.element;
                this.dateType = this.START;
                this.startDate = this.options.value ? moment(this.options.value, 'YYYY-M-D', true).startOf('day') : null;
            }

            // firstValidDate
            if (this.options.firstValidDate) {
                this.options.firstValidDate = moment(this.options.firstValidDate, 'YYYY-M-D', true).startOf('day');
            } else {
                this.options.firstValidDate = todaysDate;
                if (this.options.annual) {
                    this.options.annualWithNoFirstValidDate = true;
                }
            }

            // lastValidDate
            this.options.lastValidDate = moment(this.options.lastValidDate, 'YYYY-M-D', true).startOf('day');

            // If using Tooltip subscribe to Tooltip hidden event
            if (this.options.tooltip) {
                var token;
                token = uitk.subscribe('tooltip.hidden', function (topic, elem) {
                    that.tooltipClosed(elem);
                });
            }

            // Binding clear input user events
            this.element
                .on('keydown', $.proxy(this.keyDown, this))
                .on('keyup', $.proxy(this.keyUp, this))
                .on('click', $.proxy(this.open, this));
        },

        getDaysHtml: function () {

            var generatedHtml = '',
                dayNames,
                numOfDays;

            dayNames = moment.weekdaysMin(true); // moment.weekdaysMin true return locale specific order
            numOfDays = dayNames.length;

            for (var i = 0; i < numOfDays; i++) {
                generatedHtml += '<li>' + dayNames[i] + '</li>';
            }

            return generatedHtml;
        },

        // Gets the year and month for the next month
        getNextMonth: function (month, year) {
            var nextMonthNum,
                nextYear;

            if (month === december) { // If month is Dec...
                nextMonthNum = 0; // then next month is Jan, i.e. start months over, ...
                nextYear = year + 1; // and bump the year.
            } else {
                nextMonthNum = month + 1; // Otherwise bump to the next index/month...
                nextYear = year; // and the year stays the same.
            }

            return {month: nextMonthNum, year: nextYear};
        },

        // Gets the year and month for the prev month
        getPrevMonth: function (month, year) {
            var prevMonthNum,
                prevYear;

            if (month === 0) {
                prevMonthNum = december;
                prevYear = year - 1;
            } else {
                prevMonthNum = month - 1;
                prevYear = year;
            }

            return {month: prevMonthNum, year: prevYear};
        },


        // Work out blank days at start of month
        getFirstDayOffset: function (firstDay, startDay) {
            var firstDayOffset;

            startDay = startDay || momentLocaleData.firstDayOfWeek();

            firstDayOffset = firstDay - startDay;

            if (firstDayOffset < 0) {
                firstDayOffset = 7 + firstDayOffset;
            }

            return firstDayOffset;
        },


        // Generate month
        getMonthHtml: function (month, year) {
            var i,
                monthNumOfDays,
                firstDay,
                firstDayOffset;
            var cacheKey = month + '-' + year;
            var html = '';
            var firstDayOfMonth = moment({year:year, month:month, date:1}).startOf('day');

            // If month has already been generated get it form cache
            if (cacheKey in this.monthCache) {
                return this.monthCache[cacheKey];
            }

            // Work out blank days
            firstDay = firstDayOfMonth.day();
            firstDayOffset = this.getFirstDayOffset(firstDay);

            // Generate html
            monthNumOfDays = firstDayOfMonth.daysInMonth();
            for (i = 1; i <= monthNumOfDays; i = i + 1) {
                if (i === 1 && firstDayOffset > 0) {
                    html += this.dayTemplate({
                        firstDayOffset: firstDayOffset,
                        day: i,
                        month: month,
                        year: year
                    });
                }
                else {
                    html += this.dayTemplate({
                        day: i,
                        month: month,
                        year: year
                    });
                }
            }

            // Store result in cache
            this.monthCache[cacheKey] = html;

            return html;
        },


        // Generate cal
        getCalHtml: function (month, year) {
            var generatedHtml,
                nextMonth,
                nextMonthNum,
                nextYear,
                newYear,
                calData,
                lastDate,
                options = this.options;


            this.firstMonth = month;
            this.firstYear = year;

            nextMonth = this.getNextMonth(month, year);
            nextMonthNum = nextMonth.month;
            nextYear = nextMonth.year;
            newYear = nextMonthNum === 0 ? 'new-year' : '';

            // GENERATE CALENDAR
            calData = {
                prevButton: {},
                nextButton: {},
                calDays: this.daysHeader,
                month1: moment().month(month).format('MMMM'),
                year1: year,
                calDates1: this.getMonthHtml(month, year),
                month2: moment().month(nextMonthNum).format('MMMM'),
                year2: nextYear,
                calDates2: this.getMonthHtml(nextMonthNum, nextYear),
                annual: options.annual,
                dateRange: this.calendarPair,
                newYear: newYear
            };

            // Add Time Picker
//            if (this.options.timePickerId) {
//                calData.timepicker = true;
//                $.extend(calData, uitk.calendarObj.timepickers[this.options.timePickerId]);
//            }

            generatedHtml = this.calTemplate(calData);

            this.currentCal = $(generatedHtml).data('calendar', this.element);
            this.currentMonth = month;
            this.currentYear = year;

            this.highlight(this.currentCal);

            // Disable past dates:
            // If NOT an Annual cal. Or if it is an Annual cal and a firstValidDate has been given. AND...
            // If the start date has been set or if the date about to be selected is the start date.
            if (!options.annualWithNoFirstValidDate && (!this.startDate || this.dateType === this.START)) {
                this.disableDatesBefore(this.currentCal, options.firstValidDate);
            }
            else if (this.dateType === this.END) {
                if(!options.annualWithNoFirstValidDate) {
                    this.disableDatesBefore(this.currentCal, this.startDate);
                }

                // -1 means maxBookingLength is turned off
                if (options.maxBookingLength !== -1) {
                    lastDate = this.startDate.clone().add(options.maxBookingLength, 'd');
                    this.disableDatesAfter(this.currentCal, lastDate);
                }
            }

            if (options.lastValidDate) {
                this.disableDatesAfter(this.currentCal, options.lastValidDate);
            }

            var todaysDateLink = this.getDateLink(this.currentCal, todaysDate);
            if (todaysDateLink) {
                todaysDateLink.addClass('today');
            }

            return this.currentCal;
        },

        nextYear: function () {
            var nextMonthNum;
            var nextMonth = this.getNextMonth(this.currentMonth, this.currentYear);
            // Forces the current two months to stay within view
            if (nextMonth.month === 0) {
                nextMonthNum = 11;
            }
            else {
                nextMonthNum = nextMonth.month - 1;
            }

            this.calContainer.html(this.getCalHtml(nextMonthNum, this.currentYear + 1).html());
        },

        prevYear: function () {
            var prevMonthNum;
            var prevMonth = this.getPrevMonth(this.currentMonth, this.currentYear);
            // Forces the current two months to stay within view
            if (prevMonth.month === 11) {
                prevMonthNum = 0;
            }
            else {
                prevMonthNum = prevMonth.month + 1;
            }

            this.calContainer.html(this.getCalHtml(prevMonthNum, this.currentYear - 1).html());
        },

        nextMonth: function ($target) {
            var nextMonth;

            if ($target.hasClass('last-month')) {
                this.calContainer.addClass('show-second-month');

                // Allow small screen device to display one additional month.
                if (isSmallScreen()) {
                    nextMonth = this.getNextMonth(this.currentMonth, this.currentYear);
                    this.calContainer.html(this.getCalHtml(nextMonth.month, nextMonth.year).html());
                }
            } else {
                nextMonth = this.getNextMonth(this.currentMonth, this.currentYear);
                this.calContainer.html(this.getCalHtml(nextMonth.month, nextMonth.year).html());
            }
        },

        prevMonth: function () {
            var prevMonth;
            var calContainer = this.calContainer;

            prevMonth = this.getPrevMonth(this.currentMonth, this.currentYear);
            calContainer.html(this.getCalHtml(prevMonth.month, prevMonth.year).html());

            if(calContainer.hasClass('show-second-month')) {
                calContainer.removeClass('show-second-month');
            }
        },

        getDateFromField: function (field, forceDate) {
            var fieldDate;
            var error = field[pluginName]('getDateError');

            if (error && error.flagged) {
                if (error.replacementDate && (error.highlight || forceDate)) {
                    fieldDate = moment(error.replacementDate);
                } else {
                    fieldDate = false;
                }
            } else {
                fieldDate = moment(field.val(), momentLocaleData.longDateFormat('l'), true).startOf('day');
            }

            return fieldDate;
        },

        getStartDate: function (startDate) {
            const start = startDate ? moment(startDate).startOf('day') : this.getDateFromField(this.startDateField);

            if(!start.isValid()) {
                this.startDate = null;
                return todaysDate;
            }

            if(start.isBefore(this.options.firstValidDate) && !this.options.annualWithNoFirstValidDate) {
                this.startDate = this.options.firstValidDate;
                return this.options.firstValidDate;
            }

            this.startDate = start;
            return start;
        },

        getInitialCal: function () {
            if (this.state !== 'open') {
                var that = this,
                    secondMonthElem,
                    currentCal,
                    firstDate = that.options.firstValidDate,
                    firstMonth = firstDate.month(),
                    firstYear = firstDate.year(),
                    startDate,
                    endDate,
                    inputDate;

                this.state = 'open';

                // GET DATES
                // Get date from start date input
                startDate = this.getStartDate();

                // Get end date from end date input (if there is one)
                if (this.endDateField) {
                    endDate = this.getDateFromField(this.endDateField);
                    this.setThisEndDate(startDate, endDate);
                }

                // If start and end dates aren't both in error
                if ((this.startDate && this.startDate.isValid()) || (this.endDate && this.endDate.isValid())) {
                    // Store this date
                    if (this.dateType === this.END) {
                        inputDate = this.getDateFromField(this.endDateField, true) || endDate;
                    } else {
                        //inputDate = this.getDateFromField(this.startDateField, true) || startDate;
                        inputDate = startDate;
                    }

                    // WORK OUT WHICH MONTHS TO SHOW
                    // Use last displayed set of dates if set
                    if ((this.lastDisplayedMonth || this.lastDisplayedMonth === 0) && this.lastDisplayedYear) {
                        firstMonth = this.lastDisplayedMonth;
                        firstYear = this.lastDisplayedYear;
                    }

                    // Does the inputted date show up in these months
                    // Only needs testing if error on field hasn't been flagged
                    if ((inputDate && inputDate.isValid()) && (!this.dateError.flagged || (this.dateError.flagged && this.dateError.replacementDate))) {
                        var dateType = this.dateType,
                            inputMonth = inputDate.month(),
                            inputYear = inputDate.year(),
                            getSecondMonth = this.getNextMonth(firstMonth, firstYear),
                            secondMonth = getSecondMonth.month,
                            secondYear = getSecondMonth.year,
                            inputPrevMonth;

                        //check if date is not in range
                        if (!(inputMonth >= firstMonth && inputYear >= firstYear && inputMonth <= secondMonth && inputYear <= secondYear)) {
                            if (dateType === this.START) {
                                firstMonth = inputMonth;
                                firstYear = inputYear;
                            } else if (dateType === this.END) {
                                inputPrevMonth = this.getPrevMonth(inputMonth, inputYear);
                                firstMonth = inputPrevMonth.month;
                                firstYear = inputPrevMonth.year;
                            }
                        }
                    }
                }

                this.setLastDisplayed({month: firstMonth, year: firstYear});
                this.getCalHtml(firstMonth, firstYear);

                // Set initial Inline Calendar date
                if (this.options.inline && this.options.value) {
                    this.getDateLink(this.currentCal, moment(this.options.value, 'YYYY-M-D', true).startOf('day')).addClass('start');
                }

                currentCal = that.currentCal;

                // Add show-second-month class if required
                secondMonthElem = currentCal.find('.cal-section').eq(1);
                if (secondMonthElem.find('.start').length > 0 || (this.dateType === this.END && secondMonthElem.find('.end').length > 0)) {
                    currentCal.addClass('show-second-month');
                }

                return currentCal;
            }
            return undefined;
        },

        getDateLink: function (cal, date) {
            return cal.find('a[data-day="' + date.date() + '"][data-month="' + date.month() + '"][data-year="' + date.year() + '"]');
        },

        highlight: function (cal) {
            var start = this.highlightStart(cal),
                end = this.highlightEnd(cal);

            this.highlightRange(cal, start, end);
        },

        highlightStart: function (cal) {
            const startDate = this.getStartDate();

            if (startDate.isValid()) {
                var dateLink = this.getDateLink(cal, startDate);
                return dateLink.addClass('start');
            }
            return undefined;
        },

        highlightEnd: function (cal) {
            if (this.endDateField) {
                const lastDate = this.getStartDate().clone().add(this.options.maxBookingLength, 'd');
                const endDate = this.getDateFromField(this.endDateField);

                if(!endDate.isValid()) {
                    return todaysDate;
                } else if (endDate.isSameOrBefore(lastDate)) {
                     return this.getDateLink(cal, endDate).addClass('end');
                } else {
                    return this.getDateLink(cal, lastDate).addClass('end');
                }
            }
            return undefined;
        },

        highlightRange: function (cal, start, end) {
            var allDates = cal.find('.cal-dates a'),
                startIndex = allDates.index(start),
                endIndex = allDates.index(end)+1,
                startDate = this.startDate,
                startDateYear,
                endDate = this.endDate,
                endDateYear,
                currentMonth = this.currentMonth,
                currentYear = this.currentYear,
                startPos,
                endPos,
                WITHIN_VIEW = 0,//  x is within the viewable calendar:  |.x.|...|
                BEFORE_VIEW = -1,//  x is before view:  x |...|...|
                AFTER_VIEW = 1;//  x is after view:  |...|...| x

            allDates.removeClass('highlight');

            if (startIndex >= endIndex) {
                allDates.filter('.end').removeClass('end');
            }

            //is the selected START date within view or too far before or after what's visible
            if (start && startIndex > 0) {
                startPos = WITHIN_VIEW;
            } else if (startDate && startDate.isValid()) {
                startDateYear = startDate.year();
                if ((startDate.month() < currentMonth && startDateYear === currentYear) || startDateYear < currentYear) {
                    startPos = BEFORE_VIEW;
                } else {
                    startPos = AFTER_VIEW;
                }
            } else {
                return;
            }

            // Is the selected END date within view or is it too far before or after what's visible?
            if (end && endIndex >= 0) {
                endPos = WITHIN_VIEW;
            } else if (endDate && endDate.isValid()) {
                endDateYear = endDate.year();
                if ((endDate.month() < currentMonth && endDateYear === currentYear) || endDateYear < currentYear) {
                    endPos = BEFORE_VIEW;
                } else {
                    endPos = AFTER_VIEW;
                }
            } else {
                return;
            }

            //START and END dates have been selected
            //if the selected range is not visible, don't bother highlighting
            if ((startPos < WITHIN_VIEW && endPos < WITHIN_VIEW) || (startPos > WITHIN_VIEW && endPos > WITHIN_VIEW)) {
                return;
            }

            //ONLY START date is selected
            //start date is before view, so highlight from there up to mouse, otherwise don't bother because you can't select an end date
            if (startPos < WITHIN_VIEW) {
                startIndex = 0;
            }
            else if (startPos > WITHIN_VIEW) {
                return;
            }

            //ONLY END date is selected
            //end date is after view, so highlight from there back to mouse, otherwise don't bother because you can't select a start date
            if (endPos > WITHIN_VIEW) {
                endIndex = allDates.length;
            }
            else if (endPos < WITHIN_VIEW) {
                return;
            }

            //highlight all days within the selected range
            allDates.slice(startIndex, endIndex).addClass('highlight');
        },

        tooltipClosed: function (elem) {
            if (elem[0] === this.element[0]) {
                elem.closest('label').removeClass('focused');
                this.state = 'closed';
            }
        },

        //disable
        disableDatesBefore: function (cal, date) {
            var allDates = cal.find('.cal-dates a'),
                dateLink = this.getDateLink(cal, date),
                dateIndex;

            if (dateLink.length > 0) {
                dateIndex = allDates.index(dateLink);

                if (dateIndex > 0) {
                    allDates.slice(0, dateIndex).addClass('disabled');
                    cal.find('button.prev').addClass('first-month');
                    cal.find('button.prev-year').addClass('first-year');
                }
            }
        },

        disableDatesAfter: function (cal, date) {
            var allDates = cal.find('.cal-dates a'),
                allDatesLength = allDates.length,
                dateLink = this.getDateLink(cal, date),
                dateIndex,
                firstDate,
                firstDateYear,
                firstDateMonth;

            if (dateLink.length > 0) {
                dateIndex = allDates.index(dateLink);

                if (dateIndex + 1 < allDatesLength) {
                    allDates.slice(dateIndex + 1).addClass('disabled');
                    cal.find('button.next').addClass('last-month');
                    cal.find('button.next-year').addClass('last-year');
                }
            } else {
                firstDate = allDates.first();
                firstDateYear = firstDate.data('year');
                firstDateMonth = firstDate.data('month');

                if (firstDateYear > date.year() || ((firstDateMonth > date.month()) && (firstDateYear === date.year()))) {
                    allDates.addClass('disabled');
                    cal.find('button.next').addClass('last-month');
                }
            }
        },

        selectDate: function ($target) {
            var data = {},
                isoDateString,
                displayDate;

            var selectedDate = moment({year:$target.data('year'), month:$target.data('month'), date:$target.data('day')}).startOf('day');

            this.calContainer.find('.selected').removeClass('selected');
            $target.addClass('selected');

            this.setLastDisplayed();
            this.clearDateError();

            // Determine which to update
            if (this.calendarPair) {
                if (this.dateType === this.START) {
                    this.setStartDate(selectedDate);
                }
                else if (this.dateType === this.END) {
                    this.setEndDate(selectedDate);
                }
            } else {
                displayDate = selectedDate.format('l');
                //expectation is that the selected date is the user's timezone and iso date uses that offset
                isoDateString = this.getIsoDateString(selectedDate);
                this.element.data('isoDate', isoDateString);
                this.element.val(displayDate).trigger('input');

                data.element = this.element;
                data.date = selectedDate.toDate();
                data.isoDate = isoDateString;
                uitk.publish('calendar.selectDate', data);
            }

            // Automatically open the end date if this is the first time setting the start date
            if (this.dateRange && this.dateType === this.START && !this.endDate) {
                this.setDefaultEndDate(selectedDate);
                $(this.endDateSelector).trigger('focus');
            } else {
                if (isSmallScreen()) {
                    this.element.trigger('blur');
                } else {
                    this.element.trigger('focus');
                }
                if($target.closest('.cal').data('calendar')){
                    $target.closest('.cal').data('calendar')[tooltipPlugin]('hide');
                }
            }
        },

        setEndDate: function (date, defaultBookingLength) {
            var displayDate, isoDateString, finalDate, startDateIso;
            var data = {};
            var $start = $(this.startDateSelector);
            var $end = $(this.endDateSelector);
            var startDate = moment($start.val(), momentLocaleData.longDateFormat('l'), true).startOf('day');
            var endDate = date || moment($end.val(), momentLocaleData.longDateFormat('l'), true).startOf('day');
            var minOffsetDate = (startDate && startDate.isValid()) ? startDate.clone().add(this.options.minBookingLength, 'd') : date;

            if (endDate.isSameOrAfter(minOffsetDate)) {
                finalDate = endDate;
            }
            else if (endDate.isBefore(minOffsetDate)) {
                finalDate = minOffsetDate;
                data.endDateAutoAdjusted = true;
            }

            displayDate = finalDate.format('l'); // Style short
            isoDateString = this.getIsoDateString(finalDate);
            startDateIso = this.getIsoDateString(startDate);

            $end.data('isoDate', isoDateString);
            $end.val(displayDate).trigger('input');

            data.element = $end;
            data.end = true;
            data.startDate = startDate.toDate();
            data.startDateIso = startDateIso;
            data.endDate = finalDate.toDate();
            data.endDateIso = isoDateString;
            data.defaultBookingLengthSelected = !!defaultBookingLength;
            uitk.publish('calendar.selectDate', data);
        },

        setStartDate: function (date) {
            var endDisplayDate;
            var minOffsetDate = date.clone().add(this.options.minBookingLength, 'd');
            var $start = $(this.startDateSelector);
            var $end = $(this.endDateSelector);
            var endDate = moment($end.val(), momentLocaleData.longDateFormat('l'), true);
            var displayDate = date.format('l'); // Style short
            var endDateIso = this.getIsoDateString(endDate);
            var isoDateString = this.getIsoDateString(date);
            var data = {};
            var diffDays = (endDate && endDate.isValid()) ? Math.abs(date.diff(endDate, 'days')) : undefined;
            var endDateIsLessThanMinOffsetDate = (endDate && endDate.isValid()) && endDate.isBefore(minOffsetDate) ? true : false;
            var diffDaysIsGreaterThanMaxBookingLength = diffDays > this.options.maxBookingLength;

            $start.data('isoDate', isoDateString);
            $start.val(displayDate).trigger('input');

            if (endDateIsLessThanMinOffsetDate || diffDaysIsGreaterThanMaxBookingLength) {
                endDate = minOffsetDate;
                endDisplayDate = endDate.format('l');
                endDateIso = this.getIsoDateString(endDate);
                $end.data('isoDate', endDateIso);
                $end.val(endDisplayDate).trigger('input');
                data.endDateAutoAdjusted = true;
            }

            if (diffDaysIsGreaterThanMaxBookingLength) {
                this.endDate = undefined;
            }

            data.element = $start;
            data.start = true;
            data.startDate = date.toDate();
            data.startDateIso = isoDateString;
            data.endDate = endDate.toDate();
            data.endDateIso = endDateIso;
            uitk.publish('calendar.selectDate', data);

        },

        mouseenterDate: function ($target) {
            if (this.dateType === this.START) {
                this.calContainer.find('a.start').removeClass('start');
                this.calContainer.find('a.highlight-start').removeClass('highlight-start');
                $target.addClass('highlight-start');
                this.highlightRange(this.calContainer, $target, this.highlightEnd(this.calContainer));
            } else if (this.dateType === this.END) {
                this.calContainer.find('a.end').removeClass('end');
                this.calContainer.find('a.highlight-end').removeClass('highlight-end');
                $target.addClass('highlight-end');
                this.highlightRange(this.calContainer, this.highlightStart(this.calContainer), $target);
            }
        },

        mouseleaveDate: function ($target) {
            $target.removeClass('highlight-start highlight-end');
            // Highlight original date range
            this.highlightRange(this.calContainer, this.highlightStart(this.calContainer), this.highlightEnd(this.calContainer));
        },


        //SETTERS
        setContainer: function ($elem) {
            this.calContainer = $elem;
        },

        // Set the last months that were displayed
        setLastDisplayed: function (options, updatePair) {

            var month = options && (options.month || options.month === 0) ? options.month : this.firstMonth,
                year = options && options.year ? options.year : this.firstYear;

            if (!updatePair && updatePair !== false && this.calendarPair) {
                updatePair = true;
            } else {
                updatePair = false;
            }

            if (updatePair) {
                this.calendarPair[pluginName]('setLastDisplayed', {month: month, year: year}, false);
            }

            this.lastDisplayedMonth = month;
            this.lastDisplayedYear = year;
        },

        setThisEndDate: function (startDate, endDate) {
            if (!endDate.isValid() || endDate.isBefore(startDate)) {
                this.endDate = null;
            } else {
                this.endDate = endDate;
            }
        },

        setDefaultEndDate: function (date) {
            this.setEndDate(date.clone().add(this.options.defaultBookingLength,'d'), true);
        },

        //HELPER FUNCTIONS / DOCUMENTED API

        //function to allow the following options to updated after calendar has loaded
        /*
         - firstValidDate
         - lastValidDate
         - maxBookingLength
         */
        updateOptions: function (newOptions, updatePair) {

            var key,
                pairOptions = {},
                pairNeedsUpdate = false;

            //set update pair to true if undefined
            if (!updatePair && updatePair !== false && this.calendarPair) {
                updatePair = true;
            } else {
                updatePair = false;
            }

            for (key in newOptions) {
                if (newOptions.hasOwnProperty(key)) {
                    switch (key) {
                        case 'firstValidDate':
                        case 'lastValidDate':
                            this.options[key] = moment(newOptions[key], 'YYYY-M-D', true).startOf('day');
                            break;
                        case 'maxBookingLength':
                            this.options[key] = newOptions[key];
                            pairOptions[key] = newOptions[key];
                            break;
                        default:
                            this.options[key] = newOptions[key];
                    }
                }
            }

            if (updatePair === true) {
                for (key in pairOptions) {
                    if (pairOptions.hasOwnProperty(key)) {
                        pairNeedsUpdate = true;
                        break;
                    }
                }

                if (pairNeedsUpdate) {
                    if (this.options.startDate) {
                        this.startDateField[pluginName]('updateOptions', pairOptions, false);
                    } else if (this.options.endDate) {
                        this.endDateField[pluginName]('updateOptions', pairOptions, false);
                    }
                }
            }

        },

        //functions to allow an error to be flagged.
        flagDateError: function (replacementDate, highlight) {
            if (!highlight && highlight !== false) {
                highlight = true;
            } else {
                highlight = false;
            }

            this.dateError = {
                flagged: true,
                replacementDate: replacementDate || false,
                highlight: highlight
            };
        },

        clearDateError: function () {
            this.dateError = {
                flagged: false,
                replacementDate: false,
                highlight: false
            };
        },

        getDateError: function () {
            return this.dateError;
        },

        keyDown: function(e) {
            if (e.keyCode === backspaceKeyCode) {
                if(this.element.val().length === 0) {
                    this.dirty = false;
                } else {
                    this.dirty = true;
                }
            }
        },

        keyUp: function(e) {
            var data = {};

            if (e.keyCode === backspaceKeyCode) {
                if(this.element.val().length === 0 && this.dirty) {
                    data.element = $(this.element);
                    uitk.publish('calendar.cleared', data);
                }
            }
        },

        open: function() {
            if (this.state !== 'open') {
                openCalendar(this.element);
            }
        },

        getIsoDateString: function(date) {
            if (!date.isValid()){
                return undefined;
            }
            return date.format('YYYY-MM-DD');
        }

    };


    /* JQUERY PLUGIN DEFINITION */
    $.fn[pluginName] = function () {

        var options, method, arg, arg2, returned;

        if (typeof arguments[0] === 'object') {
            options = arguments[0];
            method = arguments[1];
            arg = arguments[2];
            arg2 = arguments[3];
        } else {
            options = {};
            method = arguments[0];
            arg = arguments[1];
            arg2 = arguments[2];
        }

        this.each(function () {
            if (!$.data(this, pluginName)) {
                $.data(this, pluginName, new Calendar(this, options));
            }
            if (typeof method === 'string') {
                returned = $.data(this, pluginName)[method](arg, arg2);
            }
        });

        return returned;
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////


    openCalendar = function ($target) {
        var $cal;

        $cal = $target[pluginName]('getInitialCal');

        if ($cal !== undefined && $target.data('inline')) {
            $target.html($cal);
            $target[pluginName]('setContainer', $target.find('.cal'));
        }
        else {
            if ($cal !== undefined) {
                // Init Tooltip
                $target[tooltipPlugin]({
                    jsTheme: $cal.data('jsTheme')
                });
                $target[tooltipPlugin]('updateContent', $cal);
                $target[tooltipPlugin]('show');
                $target[tooltipPlugin]('checkPos', true, true);
                $target[pluginName]('setContainer', $target.data(tooltipPlugin).tooltip.find('.cal'));
                $target.closest('label').addClass('focused');
            }

            // Set showCal to true so calendar can be opened again
            setTimeout(function () {
                $target.data('showCal', true);
            }, 100);
        }
    };

    isSmallScreen = function () {
        return window && 'matchMedia' in window && window.matchMedia('screen and (min-width: 0px) and (max-width: 540px)').matches;
    };

    // Init inline Calendars
    $(document).ready(function () {
        $('.cal-inline [data-control="calendar"]').each(function () {
            openCalendar($(this));
        });
    });

    // EVENT LISTENERS
    // Focus
    $delegate.on('focus.' + pluginName, '[data-control="calendar"]', function (event) {
        var $target = $(event.target).closest('[data-control="calendar"]');

        if ($target.data('showCal') !== false) {
            // Prevent open from happening twice (once for click and once for focus)
            $target.data('showCal', false);

            // Open calendar in Tooltip
            openCalendar($target);
        } else {
            $target.data('showCal', true);
        }
    });

    // Next year
    $delegate.on(clickEvent, '.cal .next-year', {stopPropagation: true}, function (e) {
        var $target = $(e.target),
            calendar = $target.closest('.cal').data('calendar');

        e.preventDefault();
        e.stopPropagation();

        calendar[pluginName]('nextYear', $target);
    });

    // Prev year
    $delegate.on(clickEvent, '.cal .prev-year', {stopPropagation: true}, function (e) {
        var $target = $(e.target),
            calendar = $target.closest('.cal').data('calendar');

        e.preventDefault();
        e.stopPropagation();

        calendar[pluginName]('prevYear', $target);
    });

    // Next month
    $delegate.on(clickEvent, '.cal .next', {stopPropagation: true}, function (e) {
        var $target = $(e.target),
            calendar = $target.closest('.cal').data('calendar');

        e.preventDefault();
        e.stopPropagation();

        calendar[pluginName]('nextMonth', $target);
    });

    // Prev month
    $delegate.on(clickEvent, '.cal .prev', {stopPropagation: true}, function (e) {
        var $target = $(e.target);
        var calendar = $target.closest('.cal').data('calendar');

        e.preventDefault();
        e.stopPropagation();

        calendar[pluginName]('prevMonth');
    });

    // Pick a date
    $delegate.on(clickEvent, '.cal-dates a', function (e) {
        e.preventDefault();
        var $target = $(e.target).closest('a');
        var calendar = $target.closest('.cal').data('calendar');

        if (!$target.hasClass('disabled')) {
            calendar[pluginName]('selectDate', $target);
        }
    });

    // Hover over date
    $delegate.on('mouseenter.' + pluginName, '.cal-dates a', function (e) {
        handleHover(e, 'mouseenterDate');
    });

    // Hover away from date
    $delegate.on('mouseleave.' + pluginName, '.cal-dates a', function (e) {
        handleHover(e, 'mouseleaveDate');
    });

    function handleHover(e, funcName) {
        e.preventDefault();
        var $target = $(e.target).closest('a');
        var calendar = $target.closest('.cal').data('calendar');

        if (!$target.hasClass('disabled')) {
            calendar[pluginName](funcName, $target);
        }
    }


    // Disable calendar inputs for touch-capable devices to prevent on-screen keyboard
    // Needs to be done before input is clicked
    initCalendarInputs();

    // Expose object so it can be tested without DOM
    uitk.modules.Calendar = Calendar;

}(jQuery, window));
