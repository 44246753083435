(function ($) {
    'use strict';

    var $body = $('body'),
        $document = $(document),
        tooltipPlugin = 'uitk_tooltip',
        googleMapsSessionToken;

    // Constructor
    var Typeahead = function (input, template, options) {
        this.$element = $(input);
        this.$autocomplete = this.$element.closest('.autocomplete');
        this.template = Handlebars.templates[template]; // Template comes from the Autocomplete's data-template attribute
        this.opts = $.extend(true, {}, Typeahead.defaultOptions, options);
        this.cache = {};
        this.selected = {};
        this.ignoreNext = false; // prevents keyup?
        this.preventSelect = false;
        this.isClickOn = false;
        this.bodyClick = false;
        this.dirty = true;
        this.lastValidValue = null;
        this.elementSelected = false;

        // Set up the input and listeners
        this.$element.autocomplete = 'off';

        // For gecko-based browsers, we turn autocomplete off on the <form>, not the <input>
        this.$element.closest('form').attr('autocomplete', 'off');

        // Bind user events
        this.$element
            .on('autocomplete.cleared', $.proxy(this.showDefaultResults, this))
            .on('focus', $.proxy(this.focus, this))
            .on('keydown', $.proxy(this.keyDown, this))
            .on('keyup', $.proxy(this.keyUp, this))
            .on('keypress', $.proxy(this.keyPress, this))
            .on('blur', $.proxy(this.blur, this));

        // Typeahead dialog and its listeners:
        this.$ta = $('<div class="typeahead">');

        if (this.opts.tooltip) {
            this.$element[tooltipPlugin]({
                content: this.$ta,
                template: '<div class="uitk-tooltip"><div class="tooltip-inner"></div><span class="tooltip-arrow-border"></span><span class="tooltip-arrow"></span></div>'
            });
        } else {
            this.$ta.appendTo(document.body);

            if (!uitk.isTouchDevice) {
                this.$ta
                    .on('mousedown', $.proxy(this.clickOn, this))
                    .on('mouseup', $.proxy(this.clickOff, this))
                    .on('click', $.proxy(this.click, this))
                    .on('mouseenter', 'a', $.proxy(this.mouseEnter, this));
            } else {
                this.$ta.on('click', (e) => e.preventDefault());
            }
        }

        // For tags input and placeholder
        if (this.opts.tags) {
            this.$tagsInputWrap = this.$autocomplete.find('.autocomplete-tags-wrap');

            $.each(this.$tagsInputWrap.find('.tag'), (idx, tag) => {
                var $tag = $(tag);

                $tag.on('click', '.autocomplete-tag-remove', $.proxy(this.removeTag, this));
                // Make sure that pre pop tags are selected
                this.selected[$tag.data('value')] = $tag.attr('id');
            });

            this.checkTagLimit();
        }

        // Attach a reference to this instance to the input
        this.$element.data('typeahead', this);

        // Making this optional in case some app is initializing somewhere
        // before user interaction.
        if (this.opts.giveFocusOnInit) {
            this.focus();
        }
    };

    // Prototype
    Typeahead.prototype = {
        constructor: Typeahead,
        shouldBeOpen: false,
        timeout: null,
        adapters: {},

        updateAutocompleteWithResult: function (autocompleteId, result) {
            var $this = $('#' + autocompleteId);
            if ($this.length > 0) {
                if (!$this.data('typeahead')) {
                    var _ = new Typeahead($this, $this.data('template'), $this.data());
                }

                $this.data('typeahead').updateTextField(result);
            }
        },
        essSourcesCallback: function (query, callback, options) {
            const ess = Typeahead.expediaSuggest(query, options);

            $.getJSON(ess.url, ess.data, results => {
                // If items are from Google, we'll set the provider which is pretty much only used in the template to set the Google logo
                const provider = (results?.some(result => result?.data?.[0]?.category?.toLowerCase()?.includes('google'))) ? 'google' : 'default';
                callback(this.remapEssResults(results, query), provider, options);
            });
        },

        iconMap: {
            'place': 'locationalt',
            'lob_flights': 'flightsalt',
            'lob_hotels': 'hotelsalt',
            'train': 'trainalt',
            'subdirectory_arrow_right': 'angled-arrow-right',
            'location_city' : 'destination'
        },

        // Helps testing to expose this logic
        remapEssResults: function (results, query) {
            return {
                query,
                search_results: results ? results.map( (category) => {

                    return {
                        label: category.label,
                        data: category.data.reduce( (acc, item) => {

                            const sanitizedResult = uitk.utils.removeHtmlInString(item.regionNames.displayName);
                            const display_value = sanitizedResult.replace(new RegExp(uitk.utils.escapeSpecialRegexChars(query), 'gi'), function (match) {
                                return '<b>' + match + '</b>';
                            });
                            const value = item.regionNames.fullName ?? item.regionNames.lastSearchName;

                            if (!this?.selected?.hasOwnProperty(value)) { // Remove results that have already been selected (only applies to Tags option)
                                acc.push({
                                    id: item.gaiaId ?? item.id,
                                    icon: this.iconMap[item.icon] ?? 'locationalt',
                                    display_value: display_value,
                                    opt_display_value: item.regionNames?.secondaryDisplayName,
                                    child: item.hierarchyInfo ? item.hierarchyInfo.isChild : false,
                                    category: item.type,
                                    value, 
                                    result: JSON.stringify(item)
                                });
                            }

                            return acc;
                        }, [])
                    };
                }) : []
            };
        },

        startSearch: function () {
            var query = this.$element.val();

            if (this.opts.useDefaultResults && !query) {
                this.showDefaultResults();
            } else if (this.opts.useDefaultResults || query.length >= this.opts.minchar) {
                // Send search request
                const cacheResult = this.cache[query];

                if (!cacheResult) {
                  //debounce
                  clearTimeout(this.timeout);
                  this.timeout = setTimeout(() => { this.request(query); }, 200);

                }
                // Use cache
                else {
                    this.render(this.preRender(query, cacheResult.results, cacheResult.provider));
                }
            } else {
                this.close();
            }
        },

        clearCache: function () {
            this.cache = {};
        },

        // ignoreResults is used to ping the Typeahead service on init. Apparently this makes things faster...
        request: function (text, ignoreResults) {
            let successCallback;
            if (ignoreResults) {
                successCallback = $.noop;
            } else {
                successCallback = $.proxy(this.callback, this);
            }
            this.essSourcesCallback(text, successCallback, this.opts);
        },

        // Takes the JSON returned from the Autocomplete source and gets it ready to be rendered
        callback: function (data, provider, options) {
            let results = data.search_results;
            let q = data.query; // query is what the user had typed at the time of this search

            if (uitk.autocompleteLss.adapters?.[options?.adapter]) {
                const safeStr = q.replace(/[<>&]/g, ' ');
                results = uitk.autocompleteLss.adapters[options.adapter](safeStr, results, provider);

            }

            if (results && this.shouldBeOpen) {
                // Cache results for this search
                this.cache[q] = {
                    results,
                    provider
                };

                // Render
                this.render(this.preRender(q, results, provider));

                uitk.publish('autocomplete.displayed', {
                    $autocomplete: this.$element,
                    provider
                });
            } else {
                this.cache[q] = {};
                this.close();
            }

            return results; // this function is meant for "side-effects" only but adding this return makes unit testing much easier
        },

        preRender: function (text, results, provider) {
            var sanitizedResult;

            if (this.shouldRender(text)) {
                // If there are results, prepare and render them. Otherwise show 'no results' if set.
                const resultsFlat = results?.flatMap((category)=>category.data);
                if (resultsFlat?.length > 0) {

                    const resultsByCategory = results.reduce( (acc, category) => {
                       acc[category.label] = category.data;
                       return acc;
                    }, {})

                    return this.template({
                        provider: provider,
                        autocompleteId: this.opts.autocompleteId,
                        results: resultsFlat,
                        resultsByCategory: resultsByCategory
                    });

                } else if (this.opts.noResultsTitle || this.opts.noResultsBody) {

                    return Handlebars.templates['partials/uitk/autocomplete-no-results']({
                        title: this.opts.noResultsTitle,
                        body: this.opts.noResultsBody
                    });
                }
            }
            return undefined;
        },

        // Displays the results
        render: function (template) {
            if (template) {
                this.$ta.html(template);
                this._showTA();
            }
        },

        _showTA: function () {
            if (this.$ta.is(':hidden')) {
                this.$ta.show();
            }

            if (this.opts.tooltip) {
                this.$element[tooltipPlugin]('show');
                this.$element[tooltipPlugin]('checkPos', true, true);
                this.$ta
                    .off()
                    .on('click', $.proxy(this.click, this))
                    .on('mousedown', $.proxy(this.clickOn, this))
                    .on('mouseup', $.proxy(this.clickOff, this))
                    .on('mouseenter', 'a', $.proxy(this.mouseEnter, this));
            } else {
                var height = parseInt(this.$element.css('height'), 10);
                var offset = this.$element.offset();
                this.$ta.css({
                    top: offset.top + height + 'px',
                    left: offset.left + 'px'
                });
                this.$ta.show();
            }
        },

        shouldRender: function (query) {
            var val = this.$element.val();
            // we may get query in encoded form so we are decoding it before comparing with value.
            if (query) {
                try {
                    query = decodeURIComponent(query);
                } catch (e) {
                    // decodeURIComponent() may throw URIError, try unescape() first,
                    // before giving up completely
                    try {
                        query = unescape(query);
                    } catch (e) {
                        // fall back to using the unprocessed query if nothing works
                        query = query;
                    }
                }
            }
            // Using defaults AND nothing has been typed yet (or they backspaced all the way) OR the input still matches the query (i.e. these results are for the current input) then ok to render
            if (this.opts.useDefaultResults && (query === '' || query === val)) {
                return true;
            }
            // Input still matches the query (i.e. these results are for the current input), ok to render
            return query === val;
        },

        close: function () {
            this.removeHighlights();

            if (this.opts.tooltip) {
                //the hide method will destroy events in jq but not in zepto, this leads to numerous bugs because we don't actually destroy the reference, just remove it from the DOM to hide
                //To deal with this consistently we always remove events, and always add them on _showTA
                this.$ta.off();
                this.$element[tooltipPlugin]('hide');
            } else {
                this.$ta.hide();
                this.$ta.html('');
            }

            this.shouldBeOpen = false;

            // If empty or whitespaces only, remove whitespaces and hide clear button
            if (this.$element.val().trim() === '') {
                this.$element.val('');
            }

            if (this.bodyClick) {
                this.$ta.unbind('mousedown', $.proxy(this.cancelEvent, this));
                $(document.body).unbind('mousedown', $.proxy(this.close, this));
                this.bodyClick = false;
            }
        },

        prev: function () {
            var links = this.$ta.find('a'),
                current = this.$ta.find('.highlight').removeClass('highlight'),
                i = links.index(current),
                prev = links[i - 1];

            if (prev) {
                $(prev).addClass('highlight');
            }
        },

        next: function () {
            var links = this.$ta.find('a'),
                current = this.$ta.find('.highlight').removeClass('highlight'),
                i = links.index(current),
                next = links[i + 1];

            if (next) {
                $(next).addClass('highlight');
            }
        },

        removeHighlights: function () {
            this.$ta.find('.highlight').removeClass('highlight');
        },

        findHighlightedItem: function (e) {
            var $item = this.$ta.find('.highlight');

            // Used for touch devices as they don't have hover and therefore don't highlight
            if (e && $item.length === 0) {
                $item = $(e.target).closest('.results-item').find('a');
            }
            return $item;
        },

        // Choose the currently selected item:
        selectHighlighted: function (e) {
            var $item = this.findHighlightedItem(e);

            // Clicking the Tooltip padded area can lead to an undefined $item so we check length. TODO could be dealt with earlier...
            if ($item.length) {
                if (this.opts.tags) {
                    this.addTag($item);
                } else {
                    this.updateTextField({
                        id: $item.data('id'),
                        value: $item.data('value')
                    });
                }

                this.close();
                if (!this.opts.blurOnSelect) this.$element.focus();
                uitk.publish('autocomplete.selected', {
                    selectedId: $item.data('id'),
                    result: $item.data('result'),
                    $item: $item,
                    $autocomplete: this.$element
                });
                $item.removeClass('highlight');
                this.dirty = false;
            } else {
                this.dirty = true;
            }
        },

        updateTextField: function (result) {
            result = result || {};
            var value = (result.value != null && result.value != undefined) ? uitk.utils.removeHtmlInString(result.value.toString().replace(/&#39;/g, '\'')) : '';
            this.$element.val(value).trigger('input');
            this.$element.data('prev-selected-item-id', this.$element.data('selected-item-id'));
            this.$element.data('selected-item-id', result.id);
            this.dirty = false;
            // this.$element.data('result', result); the result should be unmapped (raw) search result here, but it is not available
        },

        // Adds a Tag to the Autocomplete's Tag Group
        addTag: function ($item) {
            var id = $item.data('id');
            var type = $item.data('type');
            var value = uitk.utils.removeHtmlInString($item.data('value'));
            var tag = Handlebars.templates['partials/uitk/tag']({
                id: id,
                type: type,
                value: value,
                label: value,
                autocomplete: true
            }); //TODO how to get a type and label?
            var $tag = $(tag);
            $tag.on('click', '.autocomplete-tag-remove', $.proxy(this.removeTag, this));

            // Add Tag and reset input
            this.selected[value] = id; // Using value as the key because the same real location can have multiple ids (they come from different categories) TODO does value need to be escaped? key names can be elaborate strings, but what are the limits if any?
            this.$tagsInputWrap.find('input.autocomplete-input').before($tag);
            this.$element.val('').attr('placeholder', '').select().trigger('input');

            // Determine if Tag limit has been reached and prevent future searching
            this.checkTagLimit();
        },

        // Removes a Tag from the Autocomplete's Tag Group and selected hash
        removeTag: function (event) {
            var $tag = event && event.target ? $(event.target).closest('.tag') : this.$tagsInputWrap.find('.tag').last();

            //Prevent bubbling
            event?.stopPropagation();

            // Remove from selected hash
            this.selected[$tag.data('value')] = null;
            delete this.selected[$tag.data('value')];

            // Remove the Tag
            $tag['uitk_tag']('remove', true);

            uitk.publish('autocomplete.tag.remove', {
                tagId: $tag.attr('id'),
                $tagsInputWrap: this.$tagsInputWrap,
                tagType: $tag.data('type'),
                tagValue: $tag.data('value'),
                $autocomplete: this.$element
            });

            // Allow searching in case the limit was previously reached
            this.checkTagLimit();

            // Put back placeholder when tag group is empty
            // including pre-populate tags
            if (this.$tagsInputWrap.find('.tag').length <= 0) {
                this.$element.attr('placeholder', this.opts.placeholder);
            }

            // Keep focus on input
            this.$element[0].focus();
        },

        // Clear all tags from the Autocomplete
        clearTags: function () {
            this.selected = {};

            // Remove all the tags
            this.$tagsInputWrap.find('.tag').each(function(key, tag) {
                $(tag)['uitk_tag']('remove', true);
            });

            this.$element.attr('placeholder', this.opts.placeholder);
        },

        focus: function () {
            if (this.opts.tags && !this.$tagsInputWrap.hasClass('focus')) {
                this.$tagsInputWrap.addClass('focus');
            }

            if (this.opts.validate) {
                this.lastValidValue = this.$element.val();
            }

            if (!this.opts.hideDropdownOnSelect) {
                if (!this.opts.dropdownOnFocus) { //default behavior
                    this.showDefaultResults();
                }
                else {
                    this.showLastResults()
                }
            } else { //behavior to hide dropdown when an element is selected
                if (!this.elementSelected) {
                    this.showDefaultResults();
                } else {
                    this.elementSelected = false;
                }
            }
        },

        showDefaultResults: function () {
            if (this.opts.useDefaultResults && !this.preventSelect && this.$element.val() === '') {
                this.opts.shouldBeOpen = true;
                // There is no query, so just show the defaults (Note the empty string passed as the query arg)
                this.essSourcesCallback('', $.proxy(this.defaultResultsCallback, this), this.opts)
            }
        },

        showLastResults: function () {
            if (this.lastValidValue !== undefined && this.lastValidValue !== null) {
                this.essSourcesCallback(this.lastValidValue, $.proxy(this.defaultResultsCallback, this), this.opts)
            }
            else {
                this.showDefaultResults();
            }

        },

        // Clears the input and publishes a cleared event
        clearInput: function () {
            var $el = this.$element;
            var val = $el.val();
            var lastId = $el.data('selected-item-id');

            $el.val('').data('selected-item-id', '').data('result', '').trigger('input');
            uitk.publish('autocomplete.cleared', {
                lastVal: val,
                lastId: lastId,
                $autocomplete: $el
            });
        },

        defaultResultsCallback: function (data) {
            this.render(this.preRender(data.query, data.search_results, ''));
        },

        keyDown: function (e) {
            var keyCode;

            if (!e) {
                e = window.event;
            }

            keyCode = e.keyCode;

            switch (keyCode) {
                case 27: // Escape
                    this.close();
                    e.stopPropagation();
                    break;

                case 13: // Enter
                    if (this.$ta.find('.highlight').length) {
                        e.stopPropagation();
                        if (keyCode === 13) {
                            e.preventDefault();
                        }
                        this.selectHighlighted();
                    }
                    this.blur(e); //forms submit before blur, this allows us to validate on key submit
                    break;

                case 8: // Backspace
                    if (this.$element.val().length === 1 && !this.opts.dropdownOnFocus) {
                        this.close();
                    }

                    if (this.opts.tags && this.$element.val() === '') {
                        this.removeTag();
                    }
                    this.dirty = true;
                    break;

                case 9: // Tab
                case 39: // Right
                    if (this.$ta.find('.highlight').length) {
                        this.ignoreNext = true;
                        e.preventDefault(); // Prevents tabbing to the next input, we want the Autocomplete to stay focused after selection
                        e.stopPropagation();
                        this.selectHighlighted();
                    }
                    break;
                default:
                    // Prevent typing (e.g. max Tags have already been selected)
                    if (this.preventSelect) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        this.dirty = true;
                    }
                    break;
            }
        },

        keyPress: function (e) {
            if (!e) {
                e = window.event;
            }
            if (e.keyCode === 13 /* Enter/Return */ ) {
                this.close();
            }
        },

        keyUp: function (e) {
            // Prevents a key up event after an item has been selected by the key board
            if (this.ignoreNext) {
                this.ignoreNext = false;
                return;
            }
            if (!e) {
                e = window.event;
            }
            switch (e.keyCode) {
                case 27: // Escape
                    break;

                case 37: // Left
                    this.removeHighlights();
                    break;

                case 38: // Up
                    this.prev();
                    break;

                case 40: // Down
                    this.next();
                    break;

                case 16: // Shift
                case 17: // Ctrl
                case 18: // Alt
                    break;
                case 13: // Enter/Return
                    this.shouldBeOpen = false;
                    break;

                case 8: //Backspace
                    if (this.$element.val().length === 0 && this.dirty) {
                        uitk.publish('autocomplete.cleared', {
                            $autocomplete: this.$element
                        });
                        if (!this.opts.dropdownOnFocus) break;
                    }
                    // Backspace should also trigger an update to dropdown

                default:
                    this.shouldBeOpen = true;
                    this.startSearch();
                    this.dirty = true;
                    break;
            }
        },


        validateInput: function (e) {
            var theInputIsValid = true;
            if (!this.opts.tags && this.opts.validate && this.dirty) {
                theInputIsValid = false;
                var inputValue = this.$element.val();
                var inputValueLowerCase = inputValue ? inputValue.toLowerCase() : '';
                var lastValidValueLowerCase = this.lastValidValue ? this.lastValidValue.toLowerCase() : '';
                if (inputValueLowerCase !== lastValidValueLowerCase) {
                    // if value has changed, there should have been queries issued
                    var currentValidResult;
                    if (inputValue) {

                        const currentValidResult =  this.cache?.[inputValue]?.results?.flatMap((category)=>category.data)
                            .find( (item) => item.value?.toLowerCase().trim() === inputValue.trim().toLowerCase());
                    
                        if (currentValidResult) {
                            theInputIsValid = true;
                            this.updateTextField(currentValidResult);
                            var $item = this.findHighlightedItem(e);
                            uitk.publish('autocomplete.selected', {
                                selectedId: currentValidResult.id,
                                result: currentValidResult,
                                $item: $item && $item.length ? $item : null,
                                $autocomplete: this.$element
                            });
                        }
                    }
                } else {
                    // value has not changed, still vaild
                    theInputIsValid = true;
                }
            }
            return theInputIsValid;
        },

        blur: function (e) {
            // Need a delay so we can cancel if the Autocomplete is being clicked on
            if (!this.isClickOn) {
                if (!this.validateInput(e)) {
                    this.clearInput();
                }
                this.close();
            } else {
                this.$ta.bind('mousedown', $.proxy(this.cancelEvent, this));
                $(document.body).bind('mousedown', $.proxy(this.close, this));
                this.bodyClick = true;
            }

            // For autocomplete with tags
            if (this.opts.tags) {
                this.$tagsInputWrap.removeClass('focus');
            }
        },

        clickOn: function () {
            this.isClickOn = true;
            this.elementSelected = true;
        },

        clickOff: function () {
            this.isClickOn = false;
        },

        click: function (e) {
            //this is dumb and assumes A LOT
            e.stopPropagation();
            e.preventDefault();
            this.removeHighlights();
            $(e.target).closest('a').addClass('highlight');
            this.selectHighlighted(e);
        },

        mouseEnter: function (e) {
            this.removeHighlights();
            $(e.currentTarget).addClass('highlight');
        },

        cancelEvent: function (e) {
            e.cancelBubble = true;
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            if (e.preventDefault) {
                e.preventDefault();
            }
            e.cancel = true;
            e.returnValue = false;
        },

        remove: function () {
            this.$element.autocomplete = 'on';
            this.$element.closest('form').attr('autocomplete', 'on');

            this.$element
                .off('focus', $.proxy(this.showDefaultResults, this))
                .off('keydown', $.proxy(this.keyDown, this))
                .off('keyup', $.proxy(this.keyUp, this))
                .off('keypress', $.proxy(this.keyPress, this))
                .off('blur', $.proxy(this.blur, this));
            this.$ta
                .off('mousedown', $.proxy(this.mouseDown, this))
                .off('mouseup', $.proxy(this.mouseUp, this))
                .off('click', $.proxy(this.click, this))
                .off('mouseenter', 'a', $.proxy(this.mouseEnter, this))
                .remove();
        },

        checkTagLimit: function () {
            if (this.opts.taglimit) {
                if (this.$tagsInputWrap.find('.tag').length >= this.opts.taglimit) {
                    uitk.publish('autocomplete.taglimit', {
                        $autocomplete: this.$element
                    });
                    this.preventSelect = true;
                } else {
                    this.preventSelect = false;
                }
            }
        }
    };

    Typeahead.expediaSuggest = function (term, options) {
        var params = {};

        const lssVersion = options.lssVersion ?? 'v0';
        const query = term || 'zzzzz';

        params.url = (lssVersion === 'v0') ?
            `/location-suggest-service/v0/query/${query}?`:
            `/location-suggest-service/${lssVersion}/suggest?`;

        params.data = {
            query: query,
            client: options.clientId,
            lob: Typeahead.LOB[options.source?.toUpperCase()] || options.lob, // source is also used to specify lob
            regiontype: options.regiontype,
            locale: options.locale?.replace('-', '_'),
            ab: options.abtest,
            siteid: options.siteId,
            destination: options.dest,
            maxresults: options.maxitems,
            features: options.features?.replace(/\-/g, '_') || 'ta_hierarchy',
            sortcriteria: Typeahead.SORTCRITERIA[options.sortcriteria?.toUpperCase()] || Typeahead.SORTCRITERIA.REGIONTYPE,
            companyId: options.companyId,
            userId: options.userId,
            preset: options.preset,
            productId: options.productId,
            latLonTopLeft: options.latLonTopLeft,
            latLonBottomRight: options.latLonBottomRight
        };

        $.extend(params.data, options.essQueryParams);
        return params;
    };

    // Constants:
    Typeahead.REGIONTYPE = {
        AIRPORT: 'AIRPORT', //1
        CITY: 'CITY', //2
        MULTICITY: 'MULTICITY', //4
        NEIGHBORHOOD: 'NEIGHBORHOOD', //8
        POI: 'POI', //16
        ADDRESS: 'ADDRESS', //32
        METROCODE: 'METROCODE', //64
        HOTEL: 'HOTEL' //128
    };

    Typeahead.LOB = {
        'HOTELS': 'HOTELS',
        'PACKAGES': 'PACKAGES',
        'FLIGHTS': 'FLIGHTS',
        'CARS': 'CARS'
    };

    Typeahead.CATEGORIES = {
        //If you are changing name or icon for CITY make the same change to MULTICITY and NEIGHBORHOOD
        'CITY': {
            'id': 0,
            name: uitk.i18n.msg('uitk_autocomplete_region') || 'Region/City',
            icon: 'locationalt'
        },
        //If you are changing name or icon for ATTRACTION make the same change to POI
        'ATTRACTION': {
            'id': 1,
            name: uitk.i18n.msg('uitk_autocomplete_attractions') || 'Attractions',
            icon: 'locationalt'
        },
        'AIRPORT': {
            'id': 2,
            name: uitk.i18n.msg('uitk_autocomplete_airports') || 'Airports',
            icon: 'flightsalt'
        },
        'HOTEL': {
            'id': 3,
            name: uitk.i18n.msg('uitk_autocomplete_hotels') || 'Hotels',
            icon: 'hotelsalt'
        },
        'ADDRESS': {
            'id': 4,
            name: uitk.i18n.msg('uitk_autocomplete_address') || 'Address',
            icon: 'locationalt'
        },
        'ARRANGEE_LIST': {
            'id': 5,
            name: uitk.i18n.msg('uitk_autocomplete_arrangee_list') || 'Arrangee list',
            icon: 'traveleralt'
        },
        'OTHER_TRAVELER': {
            'id': 6,
            name: uitk.i18n.msg('uitk_autocomplete_other_travelers') || 'Other travelers',
            icon: 'traveler'
        },
        'CUSTOM_DEST': {
            'id': 8,
            name: uitk.i18n.msg('uitk_autocomplete_custom_destination') || 'Company location',
            icon: 'destination'
        },
        'TRAINSTATION': {
            'id': 9,
            name: uitk.i18n.msg('uitk_autocomplete_train_station') || 'Train station',
            icon: 'trainalt'
        },
        'METROSTATION': {
            'id': 10,
            name: uitk.i18n.msg('uitk_autocomplete_metro_station') || 'Metro station',
            icon: 'trainalt'
        },
        //The icon and name should be in sync with 'Attraction'. ESS V4 returns attractions as POI
        'POI': {
            'id': 11,
            name: uitk.i18n.msg('uitk_autocomplete_attractions') || 'Attractions',
            icon: 'locationalt'
        },
        //The icon and name should be in sync with 'City'. ESS V4 returns some cities as multicity
        'MULTICITY': {
            'id': 12,
            name: uitk.i18n.msg('uitk_autocomplete_region') || 'Region/City',
            icon: 'locationalt'
        },
        //The icon and name should be in sync with 'City'. ESS V4 returns some cities as neighborhood
        'NEIGHBORHOOD': {
            'id': 13,
            name: uitk.i18n.msg('uitk_autocomplete_region') || 'Region/City',
            icon: 'locationalt'
        }
    };

    Typeahead.SORTCRITERIA = {
        'REGIONTYPE': 'regiontype', // Default
        'CATEGORY': 'category',
        'POPULARITY': 'popularity'
    };

    // This should probably be called 'ESSOptions'
    Typeahead.defaultOptions = {
        // 'Options' - to be moved into this.opts, which is a merge from Typeahead.default
        // The options are overwritten with HTML data attributes and need to be all lower case
        clientId: 'Egencia.Uitk.Autocomplete',
        siteId: '',
        minchar: 3,
        maxitems: 5,
        locale: 'en_US', // ESS requires the non-standard Java-style format DO NOT CHANGE
        abtest: '',
        mask: Typeahead.REGIONTYPE.AIRPORT + '|' + Typeahead.REGIONTYPE.CITY + '|' + Typeahead.REGIONTYPE.MULTICITY + '|' + Typeahead.REGIONTYPE.NEIGHBORHOOD + '|' + Typeahead.REGIONTYPE.POI,
        regiontype: '',
        lob: Typeahead.LOB.FLIGHTS,
        dest: false,
        tooltip: true,
        forceicon: false,
        validate: false,
        features: '',
        sortcriteria: '',
        essQueryParams: {},
        giveFocusOnInit: false
    };

    function init() {
        var $autofocus = $('[data-control="typeahead-lss"][data-autofocus]');
        var $autoCompleteTagsInputWrap = $('.autocomplete-lss .autocomplete-tags-wrap');

        // For removing placeholder and pre-populated tags.
        if ($autoCompleteTagsInputWrap.length > 0) {
            $.each($autoCompleteTagsInputWrap, function (index, tagsInputWrap) {
                var $tagsInputWrap = $(tagsInputWrap);

                $tagsInputWrap.on('click', function () {
                    $(this).find('input.autocomplete-input').select();
                });

                if ($tagsInputWrap.find('.tag').length > 0) {
                    var $autoCompleteTagInput = $tagsInputWrap.find('input.autocomplete-input');

                    // Clear the placeholder
                    $autoCompleteTagInput.attr('placeholder', '');

                    // Init first if it needs to pre-populate the tag
                    if ($autoCompleteTagInput.data('typeahead')) {
                        return; // Has already been instantiated, so just reuse
                    }

                    var _ = new Typeahead(
                        $autoCompleteTagInput,
                        $autoCompleteTagInput.data('template'),
                        $autoCompleteTagInput.data()
                    );
                }
            });
        }

        if ($autofocus.length > 0) {
            $autofocus[0].focus();
        }
    }

    // Expose Autocomplete to uitk, used for testing and exposing sources
    uitk.modules.AutocompleteLss = Typeahead;
    uitk.autocompleteLss = {
        init: init,
        adapters: Typeahead.prototype.adapters,
        callback: Typeahead.prototype.callback,
        updateAutocompleteWithResult: Typeahead.prototype.updateAutocompleteWithResult
    };

    // Listen for focuses on typeahead elements
    $body.on('focus.typeahead', '[data-control="typeahead-lss"]', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.data('typeahead')) {
            return; // Has already been instantiated, so just reuse
        }
        var _ = new Typeahead($this, $this.data('template'), $this.data());
    });

    // Auto-select text for easy delete
    $body.on('click', '[data-control="typeahead-lss"]', function (e) {
        $(this).trigger('select');
    });

    // Stop propagation on icon click for autocomplete-tags
    $body.on('click', '.autocomplete-tags-wrap .icon', (e) => e.stopPropagation());

    // For autofocus feature and pre-populate tag placeholder
    $document.ready(init);

    function clearGoogleMapSessionToken (topic, data) {
        if(data.result.category === "GOOGLE") {
            googleMapsSessionToken = null;
        }
    }

    //empty and re-generate google map session token on google map autocomplete result selection
    uitk.subscribe('autocomplete.selected', clearGoogleMapSessionToken);

})(window.jQuery);
