
;((window) => {
    'use strict';

    const helpPanel = uitk.modal.create({
      modalId: 'contextual-help',
      panel: true,
      panelSize: 'small',
      title: false,
      footer: false,
    });

    const panelOpen = (src = '/help-center/panel') => {
      const content = `<iframe class="contextual-help-content" src="${src}"></iframe>`;
      helpPanel.updateContent(content).open();
      helpPanel.modalBg.remove();
      document.body.classList.add('scrollable');
    };

    const panelMessage = (e) => {
        if (window.location.origin === e.origin) {
          switch(e.data?.message) {
              case 'close-modal':
                  panelClose();
                  break;
              case 'open-vac':
                  uitk.chatBot.open(true, e.data.launchPoint);
                  break;
              case 'open-sfchat':
                  uitk.sfChatBot.openSfChat(true, e.data.launchPoint);
                  break;
          }
      }
    };

    const panelClose = () => {
      helpPanel.close('close-button', true);
      document.body.classList.remove('scrollable');
    };

    window.addEventListener('message', panelMessage);

    document.addEventListener('click', (e) => {
      const isContextualHelpLink = e.target.classList.contains('contextual-help-link');
      if (isContextualHelpLink) {
          e.preventDefault();
          let urlToOpen = e.target.dataset.src;
          const href = e.target.getAttribute('href');
          if (!urlToOpen && href && href.length && href !== '#') {
            urlToOpen = href;
          }

          panelOpen(urlToOpen);
      }
    });



    uitk.helpPanel = {
        panelOpen,
        panelClose
    }
})(window);
