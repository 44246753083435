(function() {
    var defaultTemplate= '<div class="interstitial-message-content"><span class="loader loading"></span></div>',
        messageTemplate = '<div class="interstitial-message-content">',
        adSpaceTemplate= '<div class="interstitial-ad-space"></div>';

    function changed(topic, options) {
        var content;
        var $container = $('#' + options.contentId);
        var $interstitial = $container.find('.interstitial');

        // Create an Interstitial
        if (!$interstitial.length) {
            content = options.adSpace ? defaultTemplate + adSpaceTemplate : defaultTemplate;
            $interstitial = $(Handlebars.templates['partials/uitk/interstitial']({content: content}));
            $container.addClass('interstitial-results').append($interstitial);

            // Fade in the Interstitial
            setTimeout(function(){ $interstitial.addClass('fade-in'); }, 100);
        }
        // Update the Interstitial
        else if ($interstitial && options.replaceMsg) {
            content = options.adSpace ? messageTemplate + options.replaceMsg + '</div>'+ adSpaceTemplate : messageTemplate + options.replaceMsg + '</div>';
            $interstitial.find('.interstitial-message').html(content);
        }
    }

    function complete(topic, options) {
        var $container = $('#' + options.contentId);
        var $interstitial = $container.find('.interstitial');

        if ($interstitial) {

            $interstitial.one(uitk.topics.transitionEnd, function(e) {
                e.stopPropagation();
                if(e.target === $interstitial[0]) {
                    $interstitial.remove();
                }
            });

            $interstitial.removeClass('fade-in');
        }
    }

    // Subscribe to results changing
    uitk.subscribe('interstitial.changed', changed);

    // Subscribe to results to complete and remove the modal
    uitk.subscribe('interstitial.complete', complete);
    
})();