/*
 *  Project: UI Toolkit Tabs Plugin
 *  Description: jQuery Tab Plugin for use in the Expedia.com UI Toolkit
 *  Author: dstrugnell@expedia.com
 */

/*
 *  OPTIONS (passed via data-* attributes in the html)
 *
 *  @param data-initial-tab (optional) the initial tab that is shown on page load. Default: 0
 *  @param data-hash-suffix (optional) the suffix added to the hash in the url when tab is selected. Default: 'tab'
 *  @param data-history (optional) should hash history be used for browsers that support onHashChange (not IE7). Default: false
 *  @param data-tab-state (optional) should state of tabs be stored for the browser session in browsers that support session storage (not IE7). Default: true
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function ($, window, undefined) {

    'use strict';

    // Create the defaults once
    var pluginName = 'uitk_tabs',
        $body = $('body'),
        storageKey = window.location.pathname + '_' + pluginName,
        hasSessionStorage = Modernizr.sessionstorage,
        hasHashChange = Modernizr.hashchange,
        currentClass = 'on',
        activeClass = 'active',
        defaults = {
            initialTab: 0,
            hashSuffix: 'tab',
            history: true,
            tabState: true
        };

    /* CONSTRUCTOR */
    function Tabs(element, jsOptions) {
        var newOptions;

        this.element = $(element);
        this.tabs = this.element.find('>.tabs >li'); // this.element.find('.tabs').find('.tab') ????

        jsOptions = jsOptions || {};
        newOptions = this.element.data() || {};

        this.options = $.extend({}, defaults, newOptions, jsOptions);

        this.init();
    }

    Tabs.prototype = {
        init: function () {

            var that = this,
                activeTab,
                i;

            // Add data-control
            this.element.attr('data-control', 'tabs');

            // For each tab find and cache the DOM element containing the tab's content
            this.tabsContent = $();
            for (i = this.tabs.length; i > 0; i = i - 1) {
                var $this = this.tabs.eq(i - 1),
                    contentElem = that.getContentElem($this);

                // Store reference to the tabs content on the tab so it doesn't need to be looked up again
                $this.data('content', contentElem);

                // Add to object containing all content elements
                this.tabsContent = this.tabsContent.add(contentElem);
            }

            if (hasHashChange) {
                $(window).on('hashchange.' + pluginName, function () {
                    var nextTab = that.getNextTab();

                    if (nextTab) {
                        that.showTab(nextTab);
                    }
                });
            }

            // Work out which tab should be activated initially and activate it
            activeTab = this.getNextTab(true);
            if (activeTab) {
                this.showTab(activeTab, true);
            }

            // For correcting the sliding tab Indicator on simple theme
            uitk.subscribe('throttled.resize', function() {
                if(that.element.hasClass('simple-theme')){
                    var $tab = that.element.find('li.active');
                    that.getIndicator($tab, {resize:true});
                }
            });
        },

        selectTab: function ($tab, noFocus) {
            if (!hasHashChange || !this.options.history) {
                this.showTab($tab.closest('li'));
            } else if(noFocus) {
                // updates browser url without scrolling page to element with corresponding id
                this.pushState($tab.attr('href'));
            } else {
                window.location.hash = $tab.attr('href');
            }
        },

        pushState: function (tab) {
            history.pushState({}, '', tab);
        },

        getContentElem: function ($tab) {
            var href = $tab.find('a[href^="#"]').attr('href');

            //return content elem
            return this.element.find(href);
        },

        getNextTab: function (init) {
            var hash = window.location.hash,
                hashTab = this.tabs.find('a[href="' + hash + '"]'),
                sessionObj,
                sessionString,
                sessionTab,
                nextTab,
                i;


            if (init && hasSessionStorage && this.options.tabState) {
                sessionString = sessionStorage.getItem(storageKey);

                if (sessionString) {
                    sessionObj = JSON.parse(sessionString);

                    for (i = sessionObj.length - 1; i >= 0; i = i - 1) {
                        sessionTab = this.tabs.find('a[href="' + sessionObj[i] + '"]');

                        if (sessionTab.length > 0) {
                            return sessionTab.parent();
                        }
                    }
                }
            }

            //use the url hash's corresponding tab
            if (hash && hashTab.length > 0) {
                nextTab = hashTab.parent();
            } else if (init) {
                // use initial tag setting from JSP if set, otherwise try the initialTag option, otherwise set to 0
                var taggedInitialTabs = this.tabs.find('a[data-initial-tab]');
                if (taggedInitialTabs.length > 0) {
                    nextTab = $(taggedInitialTabs[0]).parent();
                } else if (this.options.initialTab + 1 <= this.tabs.length) {
                    nextTab = this.tabs.eq(this.options.initialTab);
                } else {
                    nextTab = this.tabs.eq(0);
                }
            }
            return nextTab;
        },

        showTab: function ($tab, init) {

            this.doShowTab($tab, init);

            //publish tab.selected
            uitk.publish('tab.selected', $tab);
        },

        doShowTab: function ($tab, init) {
            var tabContent = $tab.data('content');

            //hide all content
            this.tabsContent.removeClass(currentClass);

            // Active chosen tab
            this.tabs.removeClass(activeClass);
            $tab.addClass(activeClass);
            // Activate chosen anchor and show content if it exists
            this.tabs.find('a').removeClass(currentClass).attr({'tabindex': '-1', 'aria-selected': 'false'});
            $tab.find('a').addClass(currentClass).attr({'tabindex': '0', 'aria-selected': 'true'});

            // Slide the tab indicator to the left for Simple theme
            if ($tab.closest('.inline-tabs').hasClass('simple-theme')) {
                this.getIndicator($tab);
            }

            if (tabContent) {
                tabContent.addClass(currentClass);
            }

            //if not the initial page load, store tab state and set focus()
            if (!init) {
                this.storeState();
                $tab.find('a').focus();
            }
        },

        storeState: function () {
            var currentTabs,
                currentTabsArray,
                i;

            if (hasSessionStorage && this.options.tabState) {

                currentTabsArray = [];

                currentTabs = $('.tabs a.' + currentClass);

                for (i = currentTabs.length - 1; i >= 0; i = i - 1) {
                    currentTabsArray.push(currentTabs.eq(i).attr('href'));
                }

                sessionStorage.setItem(storageKey, JSON.stringify(currentTabsArray));
            }
        },

        getIndicator: function($tab, resize) {
            var width = $tab.width(),
                nextWidth = 0;

            $tab.prevAll().each(function() {
                nextWidth += $(this).width();
            });

            this.slideIndicator($tab, width, nextWidth, resize);
        },

        slideIndicator: function ($tab, width, totalWidth, resize) {
            if(resize && resize.resize) {
                    $tab.closest('ul.tabs').find('.tab').first().find('.tab-indicator').attr('style', 'left:'+ totalWidth +'px;width:'+ width +'px;transition: all 0s');
            } else {
                $tab.closest('ul.tabs').find('.tab').first().find('.tab-indicator').attr('style', 'left:'+ totalWidth +'px;width:'+ width +'px;');
            }

        }
    };

    /* JQUERY PLUGIN DEFINITION */
    $.fn[pluginName] = function () {

        var options, method, args;

        if (typeof arguments[0] === 'object') {
            options = arguments[0];
            method = arguments[1];
            args = arguments[2];
        } else {
            options = {};
            method = arguments[0];
            args = Array.from(arguments).slice(1);
        }

        return this.each(function () {
            if (!$.data(this, pluginName)) {
                $.data(this, pluginName, new Tabs(this, options));
            }
            if (typeof method === 'string') {
                $.data(this, pluginName)[method](...args);
            }
        });
    };

    // INITIALISE TABS
    // This will take in to account any tab state stored in local storage or hash in the URL
    // for tabs that are present on page load
    function initTabs() {
        $('[data-control=tabs]')[pluginName]();
    }

    initTabs();

    // Expose init for dynamic client-side rendering
    uitk.initTabs = initTabs;

    //EVENT LISTENERS
    //Tabs that haven't been initiated on page load will be initiated on click
    $body.on('click.' + pluginName, '[data-control=tabs] .tabs a[href^="#"]', function (e) {
        var $target = $(e.target).closest('a');

        e.preventDefault();

        $target.closest('[data-control=tabs]')[pluginName]('selectTab', $target);
    });

    //Left and right arrow for accessibility
    $body.on('keydown.' + pluginName, '[data-control=tabs] .tabs a', function (e) {
        var $target = $(e.target),
            keycode = e.keyCode,
            prev,
            next;

        if (keycode === 37 || keycode === 38) {
            //left or up arrow
            prev = $target.parent().prev();

            if (prev.length > 0) {
                prev.find('>a').focus();
                if (keycode === 38) {
                    e.preventDefault();
                    return false;
                }
            } else if (keycode === 37) {
                $target.closest('.tabs').find('li').last().find('a').focus();
            }

        } else if (keycode === 39 || keycode === 40) {
            //right or down arrow
            next = $target.parent().next();

            if (next.length > 0) {
                next.find('> a').focus();
                if (keycode === 40) {
                    e.preventDefault();
                    return false;
                }
            } else if (keycode === 39) {
                $target.closest('.tabs').find('li').first().find('a').focus();
            }
        }
        return true;
    });

}(jQuery, window));
